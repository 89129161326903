import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  typographyClasses,
} from '@mui/material';
import { Check, Dummy } from '@lunit/oncology-icons';
import getPadding from '../List/List.utils';
import type { StyledListItemButtonProps } from './ListItem.types';

export const StyledListItem = styled(ListItem)({
  width: '100%',
});

export const StyledListItemButton = styled(({ size, ...props }: StyledListItemButtonProps) => (
  <ListItemButton {...props} />
))(({ theme, size }) => ({
  borderRadius: '8px',
  padding: `${getPadding(size)} 8px`,
  '&.Mui-selected': {
    background: 'transparent',
  },
  '&:hover, &.Mui-selected:hover, &.Mui-focusVisible, &.Mui-selected.Mui-focusVisible': {
    backgroundColor: theme.palette.neutralGrey[75],
  },
}));

export const slotStyles = {
  minWidth: '20px',
  height: '20px',
  color: 'currentColor',
  svg: {
    width: '100%',
    height: '100%',
    color: 'currentColor !important',
  },
};

export const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  margin: 0,
  [`& .${typographyClasses.body1}`]: {
    ...theme.typography.body5,
  },
}));

export const StyledListItemStartSlot = styled(ListItemIcon)(({ theme }) => ({
  ...slotStyles,
  marginRight: theme.spacing(2),
}));

export const StyledListItemEndSlot = styled(ListItemIcon)(({ theme }) => ({
  ...slotStyles,
  justifyContent: 'flex-end',
  marginLeft: theme.spacing(2),
}));

export const CheckIcon = styled(Check)({ color: '#fff' });
export const EmptyIcon = styled(Dummy)({ visibility: 'hidden' });
