import { useContext } from 'react';
import { createPortal } from 'react-dom';
import {
  Dialog as Container,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  Tooltip,
  Typography,
  IconButton,
  CircularProgress,
  styled,
} from '@mui/material';
import { CloseIcon } from '@lunit/scope-icons';
import { isNil } from 'lodash';
// eslint-disable-next-line import/no-cycle
import { DialogContext } from './context';

const Title = styled(DialogTitle)({
  padding: '16px 20px 8px 20px',
  display: 'flex',
  justifyContent: 'space-between',
});

const StyledDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.darkGrey[80],
}));

const ContentWrapper = styled(DialogContent)(({ theme }) => ({
  position: 'relative',
  padding: '16px 20px 0px 20px',
  backgroundColor: theme.palette.darkGrey[85],
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  padding: '8px 20px 20px 20px',
  backgroundColor: theme.palette.darkGrey[85],
}));

const StyledProgress = styled(CircularProgress)({
  position: 'absolute',
  top: 'calc(50% - 20px)',
  right: 'calc(50% - 20px)',
});

const Dialog = () => {
  const {
    open,
    handleDialog,
    dialogContent: {
      disableBackdropClick,
      width,
      height,
      handleDisagreementCallback,
      handleAgreementCallback,
      title,
      divider,
      content,
      disagree,
      agree,
      useCustomActionBtns,
      useCloseBtn,
      forceAction,
      loading,
      agreeButtonSx,
    },
  } = useContext(DialogContext);
  return (
    open &&
    createPortal(
      <Container
        open
        PaperProps={{
          sx: {
            width: `${width || 320}px`,
            height: height ? `${height}px` : 'auto',
          },
        }}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') {
            handleDialog(null);
          } else if (disableBackdropClick) {
            handleDialog(null);
          }
        }}
        aria-labelledby="max-width-dialog-title"
        sx={{ padding: 10 }}
      >
        {title && (
          <Title id="max-width-dialog-title">
            <Typography variant="subtitle3">{title}</Typography>
            {useCloseBtn && (
              <Tooltip title="Close">
                <IconButton
                  sx={{ padding: 0 }}
                  size="small"
                  aria-label="close"
                  disabled={loading}
                  onClick={() => handleDialog(null)}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            )}
          </Title>
        )}
        {divider && <StyledDivider />}
        <ContentWrapper>
          {typeof content === 'string' ? (
            <Typography variant="body5">{content}</Typography>
          ) : (
            content
          )}
          {loading && <StyledProgress />}
        </ContentWrapper>
        {!useCustomActionBtns && (
          <Actions>
            {!forceAction && (
              <Button
                color="primary"
                disabled={loading}
                onClick={() => {
                  if (typeof handleDisagreementCallback === 'function') {
                    handleDisagreementCallback();
                    handleDialog(null);
                  } else {
                    handleDialog(null);
                  }
                }}
              >
                {disagree || 'Cancel'}
              </Button>
            )}
            <Button
              variant="contained"
              color="secondary"
              sx={agreeButtonSx}
              disabled={loading}
              onClick={() => {
                if (typeof handleAgreementCallback === 'function') {
                  handleAgreementCallback();
                }
                if (isNil(loading)) {
                  handleDialog(null);
                }
              }}
            >
              {agree || 'Confirm'}
            </Button>
          </Actions>
        )}
      </Container>,
      document.querySelector('#dialog-root'),
    )
  );
};

export default Dialog;
