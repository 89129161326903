import { useState } from 'react';

type Toggle = () => void;
type Set = (val) => void;

export default function useToggleState(defaultState: boolean): [boolean, Toggle, Set] {
  const [state, setState] = useState(defaultState);
  const toggleState = () => setState((s) => !s);
  return [state, toggleState, setState];
}
