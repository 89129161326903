/* eslint-disable @typescript-eslint/no-var-requires */
import { Player } from '@lottiefiles/react-lottie-player';
import {
  LoadingOverlay,
  LoadingIndicatorBox,
  LoadingMessage,
  LoadingDetails,
} from './LoadingIndicator.styled';
import type { LoadingIndicatorProps } from './LoadingIndicator.types';

const DotLoadingAnimation = require('./assets/DotLoadingAnimation.json');
const DnaLoadingAnimation = require('./assets/DnaLoadingAnimation.json');

const LoadingIndicator = ({
  message,
  details,
  loadingAnimation = 'dot',
}: LoadingIndicatorProps) => {
  return (
    <LoadingOverlay>
      <LoadingIndicatorBox>
        <Player
          autoplay
          loop
          src={loadingAnimation === 'dot' ? DotLoadingAnimation : DnaLoadingAnimation}
          style={{ height: '112px', width: '140px' }}
        />
        <LoadingMessage>{message}</LoadingMessage>
        <LoadingDetails>
          {details || (
            <>
              Please wait.
              <br />
              It may take a few minutes.
            </>
          )}
        </LoadingDetails>
      </LoadingIndicatorBox>
    </LoadingOverlay>
  );
};

export default LoadingIndicator;
