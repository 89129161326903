import * as React from 'react';
import type { SVGProps } from 'react';
const SvgIcBellNotioutlined20Px = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M10 1.5c.557 0 1.072.049 1.547.142a5.021 5.021 0 0 0-1.128.869A8.062 8.062 0 0 0 10 2.5c-1.258 0-2.214.278-2.944.717-.731.439-1.265 1.056-1.653 1.778-.785 1.461-.955 3.326-.914 4.872.044 1.62-.343 3.313-1.36 4.633h13.742c-.831-1.078-1.241-2.406-1.34-3.739.344-.11.672-.257.98-.436.039 1.495.502 2.935 1.493 3.96a.717.717 0 0 1-.515 1.215H12.5a2.5 2.5 0 0 1-5 0H2.511a.717.717 0 0 1-.515-1.215c1.086-1.123 1.538-2.745 1.494-4.39-.044-1.606.124-3.682 1.032-5.373.458-.854 1.11-1.616 2.019-2.162.91-.547 2.05-.86 3.459-.86Zm-1.5 14a1.5 1.5 0 0 0 3 0h-3Z"
      clipRule="evenodd"
    />
    <path
      fill="#E63B28"
      d="M18 6a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
    />
  </svg>
);
export default SvgIcBellNotioutlined20Px;
