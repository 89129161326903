import { useRef, useState, useMemo, useEffect } from 'react';
import { styled, Tooltip } from '@mui/material';
import {
  ListHeader,
  SearchField,
  ActiveIconButton,
  LoadingIndicatorDeprecated,
  ProjectContent,
  NoMatchContainer,
} from '@lunit/oncology-components';
import { useElementBreakpoints } from '@lunit/oncology-components/hooks/useElementBreakpoints';
import { ViewGridIcon, ViewListIcon } from '@lunit/scope-icons';
import { useProjects } from 'src/hooks/useProjectQueries';
import { generateProjectLink } from 'src/utils/projects';
import useSnackbar from 'src/hooks/useSnackbar';
import { Project } from '../../api/project/models';

const ProjectsContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'open',
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  minWidth: '860px',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && { width: 'calc(100% - 240px)' }),
}));

const ContentContainer = styled('div')({
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
});

enum ProjectViewMode {
  Grid,
  List,
}

const breakpoints = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
  (value) => 520 * (value + 1) + 20 * value,
);

function Projects() {
  const { enqueueSnackbar } = useSnackbar();
  const [searchField, setSearchField] = useState<string>('');
  const [viewMode, setViewMode] = useState<ProjectViewMode>(ProjectViewMode.List);

  const containerRef = useRef<HTMLDivElement>(null);
  const breakpointIndex = useElementBreakpoints(containerRef, breakpoints);

  const { data: projectData, isSuccess, isFetching, isError, error } = useProjects();

  useEffect(() => {
    if (isError && error) {
      enqueueSnackbar(`Failed to fetch Project data: ${(error as Error).message}.`, {
        variant: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isError]);

  const projectsList = useMemo<Array<Project>>(() => {
    if (!isSuccess) return [];
    if (!searchField) return projectData.projects;
    const sValue = searchField.toLowerCase();
    return projectData.projects.filter(
      (project: Project) =>
        project.title.toLowerCase().includes(sValue) ||
        project.description.toLowerCase().includes(sValue),
    );
  }, [projectData, searchField, isSuccess]);

  const customerCode = useMemo<string>(() => projectData?.customerCode || '', [projectData]);

  const handleSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchField(event.target.value);
  };

  const handleResetInput = () => {
    setSearchField('');
  };

  return (
    <ProjectsContainer ref={containerRef}>
      <ListHeader
        title="All Projects"
        searchField={
          <SearchField
            placeholder="Search by project title or description"
            value={searchField}
            onSearch={handleSearchField}
            onResetInput={handleResetInput}
          />
        }
        toolTipField={
          <>
            <Tooltip
              title="Show as list"
              key="showList"
            >
              <ActiveIconButton
                active={viewMode === ProjectViewMode.List}
                onClick={() => setViewMode(ProjectViewMode.List)}
                size="large"
              >
                <ViewListIcon />
              </ActiveIconButton>
            </Tooltip>
            <Tooltip
              title="Show as grid"
              key="showGrid"
            >
              <ActiveIconButton
                active={viewMode === ProjectViewMode.Grid}
                onClick={() => setViewMode(ProjectViewMode.Grid)}
                size="large"
              >
                <ViewGridIcon />
              </ActiveIconButton>
            </Tooltip>
          </>
        }
      />

      <ContentContainer>
        {isSuccess && projectsList.length !== 0 ? (
          <ProjectContent
            viewMode={viewMode}
            isAnalyzer
            breakpointIndex={breakpointIndex}
            ProjectViewMode={ProjectViewMode}
            projectPerPage={projectsList}
            customerCode={customerCode}
            generateProjectLink={generateProjectLink}
          />
        ) : (
          <NoMatchContainer />
        )}
        {isFetching && <LoadingIndicatorDeprecated message="Loading project list..." />}
      </ContentContainer>
    </ProjectsContainer>
  );
}

export default Projects;
