import type { ComponentsOverrides } from '@mui/material/styles';
import palette from '../colors';
import typographyOptions from '../system/typography';

const styleOverrides: ComponentsOverrides['MuiMenuItem'] = {
  root: {
    ...typographyOptions.body5,
    padding: '8px',
    margin: '0 8px',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: palette.background.selected,
    },
    '&.Mui-selected:hover': {
      backgroundColor: palette.background.selected,
    },
    '&.Mui-selected': {
      backgroundColor: 'transparent',
    },
  },
};
export default {
  styleOverrides,
};
