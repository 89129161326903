import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

import filled from "../../assets/ic_dummy=filled_20px.svg";
import outlined from "../../assets/ic_dummy=outlined_20px.svg";

const variants = ['filled', 'outlined', ] as const;
type Variant = typeof variants[number];
type IconProps = SvgIconProps & {
  variant?: Variant;
};
const Dummy = ({variant = 'outlined', ...rest}: IconProps) => {
  if (variant === 'filled') {
    return <SvgIcon {...rest} component={ filled } inheritViewBox />;
  }
  if (variant === 'outlined') {
    return <SvgIcon {...rest} component={ outlined } inheritViewBox />;
  }

  return null;
};

Dummy.displayName = 'Dummy';
Dummy.variants = variants;

export default Dummy;
