import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import msgpack from 'msgpack-lite';
import { camelize } from '@ridi/object-case-converter';
import { getSlideMsgpackPresignedUrl } from 'src/api/slide/queries';
import { LabelsDetails } from 'src/api/slide/models';
import { logout } from 'src/pages/auth/utils';
import { SlideProps, TaskClass } from 'src/pages/visualizer/types';
import Slide from 'src/pages/visualizer/component/Classes/Slide';
import { isLoadingDataAtom } from 'src/state';
import { useSetRecoilState } from 'recoil';

export default function useResultData(
  msgpackFilePath: string | undefined,
  params: any,
  labels: LabelsDetails[],
) {
  const setIsDataLoading = useSetRecoilState(isLoadingDataAtom);
  const [slideData, setSlideData] = useState<any>();
  // @ts-ignore
  const taskClasses: TaskClasses = { __array__: labels };
  labels.forEach((label: TaskClass) => {
    taskClasses[label.id] = label;
  });

  const {
    isLoading,
    isError,
    isSuccess,
    data: msgpackPresignedUrl,
  } = useQuery(
    getSlideMsgpackPresignedUrl.key(msgpackFilePath || ''),
    () => {
      slideData?.currentSlide.destroy();
      return getSlideMsgpackPresignedUrl(
        params.get('group'),
        params.get('customer_code'),
        msgpackFilePath || '',
      );
    },

    {
      cacheTime: 0,
      suspense: false,
      useErrorBoundary: false,
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!msgpackFilePath,
      keepPreviousData: false,
      onError: (error: Error) => {
        if (error.message === 'AUTH_ERROR') {
          logout();
        }
      },
    },
  );

  useEffect(() => {
    if (!isSuccess && slideData) {
      setSlideData(undefined);
      setIsDataLoading(false);
    }
  }, [isSuccess, slideData, setIsDataLoading]);

  useEffect(() => {
    setIsDataLoading(isLoading);
  }, [isLoading, setIsDataLoading]);

  useEffect(() => {
    if (msgpackPresignedUrl) {
      fetch(msgpackPresignedUrl)
        .then((response) => response.arrayBuffer())
        .then((encodedInferenceResult) => {
          const msgpackData: SlideProps = camelize(
            msgpack.decode(new Uint8Array(encodedInferenceResult)),
            { recursive: true },
          );
          const slide = new Slide(msgpackData);
          slide.dataList = [
            ...msgpackData.inferenceContours,
            ...msgpackData.inferenceCoordinates,
            ...msgpackData.inferenceImages,
          ];
          const targetSlide = msgpackData.inferenceImages[0] || {};
          const masks = msgpackData.inferenceImages.map((image) => ({
            minX: image.minX,
            minY: image.minY,
            downsampling: image.downsample,
            maskUrl: window.URL.createObjectURL(
              new Blob([image.imgBytes], { type: image.imgType }),
            ),
          }));
          slide.minX = targetSlide.minX;
          slide.minY = targetSlide.minY;
          slide.downsampling = targetSlide.downsampling;
          const blob = new Blob([targetSlide.imgBytes], { type: 'image/png' });
          const tissueClassCandidates = slide.dataList.filter((data) => data.dataType === 3);
          setSlideData({
            tissueClasses: tissueClassCandidates.length
              ? [
                  // For image type
                  ...tissueClassCandidates
                    .map((tissue) =>
                      tissue.labelIds
                        ? [
                            ...tissue.labelIds
                              .map((id: any) => taskClasses[id])
                              .filter((cls: any) => !!cls),
                          ]
                        : [],
                    )
                    .reduce((acc, labelIds) => acc.concat(labelIds), []),
                  // For others
                  ...tissueClassCandidates
                    .map((tissue) => taskClasses[tissue.labelId])
                    .filter((cls) => !!cls),
                ]
              : [],
            structureClasses: slide.dataList
              .filter((data) => data.dataType === 1 || data.dataType === 4)
              .map((structure) => taskClasses[structure.labelId])
              .filter((cls) => !!cls),
            cellClasses: slide.dataList
              .filter((data) => data.dataType === 2)
              .map((cell) => taskClasses[cell.labelId])
              .filter((cls) => !!cls),
            currentSlide: slide,
            currentMasks: masks,
            currentMaskUrl: window.URL.createObjectURL(blob),
            analysisData: msgpackData.analysisResults,
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msgpackPresignedUrl]);

  return { isLoading, isError, slideData, taskClasses };
}
