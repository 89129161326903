import { PieChart } from 'react-minimal-pie-chart';
import { Typography } from '@mui/material';
import { Bar, Indicator, PieDD, SectionWrapper, Wrapper } from './styled';
import { AnalysisData, TaskClasses } from '../../types';

interface SectionProps {
  data: AnalysisData;
  taskClasses: TaskClasses;
}

function Section({ data, taskClasses }: SectionProps) {
  return (
    <SectionWrapper>
      <h3>{data.title}</h3>
      <dl>
        {data.items.map((item, idx) => {
          if (item.type === 'numeric') {
            return [
              <dt key={`${idx.toString()}_title`}>{item.title}</dt>,
              <dd key={`${idx.toString()}_value`}>
                {`${(item.values[0].value * 100).toFixed(1)} ${item.values[0].unit}`}
                <Bar value={item.values[0].value} />
              </dd>,
            ];
          }
          if (item.type === 'pie') {
            return [
              <dt key={`${idx.toString()}_title`}>{item.title}</dt>,
              <PieDD key={`${idx.toString()}_value`}>
                t
                <div>
                  <PieChart
                    data={item.values.map((v, i) => ({
                      ...v,
                      key: i,
                      // @ts-ignore
                      color: v.color || taskClasses[v.title]?.color,
                    }))}
                    radius={50}
                  />
                </div>
                <dl>
                  {item.values.map((v, i) => [
                    <dt key={`${idx.toString()}_${i.toString()}_title`}>
                      <Indicator
                        // @ts-ignore
                        color={v.color || taskClasses[v.title]?.color}
                      />
                      {v.title}
                    </dt>,
                    <dd key={`${idx.toString()}_${i.toString()}_value`}>
                      {`${(v.value * 100).toFixed(1)} ${v.unit}`}
                    </dd>,
                  ])}
                </dl>
              </PieDD>,
            ];
          }
          if (data.title.toLowerCase() === 'footnotes') {
            return [<dt key={`${idx.toString()}_title`}>{item.title}</dt>];
          }
          return [
            <dt key={`${idx.toString()}_title`}>{item.title}</dt>,
            <dd key={`${idx.toString()}_value`}>
              {`${item.values[0].value} ${
                item.values[0].unit === 'mm2' ? ' / mm²' : item.values[0].unit
              }`}
            </dd>,
          ];
        })}
      </dl>
    </SectionWrapper>
  );
}

interface AnalysisProps {
  data: AnalysisData[];
  taskClasses: TaskClasses;
}

function Analysis({ data, taskClasses }: AnalysisProps) {
  return (
    <Wrapper>
      <Typography sx={{ margin: '12px 0' }}>Analysis Results</Typography>
      {data.map((section, idx) => (
        <Section
          key={`section-key-${idx.toString()}`}
          data={section}
          taskClasses={taskClasses}
        />
      ))}
    </Wrapper>
  );
}

export default Analysis;
