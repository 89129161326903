import { useContext, useEffect, useState } from 'react';
import { ResizeObserverContext } from '../ResizeObserverContext';

function sortedIndexRec(array: number[], left: number, right: number, target: number) {
  if (left >= right) {
    if (array[left] <= target) {
      return left + 1;
    }
    return left;
  }
  const centerIdx = Math.floor((left + right) / 2);
  if (target > array[centerIdx]) {
    return sortedIndexRec(array, centerIdx + 1, right, target);
  }
  if (target < array[centerIdx]) {
    return sortedIndexRec(array, left, centerIdx, target);
  }
  return centerIdx + 1;
}

function sortedIndex(array: number[], target: number): number {
  return sortedIndexRec(array, 0, array.length - 1, target);
}

function getBreakpointIndex(element: HTMLElement, breakpoints: number[]) {
  const width = element.clientWidth;
  return sortedIndex(breakpoints, width);
}

// breakpoints must be sorted for this to work properly
// eslint-disable-next-line import/prefer-default-export
export function useElementBreakpoints(ref: React.RefObject<HTMLElement>, breakpoints: number[]) {
  const [breakpointIndex, setBreakpointIndex] = useState<number>(0);
  const { addResizeHandler, removeResizeHandler } = useContext(ResizeObserverContext);

  useEffect(() => {
    const elem = ref.current!;

    function run() {
      const newBPIndex = getBreakpointIndex(elem, breakpoints);
      if (newBPIndex !== breakpointIndex) {
        setBreakpointIndex(newBPIndex);
      }
    }

    addResizeHandler(elem, run);

    return () => removeResizeHandler(elem);
  }, [ref, breakpoints, breakpointIndex, addResizeHandler, removeResizeHandler]);

  return breakpointIndex;
}
