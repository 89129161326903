import BaseCircularProgress from './CircularProgress.styled';
import type { CircularProgressProps } from './CircularProgress.types';

function CircularProgress({ progress, size = 36, ...otherProps }: CircularProgressProps) {
  return (
    <BaseCircularProgress
      size={size}
      value={progress}
      {...otherProps}
    />
  );
}

export default CircularProgress;
