/* eslint-disable no-nested-ternary */
import { createContext, useMemo } from 'react';
import { ArrowDownSm, ArrowUpSm } from '@lunit/oncology-icons';
import { Collapse } from '@mui/material';
import { MuiList, MuiListSubheader } from './List.styled';
import type { ListProps, ListContextValue } from './List.types';

export const ListContext = createContext<ListContextValue>({
  size: 'small',
  showCheck: false,
});

const List = ({
  size = 'small',
  showCheck = false,
  subheader,
  subheaderType = 'bold',
  open = 'visible',
  children,
  onOpen,
  onClose,
  ...props
}: ListProps) => {
  const contextValue = useMemo<ListContextValue>(() => ({ size, showCheck }), [size, showCheck]);

  const collapsible = open !== 'visible';

  const onSubheaderClick = ((event: React.MouseEvent<HTMLLIElement>) => {
    if (open === 'visible') {
      return;
    }
    const handler = open ? onClose : onOpen;
    handler?.(event);
  }) as React.ComponentProps<typeof MuiListSubheader>['onClick'];

  return (
    <ListContext.Provider value={contextValue}>
      <MuiList
        {...props}
        subheader={
          subheader ? (
            <MuiListSubheader
              type={subheaderType}
              clickable={collapsible}
              tabIndex={collapsible ? 0 : undefined}
              onClick={onSubheaderClick}
            >
              {subheader}
              {collapsible && (open ? <ArrowDownSm /> : <ArrowUpSm />)}
            </MuiListSubheader>
          ) : null
        }
      >
        {collapsible ? (
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
          >
            {children}
          </Collapse>
        ) : (
          children
        )}
      </MuiList>
    </ListContext.Provider>
  );
};

export default List;
