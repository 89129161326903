import { useQuery } from 'react-query';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { getSlides } from 'src/api/slide/queries';
import { slidesAtom } from 'src/state/slides';

const useSlides = (params: URLSearchParams, enabled: boolean) => {
  const setSlides = useSetRecoilState(slidesAtom);

  const initialData = useRecoilValue(slidesAtom);

  const data = useQuery(
    getSlides.key(),
    () => getSlides(params.get('group') || '', params.get('customer_code') || ''),
    {
      suspense: false,
      cacheTime: 3600 * 1000,
      refetchOnWindowFocus: false,
      useErrorBoundary: false,
      initialData,
      enabled,
      onSuccess: async (slidesData) => {
        setSlides(slidesData);
      },
    },
  );

  return data;
};

// eslint-disable-next-line import/prefer-default-export
export { useSlides };
