/* ---------- React ----------*/
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { IconButton, MenuItem, Typography } from '@mui/material';
import { MoreIcon } from '@lunit/scope-icons';

/* ---------- Components ----------*/
import clsx from 'clsx';
import ContextMenu from '../../ContextMenu';
import EllipsizedTypography from '../../EllipsizedTypography';
import DataGridContainer from '../../DataGridContainer';

/* ---------- APIs ----------*/
import { Project } from '../../api/projects/models';

/* ---------- Hooks ----------*/
import usePopper from '../../hooks/usePopper';

interface IProjectTableProps {
  projects: Project[];
  isAnalyzer: boolean;
  handleEditProject: (project: Project) => void;
  handleDeleteProject: (project: Project) => void;
  customerCode: string;
  generateProjectLink: (project: Project, customerCode: string) => string;
}

const MoreIconComponent = styled(MoreIcon)({ padding: 0 });

const getColumns = ({
  handleDeleteProject,
  handleEditProject,
  isAnalyzer,
}: Pick<
  IProjectTableProps,
  'handleDeleteProject' | 'handleEditProject' | 'isAnalyzer'
>): GridColDef[] => [
  {
    field: 'title',
    headerName: 'Title',
    headerClassName: 'header',
    width: 256,
    sortable: false,
    renderCell: (params) => (
      <EllipsizedTypography
        direction="row"
        variant="body3"
        color="darkGrey.10"
      >
        {params.value}
      </EllipsizedTypography>
    ),
  },
  {
    field: 'description',
    headerName: 'Description',
    headerClassName: 'header',
    width: 458,
    sortable: false,
    flex: 1,
    renderCell: (params) => (
      <EllipsizedTypography
        variant="body5"
        color="darkGrey.30"
        direction="row"
      >
        {params.value}
      </EllipsizedTypography>
    ),
  },
  {
    field: 'slideCount',
    headerName: 'No. of Slides',
    headerClassName: 'header',
    headerAlign: 'right',
    align: 'right',
    width: 120,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => (
      <Typography variant="body6">{params.value}</Typography>
    ),
  },
  {
    field: 'fromDate',
    headerName: 'From',
    headerClassName: 'header',
    width: 120,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => (
      <Typography
        variant="body6"
        color="darkGrey.30"
      >
        {params.value}
      </Typography>
    ),
  },
  {
    field: 'toDate',
    headerName: 'To',
    headerClassName: 'header',
    width: 144,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      const { row, value } = params;
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const menuPopper = usePopper();
      return (
        <>
          <Typography
            variant="body6"
            color="darkGrey.30"
          >
            {value}
          </Typography>
          {!isAnalyzer && (
            <IconButton
              aria-label="Ellipsis Button"
              aria-expanded={menuPopper.open ? 'true' : undefined}
              className={clsx('hover-shown', menuPopper.open && 'menu-active')}
              onClick={(e) => {
                e.stopPropagation();
                menuPopper.onOpen(e);
              }}
              sx={{
                position: 'absolute',
                right: 4,
                color: '#fff',
                '&:hover, &.Mui-focused, &[aria-expanded*="true"]': {
                  backgroundColor: (theme) => theme.palette.background.selected,
                },
              }}
            >
              <MoreIconComponent className="MoreIcon" />
            </IconButton>
          )}
          <ContextMenu
            anchorEl={menuPopper.anchorEl}
            open={menuPopper.open}
            onClose={menuPopper.onClose}
          >
            <MenuItem
              onClick={() => {
                handleEditProject(row);
                menuPopper.onClose();
              }}
            >
              Edit Project
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleDeleteProject(row);
                menuPopper.onClose();
              }}
            >
              Delete Project
            </MenuItem>
          </ContextMenu>
        </>
      );
    },
  },
];

export default function ProjectTable({
  projects,
  isAnalyzer,
  handleEditProject,
  handleDeleteProject,
  customerCode,
  generateProjectLink,
}: IProjectTableProps) {
  const navigate = useNavigate();

  const columns = useMemo(
    () => getColumns({ handleEditProject, handleDeleteProject, isAnalyzer }),
    [handleEditProject, handleDeleteProject, isAnalyzer],
  );

  return (
    <DataGridContainer>
      <DataGrid
        rows={projects}
        columns={columns}
        headerHeight={40}
        rowHeight={40}
        showColumnRightBorder
        showCellRightBorder
        disableColumnMenu
        hideFooter
        onRowClick={(params) => {
          const projectLink = generateProjectLink
            ? generateProjectLink(params.row, customerCode)
            : `/projects/${params.id}/slides`;

          navigate(projectLink);
        }}
      />
    </DataGridContainer>
  );
}
