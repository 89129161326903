import * as React from 'react';
import type { SVGProps } from 'react';
const SvgIcInformationLargeoutlined20Px = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M10 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16Zm-9 8a9 9 0 1 1 18 0 9 9 0 0 1-18 0Zm8.25 4.247c0 .38.283.694.65.743a.503.503 0 0 0 .2 0 .75.75 0 0 0 .65-.743v-5.5a.75.75 0 0 0-1.5 0v5.5ZM10 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIcInformationLargeoutlined20Px;
