import type { ComponentsOverrides } from '@mui/material/styles';
import palette from '../colors';

const styleOverrides: ComponentsOverrides['MuiAppBar'] = {
  colorPrimary: {
    color: palette.text.primary,
    backgroundColor: palette.background.selected,
  },
};
export default {
  styleOverrides,
};
