import type { ComponentsOverrides } from '@mui/material/styles';
import typographyOptions from '../system/typography';
import palette from '../colors';

const styleOverrides: ComponentsOverrides['MuiTab'] = {
  root: {
    '&.Mui-selected': {
      color: palette.darkGrey[0],
    },
    color: palette.darkGrey[30],
    minHeight: 44,
    ...typographyOptions.button2,
  },
};
export default {
  styleOverrides,
};
