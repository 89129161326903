import { Box, Divider, Typography } from '@mui/material';
import { ManufacturerIcon } from '@lunit/scope-icons';
import {
  ProductName,
  LabelRow,
  LabelColumn,
  LabelValue,
  FramedText,
  LabelSubTitleTypography,
} from './styled';

function LabelContent() {
  return (
    <>
      <img
        alt="lunit-scope"
        src="/scope-b.svg"
        height="28px"
      />
      <Typography
        variant="body5"
        sx={{ mt: '10px' }}
      >
        Software Algorithm Device To Assist Users In Digital Pathology
      </Typography>
      <Divider sx={{ margin: '15px 0' }} />
      <Box>
        <ProductName variant="body3">Lunit SCOPE for Research</ProductName>
        <LabelRow>
          <LabelColumn>
            <FramedText color="darkGrey.85">RUO</FramedText>
          </LabelColumn>
          <LabelValue>
            <Typography
              variant="body3"
              color="darkGrey.85"
            >
              Research Use Only
            </Typography>
          </LabelValue>
        </LabelRow>
        <LabelRow>
          <LabelColumn>
            <ManufacturerIcon />
          </LabelColumn>
          <LabelValue>
            <LabelSubTitleTypography
              color="darkGrey.85"
              sx={{ ml: '11.5px' }}
            >
              Manufactured by Lunit Inc.
              <Typography
                sx={{
                  fontSize: 12,
                  lineHeight: '16px',
                  fontWeight: 400,
                  color: 'darkGrey.85',
                  letterSpacing: '-0.3px',
                }}
              >
                4-8F, 374, Gangnam-daero, Gangnam-gu, Seoul, 06241, Republic of Korea
                https://lunit.io
              </Typography>
            </LabelSubTitleTypography>
          </LabelValue>
        </LabelRow>
      </Box>
      <Divider sx={{ margin: '15px 0' }} />
      <Typography variant="caption">
        * For Research Use Only (RUO). Not for use in diagnostic procedures.
      </Typography>
    </>
  );
}

export default LabelContent;
