/* eslint-disable no-underscore-dangle */
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Box } from '@mui/material';
import darkCheck from '../../assets/ico_check_dark.png';
import lightCheck from '../../assets/ico_check_light.png';
import disabledCheck from '../../assets/ico_check_disabled.png';
import darkPartial from '../../assets/ico_check_partial_dark.png';
import lightPartial from '../../assets/ico_check_partial_light.png';
import { Checkbox, ControlWrapper } from './styled';
import { TaskClass, TaskClasses } from '../../types';

interface ControlProps {
  id: string;
  color: string;
  indented?: boolean;
  checked?: boolean;
  invisible?: boolean;
  halfChecked?: boolean;
  onChange?: (id: any) => void;
  children: React.ReactNode;
}

interface CheckImgSelectorProps {
  color: string;
  checked?: boolean;
  invisible?: boolean;
  halfChecked?: boolean;
}

function CheckImgSelector(props: CheckImgSelectorProps) {
  const { checked, invisible, halfChecked, color } = props;
  let isDarkColor;
  if (color) {
    const r = Number.parseInt(color.substr(1, 2), 16);
    const g = Number.parseInt(color.substr(3, 2), 16);
    const b = Number.parseInt(color.substr(5, 2), 16);
    // eslint-disable-next-line no-bitwise
    isDarkColor = ~~(0.2126 * r + 0.7152 * g + 0.0722 * b) < 128;
  }
  if (checked && invisible) {
    return (
      <img
        src={disabledCheck}
        alt=""
      />
    );
  }
  if (checked && isDarkColor) {
    return (
      <img
        src={lightCheck}
        alt=""
      />
    );
  }
  if (halfChecked && isDarkColor) {
    return (
      <img
        src={lightPartial}
        alt=""
      />
    );
  }
  if (checked) {
    return (
      <img
        src={darkCheck}
        alt=""
      />
    );
  }
  if (halfChecked) {
    return (
      <img
        src={darkPartial}
        alt=""
      />
    );
  }
  return null;
}

function Control(props: ControlProps) {
  const { id, color, indented, checked, invisible, onChange, halfChecked, children } = props;
  return (
    <ControlWrapper
      indented={indented}
      checked={checked}
      invisible={invisible}
    >
      <label htmlFor={`panelCheckBox-${id}`}>
        <input
          id={`panelCheckBox-${id}`}
          type="checkbox"
          checked={checked}
          onChange={onChange}
        />
        <Checkbox
          color={color}
          halfChecked={halfChecked}
          invisible={invisible}
        >
          <CheckImgSelector
            color={color}
            checked={checked}
            invisible={invisible}
            halfChecked={halfChecked}
          />
        </Checkbox>
        <span>{children}</span>
      </label>
    </ControlWrapper>
  );
}

function isaClass(classes: TaskClass[], targetClass: TaskClass) {
  return classes?.findIndex((aClass) => aClass.id === targetClass.id) >= 0;
}

function getShownClasses({
  tissueClasses,
  structureClasses,
  cellClasses,
  taskClasses,
}: {
  tissueClasses: any;
  structureClasses: any;
  cellClasses: any;
  taskClasses: TaskClasses;
}) {
  return (
    Array.isArray(taskClasses.__array__) &&
    taskClasses.__array__.filter((taskClass: TaskClass) => {
      const isTissueClass = isaClass(tissueClasses, taskClass);
      const isStructureClass = isaClass(structureClasses, taskClass);
      const isCellClass = isaClass(cellClasses, taskClass);
      return isTissueClass || isStructureClass || isCellClass;
    })
  );
}

function PanelSkeleton() {
  return (
    <>
      {Array.from({ length: 7 }).map((_, i) => (
        <Box
          key={`skeleton-${i.toString()}`}
          sx={{ padding: '8px 4px' }}
        >
          <Skeleton
            height={30}
            width={240}
          />
        </Box>
      ))}
    </>
  );
}

const MemoPanelSkeleton = React.memo(PanelSkeleton);

export { isaClass, getShownClasses, Control, CheckImgSelector, MemoPanelSkeleton as PanelSkeleton };
