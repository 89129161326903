import * as React from 'react';
import type { SVGProps } from 'react';
const SvgIcHideSmalloutlined20Px = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M14.926 7.644a.494.494 0 1 0-.948-.288v.002a3.655 3.655 0 0 1-.208.477c-.159.3-.412.692-.795 1.06-.354.34-.82.659-1.428.867a4.752 4.752 0 0 1-1.547.24 4.752 4.752 0 0 1-1.547-.24 3.831 3.831 0 0 1-1.428-.867 4.161 4.161 0 0 1-.96-1.42 2.268 2.268 0 0 1-.036-.096l-.007-.021v-.003a.494.494 0 0 0-.616-.334.506.506 0 0 0-.331.625v.001l.002.005.003.012a2.073 2.073 0 0 0 .065.18 5.216 5.216 0 0 0 .577 1.054l-1.057.993a.548.548 0 0 0-.048.73c.172.216.474.24.673.052l1.087-1.021c.273.257.597.503.98.714l-.663 1.257a.542.542 0 0 0 .177.706c.23.142.52.055.652-.193l.731-1.387c.378.119.794.201 1.251.236V12.5c0 .277.222.501.495.501a.498.498 0 0 0 .495-.5v-1.515a5.579 5.579 0 0 0 1.212-.224l.725 1.375c.13.248.422.335.651.193a.541.541 0 0 0 .178-.706l-.653-1.238a4.811 4.811 0 0 0 1.015-.733l1.087 1.02c.2.188.5.165.673-.051a.548.548 0 0 0-.048-.73l-1.057-.993a5.198 5.198 0 0 0 .577-1.054 3.23 3.23 0 0 0 .064-.18l.005-.012v-.005l.001-.001v-.002Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIcHideSmalloutlined20Px;
