import * as React from 'react';
import type { SVGProps } from 'react';
const SvgIcHideLargeoutlined20Px = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M3.975 6.344a.5.5 0 0 0-.95.31L3.5 6.5l-.475.155v.003l.002.005.006.016.019.052c.016.043.04.103.071.178.065.149.163.356.303.599.222.385.55.864 1.017 1.342l-1.296 1.297a.5.5 0 1 0 .707.707l1.34-1.34c.458.348 1.003.669 1.646.923l-.8 1.866a.5.5 0 1 0 .92.394l.837-1.954a8.805 8.805 0 0 0 1.703.245V13.5a.5.5 0 1 0 1 0v-2.512a8.804 8.804 0 0 0 1.703-.245l.838 1.954a.5.5 0 1 0 .919-.394l-.8-1.866a6.913 6.913 0 0 0 1.646-.923l1.39 1.39a.42.42 0 0 0 .632-.026.5.5 0 0 0 .026-.731L15.557 8.85a6.77 6.77 0 0 0 1.018-1.342 5.823 5.823 0 0 0 .374-.777l.018-.052.006-.016.002-.005v-.003L16.5 6.5l.476.155a.5.5 0 0 0-.951-.31l-.002.005a4.82 4.82 0 0 1-.315.659c-.236.41-.619.95-1.197 1.456a5.943 5.943 0 0 1-2.17 1.201 7.826 7.826 0 0 1-2.34.334 7.825 7.825 0 0 1-2.342-.334 5.943 5.943 0 0 1-2.17-1.201 5.783 5.783 0 0 1-1.197-1.456 4.814 4.814 0 0 1-.304-.628 1.486 1.486 0 0 1-.01-.031l-.003-.006Z" />
  </svg>
);
export default SvgIcHideLargeoutlined20Px;
