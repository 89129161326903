import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

import { IcRefreshLeftOutlined20Px as outlined } from '../../assets';

const variants = ['outlined', ] as const;
type Variant = typeof variants[number];
type IconProps = SvgIconProps & {
  variant?: Variant;
};
const RefreshLeft = ({variant = 'outlined', ...rest}: IconProps) => {
  if (variant === 'outlined') {
    return <SvgIcon {...rest} component={ outlined } inheritViewBox />;
  }

  return null;
};

RefreshLeft.displayName = 'RefreshLeft';
RefreshLeft.variants = variants;

export default RefreshLeft;
