import type { ComponentsOverrides, ComponentsProps } from '@mui/material/styles';

const styleOverrides: ComponentsOverrides['MuiListItemText'] = {
  root: {
    margin: 0,
  },
};
const defaultProps: ComponentsProps['MuiListItemText'] = {
  color: 'inherit',
  primaryTypographyProps: {
    variant: 'body5',
  },
};
export default {
  styleOverrides,
  defaultProps,
};
