import React from 'react';
import { createPortal } from 'react-dom';
import { CloseIcon } from '@lunit/scope-icons';
import { CloseButton, Container, Content } from './styled';

interface LabelDialogProps {
  open: boolean;
  children: React.ReactNode;
  handleClose: () => void;
}

function LabelDialog({ open, children, handleClose }: LabelDialogProps) {
  return open
    ? createPortal(
        <Container
          open
          maxWidth={false}
          onClose={handleClose}
          aria-labelledby="label-dialog-title"
        >
          <CloseButton
            aria-label="close"
            onClick={handleClose}
            size="large"
          >
            <CloseIcon />
          </CloseButton>
          <Content>{children}</Content>
        </Container>,
        document.querySelector('#dialog-root'),
      )
    : null;
}

export default LabelDialog;
