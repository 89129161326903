import type { ThemeOptions } from '@mui/material/styles';
import typography from './system/typography';
import { spacing } from './system/spacing';
import MuiAccordion from './components/MuiAccordion';
import MuiAccordionSummary from './components/MuiAccordionSummary';
import MuiAlert from './components/MuiAlert';
import MuiAppBar from './components/MuiAppBar';
import MuiButton from './components/MuiButton';
import MuiCheckbox from './components/MuiCheckbox';
import MuiCircularProgress from './components/MuiCircularProgress';
import MuiCssBaseline from './components/MuiCssBaseline';
import MuiDialog from './components/MuiDialog';
import MuiDialogActions from './components/MuiDialogActions';
import MuiDialogContent from './components/MuiDialogContent';
import MuiDialogContentText from './components/MuiDialogContentText';
import MuiDialogTitle from './components/MuiDialogTitle';
import MuiDivider from './components/MuiDivider';
import MuiIconButton from './components/MuiIconButton';
import MuiInputBase from './components/MuiInputBase';
import MuiInputLabel from './components/MuiInputLabel';
import MuiLinearProgress from './components/MuiLinearProgress';
import MuiLink from './components/MuiLink';
import MuiList from './components/MuiList';
import MuiListItem from './components/MuiListItem';
import MuiListItemButton from './components/MuiListItemButton';
import MuiListItemIcon from './components/MuiListItemIcon';
import MuiListItemSecondaryAction from './components/MuiListItemSecondaryAction';
import MuiListItemText from './components/MuiListItemText';
import MuiListSubheader from './components/MuiListSubheader';
import MuiMenu from './components/MuiMenu';
import MuiMenuItem from './components/MuiMenuItem';
import MuiModal from './components/MuiModal';
import MuiOutlinedInput from './components/MuiOutlinedInput';
import MuiPaper from './components/MuiPaper';
import MuiPopover from './components/MuiPopover';
import MuiSelect from './components/MuiSelect';
import MuiSwitch from './components/MuiSwitch';
import MuiTab from './components/MuiTab';
import MuiTable from './components/MuiTable';
import MuiTableCell from './components/MuiTableCell';
import MuiTableHead from './components/MuiTableHead';
import MuiTableRow from './components/MuiTableRow';
import MuiTabPanel from './components/MuiTabPanel';
import MuiTabs from './components/MuiTabs';
import MuiTextField from './components/MuiTextField';
import MuiToolbar from './components/MuiToolbar';
import MuiTooltip from './components/MuiTooltip';

const baseOptions: ThemeOptions = {
  typography,
  components: {
    MuiAccordion,
    MuiAccordionSummary,
    MuiAlert,
    MuiAppBar,
    MuiButton,
    MuiCheckbox,
    MuiCircularProgress,
    MuiCssBaseline,
    MuiDialog,
    MuiDialogActions,
    MuiDialogContent,
    MuiDialogContentText,
    MuiDialogTitle,
    MuiDivider,
    MuiIconButton,
    MuiInputBase,
    MuiInputLabel,
    MuiLinearProgress,
    MuiLink,
    MuiList,
    MuiListItem,
    MuiListItemButton,
    MuiListItemIcon,
    MuiListItemSecondaryAction,
    MuiListItemText,
    MuiListSubheader,
    MuiMenu,
    MuiMenuItem,
    MuiModal,
    MuiOutlinedInput,
    MuiPaper,
    MuiPopover,
    MuiSelect,
    MuiSwitch,
    MuiTab,
    MuiTable,
    MuiTableCell,
    MuiTableHead,
    MuiTableRow,
    MuiTabPanel,
    MuiTabs,
    MuiTextField,
    MuiToolbar,
    MuiTooltip,
  },
};

export default baseOptions;
