import { ReactNode } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { CloseIcon } from '@lunit/scope-icons';

export enum FormMode {
  Add = 'Add',
  Edit = 'Edit',
}

export interface CommonFormProps {
  open: boolean;
  width?: number;
  height?: number;
  loading?: boolean;
  isValidating?: boolean;
  submitActionName?: string;
  additionalAction?: ReactNode;
  onCancel: () => void;
  onSubmit?: (data: any) => void;
}

interface FormTemplateProps extends CommonFormProps {
  children: ReactNode;
  tab?: ReactNode;
  title: string;
}

function Template({
  tab,
  open,
  title,
  width,
  height,
  loading,
  children,
  isValidating,
  submitActionName = 'Confirm',
  additionalAction,
  onCancel,
  onSubmit,
}: FormTemplateProps) {
  return (
    <Dialog
      aria-labelledby="modal-form-title"
      open={open}
      onClose={onCancel}
      PaperProps={{
        elevation: 1,
        sx: {
          width: `${width || 500}px`,
          height: height ? `${height}px` : 'auto',
          pb: '32px',
        },
      }}
    >
      <form onSubmit={onSubmit}>
        <DialogTitle
          id="modal-form-title"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="subtitle3">{title}</Typography>
          {!loading ? (
            <Tooltip title="Close">
              <IconButton
                size="small"
                aria-label="close"
                onClick={onCancel}
                disabled={loading}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <CircularProgress size={28} />
          )}
        </DialogTitle>
        {tab}
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          {additionalAction}
          <Button
            onClick={onCancel}
            disabled={loading}
            sx={{ marginLeft: 'auto!important' }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={loading || isValidating}
          >
            {submitActionName}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default Template;
