import { TypographyOptions } from '@mui/material/styles/createTypography';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    h7: React.CSSProperties;
    h8: React.CSSProperties;
    h9: React.CSSProperties;
    h10: React.CSSProperties;

    subtitle3: React.CSSProperties;

    body_b1: React.CSSProperties;
    body_b2: React.CSSProperties;

    body_sb1: React.CSSProperties;
    body_sb2: React.CSSProperties;
    body_sb3: React.CSSProperties;
    body_sb4: React.CSSProperties;
    body_sb5: React.CSSProperties;

    body_m1: React.CSSProperties;
    body_m2: React.CSSProperties;

    body1: React.CSSProperties;
    body2: React.CSSProperties;
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    body5: React.CSSProperties;
    body6: React.CSSProperties;
    body7: React.CSSProperties;
    body8: React.CSSProperties;

    small_body_b1: React.CSSProperties;
    small_body_b2: React.CSSProperties;
    small_body_b3: React.CSSProperties;
    small_body_b4: React.CSSProperties;
    small_body_b5: React.CSSProperties;

    small_body_sb1: React.CSSProperties;

    small_body_m1: React.CSSProperties;
    small_body_m2: React.CSSProperties;
    small_body_m3: React.CSSProperties;
    small_body_m4: React.CSSProperties;
    small_body_m5: React.CSSProperties;
    small_body_m6: React.CSSProperties;
    small_body_m7: React.CSSProperties;

    small_body1: React.CSSProperties;
    small_body2: React.CSSProperties;
    small_body3: React.CSSProperties;
    small_body4: React.CSSProperties;
    small_body5: React.CSSProperties;

    small_body_l1: React.CSSProperties;
    small_body_l2: React.CSSProperties;

    overline: React.CSSProperties;

    button1: React.CSSProperties;
    button2: React.CSSProperties;

    caption: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    h7?: React.CSSProperties;
    h8?: React.CSSProperties;
    h9?: React.CSSProperties;
    h10?: React.CSSProperties;

    subtitle3?: React.CSSProperties;

    body_b1?: React.CSSProperties;
    body_b2?: React.CSSProperties;

    body_sb1?: React.CSSProperties;
    body_sb2?: React.CSSProperties;
    body_sb3?: React.CSSProperties;
    body_sb4?: React.CSSProperties;
    body_sb5?: React.CSSProperties;

    body_m1?: React.CSSProperties;
    body_m2?: React.CSSProperties;

    body1?: React.CSSProperties;
    body2?: React.CSSProperties;
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
    body5?: React.CSSProperties;
    body6?: React.CSSProperties;
    body7?: React.CSSProperties;
    body8?: React.CSSProperties;

    small_body_b1?: React.CSSProperties;
    small_body_b2?: React.CSSProperties;
    small_body_b3?: React.CSSProperties;
    small_body_b4?: React.CSSProperties;
    small_body_b5?: React.CSSProperties;

    small_body_sb1?: React.CSSProperties;

    small_body_m1?: React.CSSProperties;
    small_body_m2?: React.CSSProperties;
    small_body_m3?: React.CSSProperties;
    small_body_m4?: React.CSSProperties;
    small_body_m5?: React.CSSProperties;
    small_body_m6?: React.CSSProperties;
    small_body_m7?: React.CSSProperties;

    small_body1?: React.CSSProperties;
    small_body2?: React.CSSProperties;
    small_body3?: React.CSSProperties;
    small_body4?: React.CSSProperties;
    small_body5?: React.CSSProperties;

    small_body_l1?: React.CSSProperties;
    small_body_l2?: React.CSSProperties;

    overline?: React.CSSProperties;

    button1?: React.CSSProperties;
    button2?: React.CSSProperties;

    caption?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h7: true;
    h8: true;
    h9: true;
    h10: true;

    subtitle3: true;

    body_b1: true;
    body_b2: true;

    body_sb1: true;
    body_sb2: true;
    body_sb3: true;
    body_sb4: true;
    body_sb5: true;

    body_m1: true;
    body_m2: true;

    body1: true;
    body2: true;
    body3: true;
    body4: true;
    body5: true;
    body6: true;
    body7: true;
    body8: true;

    small_body_b1: true;
    small_body_b2: true;
    small_body_b3: true;
    small_body_b4: true;
    small_body_b5: true;

    small_body_sb1: true;

    small_body_m1: true;
    small_body_m2: true;
    small_body_m3: true;
    small_body_m4: true;
    small_body_m5: true;
    small_body_m6: true;
    small_body_m7: true;

    small_body1: true;
    small_body2: true;
    small_body3: true;
    small_body4: true;
    small_body5: true;

    small_body_l1: true;
    small_body_l2: true;

    overline: true;

    button1: true;
    button2: true;

    caption: true;
  }
}

const typographyOptions: TypographyOptions = {
  fontFamily: [
    'Pretendard',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  h1: {
    fontWeight: 600,
    fontSize: '52px',
    lineHeight: '68px',
  },
  h2: {
    fontWeight: 700,
    fontSize: '38px',
    lineHeight: '48px',
  },
  h3: {
    fontWeight: 500,
    fontSize: '38px',
    lineHeight: '48px',
  },
  h4: {
    fontWeight: 600,
    fontSize: '30px',
    lineHeight: '48px',
  },
  h5: {
    fontWeight: 400,
    fontSize: '30px',
    lineHeight: '48px',
  },
  h6: {
    fontWeight: 700,
    fontSize: '28px',
    lineHeight: 'auto',
  },
  h7: {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '36px',
  },
  h8: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '28px',
  },
  h9: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
  },
  subtitle1: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '28px',
  },
  subtitle2: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '24px',
  },
  subtitle3: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
  },

  body_b1: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
  },
  body_b2: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: 'auto',
  },

  body_sb1: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
  },
  body_sb2: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
  },
  body_sb3: {
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: '16px',
  },
  body_sb4: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
  },
  body_sb5: {
    fontWeight: 600,
    fontSize: '12.4px',
    lineHeight: '16px',
  },

  body_m1: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
  },
  body_m2: {
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '18px',
    letterSpacing: '0.2px',
  },

  body1: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
  },
  body2: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: 'auto',
  },
  body3: {
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '24px',
  },
  body4: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '28px',
  },
  body5: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
  },
  body6: {
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '18px',
  },
  body7: {
    fontWeight: 400,
    fontSize: '12.4px',
    lineHeight: '16px',
  },
  body8: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
  },

  small_body_b1: {
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '12px',
  },
  small_body_b2: {
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px',
  },
  small_body_b3: {
    fontWeight: 700,
    fontSize: '10px',
    lineHeight: 'auto',
  },
  small_body_b4: {
    fontWeight: 700,
    fontSize: '10px',
    lineHeight: '12px',
  },
  small_body_b5: {
    fontWeight: 700,
    fontSize: '8px',
    lineHeight: 'auto',
  },

  small_body_sb1: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
  },

  small_body_m1: {
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '20px',
  },
  small_body_m2: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '20px',
  },
  small_body_m3: {
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: 'auto',
  },
  small_body_m4: {
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '16px',
  },
  small_body_m5: {
    fontWeight: 500,
    fontSize: '8px',
    lineHeight: 'auto',
  },
  small_body_m6: {
    fontWeight: 500,
    fontSize: '8px',
    lineHeight: '12px',
  },
  small_body_m7: {
    fontWeight: 500,
    fontSize: '7px',
    lineHeight: 'auto',
  },

  small_body1: {
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '24px',
  },
  small_body2: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '20px',
  },
  small_body3: {
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: 'auto',
  },
  small_body4: {
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '14px',
  },
  small_body5: {
    fontWeight: 400,
    fontSize: '8px',
    lineHeight: 'auto',
  },

  small_body_l1: {
    fontWeight: 300,
    fontSize: '10px',
    lineHeight: '20px',
  },
  small_body_l2: {
    fontWeight: 300,
    fontSize: '7px',
    lineHeight: 'auto',
  },

  overline: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },

  button1: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    textTransform: 'capitalize',
  },
  button2: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
    textTransform: 'capitalize',
  },

  caption: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
  },
};

export default typographyOptions;
