import { atom } from 'recoil';
import { Projects } from '../api/project/models';

const projectsAtom = atom<Projects>({
  key: 'projects',
  default: {
    customerCode: '',
    projects: [],
  },
});

const isCommentEnabledAtom = atom<boolean>({
  key: 'isCommentEnabled',
  default: false,
});

export { projectsAtom, isCommentEnabledAtom };
