import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import { MyInfo } from 'src/api/auth/models';
import { APIError } from 'src/api/utils/apiRequestHelper';
import * as authAPI from '../api/auth/queries';

export const useSignupInfoQuery = () => {
  const [refetchOnWindowFocus, setRefetchOnWindowFocus] = useState(true);
  const data = useQuery(authAPI.getSignupInfo.key(), authAPI.getSignupInfo, {
    suspense: false,
    useErrorBoundary: false,
    refetchOnWindowFocus,
  });
  useEffect(() => {
    if (data.isError) {
      setRefetchOnWindowFocus(true);
    } else {
      setRefetchOnWindowFocus(false);
    }
  }, [data.isError]);
  return data;
};

export const useMyInfoQuery = () => {
  const data = useQuery<unknown, APIError, MyInfo>(
    authAPI.getCurrentUser.key(),
    authAPI.getCurrentUser,
    {
      suspense: false,
      useErrorBoundary: false,
      cacheTime: 1,
      refetchOnWindowFocus: false,
    },
  );
  return data;
};
