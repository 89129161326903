import * as React from 'react';
import type { SVGProps } from 'react';
const SvgIcDownloadPdfoutlined20Px = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M3.051 2.343V8h1.014V6.086h1.088c1.307 0 2.009-.785 2.009-1.87 0-1.08-.694-1.873-1.995-1.873H3.051Zm1.014 2.906V3.193h.947c.768 0 1.11.42 1.11 1.022 0 .603-.342 1.034-1.104 1.034h-.953ZM9.939 2.343h-1.95V8h1.914c1.724 0 2.74-1.066 2.74-2.834 0-1.763-1.016-2.823-2.704-2.823Zm-.937 4.776V3.224h.882c1.152 0 1.751.643 1.751 1.942 0 1.3-.6 1.953-1.782 1.953h-.85Z"
      clipRule="evenodd"
    />
    <path d="M14.565 5.594V8H13.55V2.343h3.619v.856h-2.605V4.74h2.356v.854h-2.356ZM13.124 13.67l-2.624 3V11a.5.5 0 1 0-1 0v5.67l-2.624-3a.5.5 0 1 0-.752.66l3.5 4a.5.5 0 0 0 .752 0l3.5-4a.5.5 0 0 0-.752-.66Z" />
  </svg>
);
export default SvgIcDownloadPdfoutlined20Px;
