import { useQuery } from 'react-query';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import * as projectQueries from '../api/project/queries';
import { projectsAtom } from '../state/projects';

const useProjects = (enabled = true) => {
  const setProjects = useSetRecoilState(projectsAtom);

  const initialData = useRecoilValue(projectsAtom);

  const data = useQuery(projectQueries.getProjects.key(), () => projectQueries.getProjects(), {
    suspense: false,
    cacheTime: 3600 * 1000,
    refetchOnWindowFocus: false,
    useErrorBoundary: false,
    initialData,
    enabled,
    onSuccess: async (projectsData) => {
      setProjects(projectsData);
    },
  });
  return data;
};

// eslint-disable-next-line import/prefer-default-export
export { useProjects };
