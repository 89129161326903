import { styled, Theme } from '@mui/material';
import { styles } from '../BaseTextField/BaseTextField.styled';
import type { RootTextAreaProps } from './TextArea.types';
import { formatCSSSize } from '../BaseTextField/BaseTextField.utils';

const baseTextAreaStyle = (theme: Theme, height: string | number | undefined) => {
  return {
    ...styles.baseTextFieldStyle(theme),
    height: height ? formatCSSSize(height) : '100px',
    resize: 'none' as const,
    '&::-webkit-scrollbar': {
      width: '14px !important',
    },
    '&::-webkit-scrollbar-thumb': {
      border: `4px solid ${theme.palette.neutralGrey[75]}`,
    },
  };
};

const BaseTextArea = styled('textarea', {
  shouldForwardProp: (prop) => !['error', 'width', 'height'].includes(prop.toString()),
})<RootTextAreaProps>(({ theme, height, error }) => {
  let resultStyle = baseTextAreaStyle(theme, height);

  if (error) {
    resultStyle = {
      ...resultStyle,
      ...styles.baseTextFieldErrorStyle(theme),
    };
  }

  return resultStyle;
});

export default BaseTextArea;
