import { styled } from '@mui/material/styles';

const DataGridContainer = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
  '.MuiDataGrid-root': {
    border: 'none',
    overflowY: 'hidden',
    borderRadius: 0,
    '.MuiDataGrid-withBorder': {
      borderRight: `1px solid ${theme.palette.neutralGrey[80]}`,
      padding: theme.spacing(0, 2),
      height: '40px',
      '&:last-child': { borderRight: 'none' },
    },
    '.MuiDataGrid-row': {
      '&:last-child .MuiDataGrid-cell': {
        borderBottom: `1px solid ${theme.palette.neutralGrey[80]}`,
      },
      '.MuiDataGrid-cellCheckbox': {
        borderRight: 0,
      },
      '&:hover': { backgroundColor: theme.palette.others.RowHover },
      '&.Mui-selected': {
        backgroundColor: theme.palette.others.RowHover,
        border: 'transparent',
        '&:hover': { backgroundColor: theme.palette.others.RowHover },
      },
      '& .hover-shown': { opacity: 0 },
      '&:hover .hover-shown, & .hover-shown.menu-active': { opacity: 1 },
    },
    '.MuiDataGrid-cell': {
      ...theme.typography.body5,
      borderBottom: `1px solid ${theme.palette.neutralGrey[80]}`,
      padding: theme.spacing(0, 2),
      '&:focus, &:focus-within, &:focus-visible': { outline: 'none' },
    },
    '.MuiDataGrid-columnSeparator': { position: 'relative' },
    '.MuiDataGrid-iconSeparator': { display: 'none' },
    '.MuiDataGrid-columnHeader': {
      '&:focus': { outline: 'none' },
    },
    '.MuiDataGrid-columnHeaderTitleContainer': {
      padding: 0,
      '&:focus': { outline: 'none' },
    },
  },
  '.MuiDataGrid-columnHeaders': {
    borderTop: `1px solid ${theme.palette.neutralGrey[80]}`,
    borderBottom: `1px solid ${theme.palette.neutralGrey[80]}`,
    borderRadius: 0,
    '&:focus': { outline: 'none' },
    '.MuiDataGrid-columnHeaderCheckbox': {
      borderRight: 0,
    },
  },
  '.MuiDataGrid-columnHeaderTitle': {
    ...theme.typography.body_b1,
    color: theme.palette.neutralGrey[40],
  },
}));

export default DataGridContainer;
