import type { ComponentsOverrides } from '@mui/material/styles';

const styleOverrides: ComponentsOverrides['MuiDialogContentText'] = {
  root: {
    margin: '20px 0',
    fontWeight: 400,
    fontSize: 14,
  },
};
export default {
  styleOverrides,
};
