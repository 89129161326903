import type { ComponentsOverrides } from '@mui/material/styles';
import palette from '../colors';

const styleOverrides: ComponentsOverrides['MuiSwitch'] = {
  root: {
    width: 28,
    height: 18,
    padding: 0,
    margin: 8,
  },
  switchBase: {
    top: 0,
    padding: 2,
    color: '#fff',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(10px)',
      '& + .MuiSwitch-track': {
        backgroundColor: palette.brand.primary,
        opacity: 1,
        border: 'none',
      },
    },
    '&.Mui-disabled': {
      color: '#fff',
      opacity: 0.3,
      transform: 'translateX(10px)',
      '& + .MuiSwitch-track': {
        backgroundColor: palette.brand.primary,
        opacity: 0.3,
        border: 'none',
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#7292FD',
      border: '6px solid #fff',
    },
  },
  thumb: {
    boxShadow: 'none',
    width: 14,
    height: 14,
  },
  track: {
    borderRadius: '40px',
    backgroundColor: palette.darkGrey[30],
    opacity: 1,
  },
  checked: {},
};
export default {
  styleOverrides,
};
