import { Box, Typography, styled } from '@mui/material';
import { Chip } from '../Chip';
import theme from '../../theme';
import type { StepperTypographyProps } from './Stepper.types';

export const StepperContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '64px',
});
export const ChipContainer = styled(Box)({
  display: 'inline-block',
});

export const StepperChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})(({ disabled }) => ({
  backgroundColor: `${
    !disabled ? theme.palette.lunitTeal[40] : theme.palette.neutralGrey[70]
  } !important`,
  color: `${!disabled ? theme.palette.lunitTeal[90] : theme.palette.neutralGrey[95]} !important`,
  marginBottom: '4px',
  '.MuiChip-label ': {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
  },
}));

export const StepperTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<StepperTypographyProps>(({ disabled }) => ({
  color: !disabled ? 'white' : theme.palette.neutralGrey[70],
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  cursor: 'default',
}));
