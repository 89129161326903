import type { ComponentsOverrides } from '@mui/material/styles';

const styleOverrides: ComponentsOverrides['MuiTableRow'] = {
  root: {
    height: 52,
    '&$selected': {
      backgroundColor: 'rgba(114, 146, 253, 0.16)',
    },
  },
  head: {
    height: 48,
  },
  hover: {
    '&.MuiTableRow-root.Mui-selected:hover': {
      backgroundColor: 'rgba(114, 146, 253, 0.16)',
      background: 'red',
    },
    '&.MuiTableRow-root:hover': {
      backgroundColor: 'rgba(134, 148, 177, 0.08)',
    },
  },
};
export default {
  styleOverrides,
};
