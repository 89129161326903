import type { ComponentsOverrides } from '@mui/material/styles';
import palette from '../colors';

const styleOverrides: ComponentsOverrides['MuiPopover'] = {
  paper: {
    backgroundImage: 'none',
    backgroundColor: palette.darkGrey[70],
  },
};
export default {
  styleOverrides,
};
