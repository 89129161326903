export const INITIAL_ZOOM = 1;

export const ZOOM_LEVELS = [0.1, 0.5, 1, 2, 5, 10, 20, 40, 160];

export const dummyToolState = {
  activeToolType: 0,
  activeToolId: 'hand',
  tempHandEnabled: false,
  toolsEnabled: false,
};

export const projectInitialState = {
  on: true,
  childState: [],
};
