import { ListSize } from './List.types';

const getPadding = (size: ListSize) => {
  if (size === 'large') {
    return '14px';
  }
  if (size === 'medium') {
    return '10px';
  }
  // 'small'
  return '6px';
};

export default getPadding;
