import PageTemplate from '@lunit/oncology-components/PageTemplate';
import React from 'react';
import {
  Container,
  InnerViewerContainer,
  osdContainerSX,
  OuterViewerContainer,
} from './SlideViewerMUI';

import ZoomController from './ZoomController';
// import BasicToolbar from '../Toolbar/BasicToolbar';

interface SlideViewerContainerProps {
  toolbar?: React.ReactNode;
  toolState: {
    activeToolType: number;
    activeToolId: string;
    tempHandEnabled: boolean;
    toolsEnabled: boolean;
  };
  slideViewport: React.ReactNode;
  sidePanel: React.ReactNode;
}

function SlideViewerContainer({
  toolbar,
  toolState,
  slideViewport,
  sidePanel,
}: SlideViewerContainerProps) {
  return (
    // <PageTemplate toolbar={toolbar || <BasicToolbar />}>
    <PageTemplate toolbar={toolbar}>
      <Container sx={osdContainerSX}>
        <OuterViewerContainer>
          <InnerViewerContainer {...toolState}>
            {slideViewport}
            <ZoomController />
          </InnerViewerContainer>
        </OuterViewerContainer>
        {sidePanel}
      </Container>
    </PageTemplate>
  );
}
export default SlideViewerContainer;
