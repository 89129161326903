import { forwardRef, useState } from 'react';
import { InputMsg, BaseTextFieldContainer } from '../../BaseTextField/BaseTextField.styled';
import { BaseTextInput, LeftIconContainer, RightIconContainer } from '../TextInput.styled';
import { ShowHidePasswordButton } from './buttons';

import type { PasswordTextInputProps } from '../TextInput.types';

interface PasswordInputContainerProps extends PasswordTextInputProps {
  children: React.ReactNode;
  hidePassword: boolean;
  handlePasswordShowHide: () => void;
  width?: string | number;
}

function PasswordInputContainer(props: PasswordInputContainerProps) {
  const {
    error,
    disabled,
    helperMsg,
    leftIcon,
    children,
    hidePassword,
    handlePasswordShowHide,
    width,
    OuterBoxProps,
  } = props;

  return (
    <BaseTextFieldContainer
      {...OuterBoxProps}
      width={width}
    >
      {leftIcon && (
        <LeftIconContainer sx={disabled ? { opacity: '0.8' } : undefined}>
          {leftIcon}
        </LeftIconContainer>
      )}
      {children}
      <RightIconContainer error={error}>
        {!disabled && (
          <ShowHidePasswordButton
            onClick={(event) => {
              event.preventDefault();
              handlePasswordShowHide();
            }}
            isHidden={hidePassword}
          />
        )}
      </RightIconContainer>
      {error ? (
        <InputMsg
          variant="body5"
          error
        >
          {error}
        </InputMsg>
      ) : (
        helperMsg && <InputMsg variant="body5">{helperMsg}</InputMsg>
      )}
    </BaseTextFieldContainer>
  );
}

const PasswordInput = forwardRef(
  (props: PasswordTextInputProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const { error, helperMsg, leftIcon, type, inputSX, ...otherInputProps } = props;

    const [hidePassword, setHidePassword] = useState(true);

    function handlePasswordShowHide() {
      setHidePassword(!hidePassword);
    }

    return (
      <PasswordInputContainer
        {...props}
        hidePassword={hidePassword}
        handlePasswordShowHide={() => handlePasswordShowHide()}
      >
        <BaseTextInput
          type={hidePassword ? 'password' : 'text'}
          ref={ref}
          {...otherInputProps}
          leftIcon={leftIcon}
          sx={inputSX}
        />
      </PasswordInputContainer>
    );
  },
);

export default PasswordInput;
