import { CloseSmall } from '@lunit/oncology-icons';
import StyledChip from './Chip.styled';
import type { ChipProps } from './Chip.types';
import { presetMap } from './Chip.utils';

function Chip({ preset, label, icon, onDelete, onClick, ...otherProps }: ChipProps) {
  const overwrittenProps: any = {
    label,
    icon,
    onDelete,
    onClick,
  };
  // Remove undefined properties
  Object.keys(overwrittenProps).forEach((key) =>
    overwrittenProps[key] === undefined ? delete overwrittenProps[key] : {},
  );

  const customProps = {
    disableRipple: true,
    deleteIcon: <CloseSmall />,
    ...presetMap[preset || 'default'],
    ...overwrittenProps,
    ...otherProps,
  };

  return <StyledChip {...customProps} />;
}

export default Chip;
