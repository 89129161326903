import { Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { DividerProps } from './Divider.types';

const BaseDivider = styled(Divider)<DividerProps>(({ theme, orientation }) => {
  if (orientation === 'vertical') {
    return {
      background: theme.palette.neutralGrey[65],
      border: 'none',
      height: '100%',
      outline: 'none',
      width: '1px',
    };
  }
  return {
    background: theme.palette.neutralGrey[65],
    border: 'none',
    height: '1px',
    outline: 'none',
    width: '100%',
  };
});

export default BaseDivider;
