import type { ComponentsOverrides } from '@mui/material/styles';

const styleOverrides: ComponentsOverrides['MuiListItemSecondaryAction'] = {
  root: {
    color: 'inherit',
    right: 16,
  },
};
export default {
  styleOverrides,
};
