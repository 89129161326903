import { useCallback, useEffect, useRef } from 'react';
import useViewerZoom from 'src/hooks/useViewerZoom';
import { Origin } from 'src/hooks/visualizer/webgl/types';
import useWebGLCellDrawing from 'src/hooks/visualizer/webgl/useWebGLCellDrawing';
import OffscreenImageMask from '../../pages/visualizer/component/Classes/OffscreenImageMask';
import { DataList, SlideData, TaskClasses } from '../../pages/visualizer/types';
import {
  assembleAreaList,
  assembleCellList,
  assembleContourList,
  assembleTissueData,
  makeImageMasks,
} from './func';

interface UseVisualization {
  prepare: () => Promise<any[]> | Promise<void>;
  drawWithCanvas: (
    ctx: CanvasRenderingContext2D,
    viewer: OpenSeadragon.Viewer,
    enabledClasses: string[],
  ) => void;
  drawWithWebGL: (
    ctx: CanvasRenderingContext2D,
    glCtx: WebGL2RenderingContext,
    viewer: OpenSeadragon.Viewer,
    origin: Origin,
    enabledClasses: string[],
  ) => void;
  rerender: (enabledClasses: string[], canvasOverlayRef: any) => void;
  destroy: () => void;
  canPrepareVisualization: boolean;
}

function useVisualization(slideData: SlideData, taskClasses: TaskClasses): UseVisualization {
  const imageMasks = useRef<any[]>([]);
  const areaListArray = useRef<any[]>([]);
  const cellListArray = useRef<any[]>([]);
  const contourListArray = useRef<any[]>([]);
  const resultPrepared = useRef<boolean>(false);

  const { handleCellDrawing } = useWebGLCellDrawing();

  const {
    zoomState: { zoom },
  } = useViewerZoom();

  useEffect(() => {
    if (taskClasses || slideData?.currentSlide) {
      resultPrepared.current = false;
    }
  }, [slideData, taskClasses]);

  const canPrepareVisualization = !!taskClasses && !!slideData?.currentSlide;

  const prepare = useCallback(() => {
    const dataList = slideData?.currentSlide.dataList;
    if (!canPrepareVisualization || resultPrepared.current) {
      return Promise.reject(new Error("Can't prepare visualization"));
    }
    const areaData = dataList.filter((data: DataList) => data.dataType === 1);
    if (areaData.length > 0) {
      areaListArray.current = assembleAreaList(areaData, taskClasses);
    }
    const contourData = dataList.filter((data: DataList) => data.dataType === 4);
    if (contourData.length > 0) {
      contourListArray.current = assembleContourList(contourData, taskClasses);
    }
    const cellData = dataList.filter((data: DataList) => data.dataType === 2);
    if (cellData.length > 0) {
      cellListArray.current = assembleCellList(cellData, taskClasses);
    }
    const tissueDataCandidate = dataList.filter((data: DataList) => data.dataType === 3);
    const tissueData = tissueDataCandidate.length
      ? [...assembleTissueData(tissueDataCandidate, taskClasses)]
      : null;
    if (!tissueData) {
      return Promise.resolve();
    }

    imageMasks.current = makeImageMasks(tissueDataCandidate, slideData, taskClasses);
    resultPrepared.current = true;
    return Promise.all(
      imageMasks.current.map((imageMaskItem: OffscreenImageMask, idx: number) =>
        imageMaskItem.prepare(
          slideData.currentMasks[idx].maskUrl,
          slideData.currentMasks[idx].minX,
          slideData.currentMasks[idx].minY,
          slideData.currentMasks[idx].downsampling,
        ),
      ),
    );
  }, [slideData, taskClasses, canPrepareVisualization]);

  const drawWithCanvas = useCallback(
    (ctx: CanvasRenderingContext2D, viewer: OpenSeadragon.Viewer, enabledClasses: string[]) => {
      if (!imageMasks) return;
      if (imageMasks.current.length > 0) {
        imageMasks.current.forEach((imageMaskItem) =>
          imageMaskItem.draw(ctx, zoom > 1 ? 0.4 : 0.6),
        );
      }
      if (zoom >= 10) {
        if (areaListArray.current.length > 0) {
          areaListArray.current
            .filter((list) => enabledClasses.includes(list.classId))
            .map((areaList) => areaList.draw(ctx));
        }
        if (contourListArray.current.length > 0) {
          contourListArray.current
            .filter((list) => enabledClasses.includes(list.classId))
            .map((contourList) => contourList.draw(ctx));
        }
      }
    },
    [imageMasks, zoom],
  );

  const drawWithWebGL = useCallback(
    (
      ctx: CanvasRenderingContext2D,
      glCtx: WebGL2RenderingContext,
      viewer: OpenSeadragon.Viewer,
      origin: Origin,
      enabledClasses: string[],
    ) => {
      if (zoom >= 10) {
        if (cellListArray.current.length > 0) {
          const activeCells = cellListArray.current.filter((list) =>
            enabledClasses.includes(list.classId),
          );
          activeCells.forEach((cellType) => {
            handleCellDrawing(glCtx, ctx, viewer, cellType, zoom, origin);
          });
        }
      }
    },
    [zoom, handleCellDrawing],
  );

  const rerender = useCallback((enabledClasses: string[], canvasOverlayRef: any) => {
    if (imageMasks.current.length === 0 || !canvasOverlayRef) {
      return;
    }
    Promise.all(
      imageMasks.current.map((imageMaskItem) => imageMaskItem.mergeSelectedClasses(enabledClasses)),
    ).then(
      () =>
        canvasOverlayRef.current &&
        canvasOverlayRef.current.overlay &&
        canvasOverlayRef.current.overlay.forceRedraw(),
    );
  }, []);

  const destroy = useCallback(() => {
    if (imageMasks.current.length > 0) {
      imageMasks.current.map((mask) => mask.destroy());
      imageMasks.current = [];
    }
    if (areaListArray.current.length > 0) {
      areaListArray.current.map((item) => item.destroy());
      areaListArray.current = [];
    }
    if (cellListArray.current.length > 0) {
      cellListArray.current.map((item) => item.destroy());
      cellListArray.current = [];
    }
    if (contourListArray.current.length > 0) {
      contourListArray.current.map((item) => item.destroy());
      contourListArray.current = [];
    }
  }, []);

  return {
    prepare,
    drawWithCanvas,
    drawWithWebGL,
    rerender,
    destroy,
    canPrepareVisualization,
  };
}

export default useVisualization;
