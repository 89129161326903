const baseSmallIconStyle = {
  height: '28px',
  width: '28px',
  minWidth: '28px',
  padding: '4px',
};

const baseLargeIconStyle = {
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '22px',
  // textTransform: 'capitalize', // Causes type error
  height: '44px',
  width: '44px',
  padding: '12px',
};

const baseMediumIconStyle = {
  height: '36px',
  width: '36px',
  minWidth: '36px',
  padding: '8px',
};

export { baseMediumIconStyle, baseSmallIconStyle, baseLargeIconStyle };
