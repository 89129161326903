const ACCESS_TOKEN_KEY = 'LUNIT_SCOPE_PDL1_USER_DASHBOARD_TOKEN_KEY';
const REFRESH_TOKEN_KEY = 'LUNIT_SCOPE_PDL1_USER_DASHBOARD_REFRESH_TOKEN_KEY';

let accessToken;
let refreshToken;

const accessTokenManager = {
  hasAccessTokenInStorage() {
    return !!this.getAccessTokenFromStorage();
  },
  isAccessTokenSane() {
    const localAccessToken = this.getAccessTokenFromStorage();
    if (!localAccessToken) return false;
    const pieces = localAccessToken.split('.');
    if (pieces.length !== 3) return false;

    try {
      const header = JSON.parse(atob(pieces[0]));
      const payload = JSON.parse(atob(pieces[1]));
      if (!header || !payload) {
        return false;
      }
    } catch (e) {
      return false;
    }
    return true;
  },
  getAccessTokenFromStorage() {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
  },
  hasRefreshTokenInStorage() {
    return !!this.getRefreshTokenFromStorage();
  },
  getRefreshTokenFromStorage() {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  },
  // @todo return값 memoization
  getAccessTokenPayloadFromStorage() {
    const localAccessToken = this.getAccessTokenFromStorage();
    return localAccessToken && JSON.parse(atob(localAccessToken.split('.')[1]));
  },
  getMFARequiredFromToken() {
    return this.getAccessTokenPayloadFromStorage().mfa_required;
  },
  getMFACertifiedFromToken() {
    return this.getAccessTokenPayloadFromStorage().mfa_certified;
  },
  getMFAVerifiedFromToken() {
    return this.getAccessTokenPayloadFromStorage().mfa_verified;
  },
  getCustomerIdFromToken() {
    return this.getAccessTokenPayloadFromStorage().ctm_id;
  },
  getCustomerEmailFromToken() {
    return this.getAccessTokenPayloadFromStorage().email;
  },
  getRolesFromToken(): string[] {
    return this.getAccessTokenPayloadFromStorage().roles;
  },
  setAccessToken(token?: string) {
    if (token) {
      localStorage.setItem(ACCESS_TOKEN_KEY, token);
    }
    accessToken = this.getAccessTokenFromStorage();
    return accessToken;
  },
  hasAccessToken() {
    return !!accessToken;
  },
  getAccessToken() {
    return accessToken;
  },
  removeAccessToken() {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    accessToken = null;
  },
  setRefreshToken(token?: string) {
    if (token) {
      localStorage.setItem(REFRESH_TOKEN_KEY, token);
    }
    refreshToken = this.getRefreshTokenFromStorage();
    return refreshToken;
  },
  hasRefreshToken() {
    return !!refreshToken;
  },
  getRefreshToken() {
    return refreshToken;
  },
  removeRefreshToken() {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    refreshToken = null;
  },
};

accessTokenManager.setAccessToken();
accessTokenManager.setRefreshToken();

export default accessTokenManager;
