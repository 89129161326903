import type {
  TooltipPlacement,
  TooltipPlacementToSize,
  TooltipSize,
  ArrowSizeBySize,
  TooltipSizes,
} from './Tooltip.types';

// small(10px * 6px) -> (10/2)^2 + 6^2 = 7.8^2
// large(16px * 8px)   -> (16/2)^2 + 8^2 = 11.3^2
const setArrowSizeBySize: ArrowSizeBySize = {
  small: { width: 11, height: 7.8 },
  large: { width: 16, height: 11.3 },
};

const tooltipPlacementToSize: TooltipPlacementToSize = {
  bottom: setArrowSizeBySize,
  left: setArrowSizeBySize,
  right: setArrowSizeBySize,
  top: setArrowSizeBySize,
};

export const resizeArrowBySize = (
  tooltipType: TooltipSizes,
  tooltipPlacement: TooltipPlacement,
): TooltipSize => {
  const size = tooltipPlacementToSize[tooltipPlacement][tooltipType];
  if (tooltipPlacement === 'left' || tooltipPlacement === 'right') {
    return { width: size.height, height: size.width };
  }
  return size;
};

export const setMarginByPlacement = (tooltipPlacement: TooltipPlacement, margin: TooltipSize) =>
  ({
    bottom: `-${margin.height}px 0 0 0`,
    right: `0 0 0 -${margin.width}px`,
    left: `0 -${margin.width}px 0 0`,
    top: `0 0 -${margin.height}px 0`,
  }[tooltipPlacement]);

export const setPaddingBySize = (tooltipSize: TooltipSizes) =>
  ({
    small: '6px 8px',
    large: '12px 16px',
  }[tooltipSize]);
