import { atom } from 'recoil';
import { CommentApi, SlidesApi } from '../api/slide/models';

const isCommentLoadingAtom = atom<boolean>({
  key: 'isCommentLoading',
  default: false,
});

const slidesAtom = atom<SlidesApi>({
  key: 'slides',
  default: {
    labels: [],
    results: [],
  },
});

const commentAtom = atom<CommentApi>({
  key: 'comment',
  default: {
    comment: '',
  },
});

const slideInfoAtom = atom<string>({
  key: 'slideUrl',
  default: '',
});

// eslint-disable-next-line import/prefer-default-export
export { slidesAtom, commentAtom, slideInfoAtom, isCommentLoadingAtom };
