import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { LoadingIndicatorDeprecated } from '@lunit/oncology-components';
import { SlidesApi } from 'src/api/slide/models';
import { useLocation } from 'react-router-dom';
import { getSlideIndexFromList } from 'src/utils/projects';
import { isVisCompleteAtom } from 'src/state';
import useSnackbar from 'src/hooks/useSnackbar';
import useResultData from 'src/hooks/visualizer/useResultData';
import SlideViewer from './component/SlideViewer';
import SlideViewerContainer from './component/SlideViewer/SlideViewerContainer';

import ViewOptions from './component/ViewOptions';

const dummyToolState = {
  activeToolType: 0,
  activeToolId: 'hand',
  tempHandEnabled: false,
  toolsEnabled: false,
};

function Visualizer({ results: slides, labels }: SlidesApi) {
  const [enabledClasses, setEnabledClasses] = useState<string[]>([]);
  const [currentSlide, setCurrentSlide] = useState<any>();
  const [histologyActive, setHistologyActive] = useState<boolean>(true);
  const [isVisComplete, setIsVisComplete] = useRecoilState<boolean>(isVisCompleteAtom);

  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const { isLoading, isError, slideData, taskClasses } = useResultData(
    currentSlide?.resultUrl,
    new URLSearchParams(location.search),
    labels,
  );

  // Handle navigating to selected slide
  useEffect(() => {
    if (slides.length !== 0) {
      const searchParams = new URLSearchParams(location.search);
      const slide = searchParams.get('slide');
      if (slide && slide !== 'undefined') {
        const index = getSlideIndexFromList(slide, slides) - 1;
        if (index > -1) {
          setCurrentSlide(slides[index]);
        } else {
          // eslint-disable-next-line no-alert
          alert('Invalid slide ID');
          const group = searchParams.get('group');
          const customerCode = searchParams.get('customer_code');
          const previousSlideId = currentSlide ? currentSlide.resultUrl : slides[0]?.resultUrl;
          // force viewer to reset by deleting currentSlide
          setCurrentSlide(null);
          let link = `/visualizer?group=${group}&slide=${previousSlideId}`;
          if (customerCode) {
            link = `/visualizer?group=${group}&customer_code=${customerCode}&slide=${previousSlideId}`;
          }
          window.location.replace(link);
        }
      } else {
        const group = searchParams.get('group');
        const customerCode = searchParams.get('customer_code');
        let link = `/visualizer?group=${group}&slide=${encodeURIComponent(slides[0]?.resultUrl)}`;
        if (customerCode) {
          link = `/visualizer?group=${group}&customer_code=${customerCode}&slide=${encodeURIComponent(
            slides[0]?.resultUrl,
          )}`;
        }
        window.location.replace(link);
      }
    }
    return () => {
      setIsVisComplete(false);
      setEnabledClasses([]);
    };
  }, [slides, currentSlide, location.search, setIsVisComplete]);

  // Handle error
  useEffect(() => {
    if (isError) {
      enqueueSnackbar(
        'Analysis result not found',
        {
          variant: 'error',
          preventDuplicate: true,
        },
        { isLight: true },
      );
    }
  }, [isError, enqueueSnackbar]);

  return (
    <>
      <SlideViewerContainer
        toolState={dummyToolState}
        slideViewport={
          !isLoading &&
          currentSlide &&
          slideData?.currentSlide &&
          currentSlide?.slideUrl && (
            <SlideViewer
              slideData={slideData}
              taskClasses={taskClasses}
              currentSlide={currentSlide}
              isVisComplete={isVisComplete}
              enabledClasses={enabledClasses}
              histologyActive={histologyActive}
              handleVisComplete={(state: boolean) => setIsVisComplete(state)}
            />
          )
        }
        sidePanel={
          currentSlide && (
            <ViewOptions
              isError={isError}
              active={histologyActive}
              data={slideData?.analysisData}
              enabledClasses={enabledClasses}
              cellClasses={slideData?.cellClasses}
              taskClasses={taskClasses}
              tissueClasses={slideData?.tissueClasses}
              structureClasses={slideData?.structureClasses}
              handleActive={(active: boolean) => setHistologyActive(active)}
              handleClass={(values: string[]) => setEnabledClasses(values)}
            />
          )
        }
      />
      {!isError && (isLoading || (currentSlide && !slideData?.currentSlide) || !isVisComplete) && (
        <LoadingIndicatorDeprecated
          message={isLoading || !slideData?.currentSlide ? 'Fetching Data..' : ''}
        />
      )}
    </>
  );
}

export default Visualizer;
