import { styled } from '@mui/material';
import {
  StyledListItem,
  StyledListItemButton,
  StyledListItemStartSlot,
  StyledListItemText,
} from '../ListItem/ListItem.styled';

export { EmptyIcon } from '../ListItem/ListItem.styled';

export const StyledNavigationGroup = styled(StyledListItem)({
  width: '100%',
});

export const StyledNavigationGroupButton = styled(StyledListItemButton)(({ theme }) => ({
  paddingTop: '8px',
  paddingBottom: '8px',
  '&.Mui-selected': {
    color: theme.palette.lunitTeal[40],
  },
}));

export const StyledNavigationGroupArrowButton = styled(StyledNavigationGroupButton)({
  flex: '0 0 auto',
  width: '36px',
});

export const StyledNavigationGroupText = styled(StyledListItemText)({
  wordBreak: 'break-all',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
  overflow: 'hidden',
});

export const StyledNavigationGroupSlot = StyledListItemStartSlot;
