import { ClickAwayListener, Menu } from '@mui/material';
import { MenuProps } from '@mui/material/Menu';

export interface ContextMenuProps extends MenuProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  children: React.ReactNode;
  onClose?: () => void;
}

function ContextMenu({
  anchorEl,
  open,
  onClose,
  children,
}: ContextMenuProps): ReturnType<typeof Menu> {
  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      onClickAway={onClose}
    >
      <Menu
        id="long-menu"
        MenuListProps={{ 'aria-labelledby': 'long-button' }}
        BackdropProps={{ open: false }}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ margin: (theme) => theme.spacing(0.5, 0) }}
      >
        {children}
      </Menu>
    </ClickAwayListener>
  );
}

export default ContextMenu;
