import * as React from 'react';
import type { SVGProps } from 'react';
const SvgIcCloseLargeoutlined20Px = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M4.854 15.854a.5.5 0 0 1-.708-.708L9.293 10 4.146 4.854a.5.5 0 1 1 .708-.708L10 9.293l5.146-5.147a.5.5 0 0 1 .708.708L10.707 10l5.147 5.146a.5.5 0 0 1-.708.708L10 10.707l-5.146 5.147Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIcCloseLargeoutlined20Px;
