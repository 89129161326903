import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import type { DialogProps } from './Dialog.types';

interface StyledDialogProps extends DialogProps {
  displayChildren: boolean;
  isPrompt: boolean;
  sx?: any;
}

interface StyledDialogTitleProps {
  displayChildren: boolean;
  isPrompt: boolean;
}

const StyledDialog = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== 'displayChildren' && prop !== 'isPrompt',
})<StyledDialogProps>(({ theme, displayChildren, isPrompt, sx }) => {
  return {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.neutralGrey[90],
      width: displayChildren && !isPrompt ? 'auto' : '320px',
      maxWidth: displayChildren && !isPrompt ? 'none' : '320px',
      borderRadius: '8px',
      padding: '28px 20px 24px 20px',
    },
    ...sx,
  };
});

const StyledHeaderBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'displayChildren' && prop !== 'isPrompt',
})<StyledDialogTitleProps>(({ displayChildren, isPrompt }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '24px',
  marginBottom: displayChildren && !isPrompt ? '32px' : '16px',
}));

const StyledTitle = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  ...theme.typography.body_sb1,
  padding: 0,
  display: 'flex',
  '& .dialogIcon': {
    display: 'flex',
    marginRight: '8px',
  },
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  ...theme.typography.body5,
  marginBottom: '16px',
  padding: 0,
  whiteSpace: 'pre-line',

  '&.childrenDialog': {
    display: 'flex',
    borderTop: 0,
    borderBottom: 0,
    maxHeight: 'fit-content',
  },
}));

const StyledDialogActions = styled(DialogActions)(() => ({
  padding: 0,
  margin: '0 12px',
}));

export {
  StyledDialog,
  StyledHeaderBox,
  StyledTitle,
  StyledDialogTitle,
  StyledDialogContent,
  StyledDialogActions,
};
