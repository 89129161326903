import { createAPIQuery } from '@lunit/oncology-components/api/utils/apiQueryHelper';
import qs from 'qs';
import { FetchMethod, request } from '../utils/apiRequestHelper';
import {
  LoginParams,
  ForgotPasswordParams,
  ResetPasswordParams,
  APIVerifyValidKeyParams,
  SignUpInfo,
  MyInfo,
  UpdatePasswordInfo,
} from './models';

const qsStringfyDefaultOptions = { addQueryPrefix: true };

export const login = (payload: LoginParams) =>
  request({
    method: FetchMethod.Post,
    path: 'research/login',
    payload,
    skipAuth: true,
  });

export const forgotPassword = (payload: ForgotPasswordParams) =>
  request({
    method: FetchMethod.Post,
    path: `research/reset-password${qs.stringify(payload, qsStringfyDefaultOptions)}`,
    payload,
    skipAuth: true,
    skipJson: true,
  });

export const resetPassword = (payload: ResetPasswordParams) =>
  request({
    method: FetchMethod.Patch,
    path: `accounts/reset-password?key=${payload.key}`,
    payload: { newPassword: payload.newPassword },
    skipAuth: true,
    skipJson: true,
  });

export const getSignupInfo = createAPIQuery(
  () =>
    request<SignUpInfo>({
      method: FetchMethod.Get,
      path: 'users/signup-info',
      skipAuth: true,
    }),
  () => ['auth', 'getSignupInfo'],
);

export const verifyEmail = ({ email }: { email: string }) =>
  request({
    method: FetchMethod.Post,
    path: `users/signup/confirmation?email=${encodeURIComponent(email)}`,
    skipAuth: true,
    skipJson: true,
  });

export const verifyValidKey = (payload: APIVerifyValidKeyParams) =>
  request({
    method: FetchMethod.Get,
    path: `accounts/key/verify?key=${encodeURIComponent(payload.key)}&keyType=${
      payload.keyType || 'PASSWORD'
    }`,
    skipJson: true,
    skipAuth: true,
  });

export const signUp = (payload: any) =>
  request({
    method: FetchMethod.Post,
    path: 'users',
    payload,
    skipJson: true,
    skipAuth: true,
  });

export const getCurrentUser = createAPIQuery(
  () =>
    request<MyInfo>({
      method: FetchMethod.Get,
      path: 'research/userinfo',
    }),
  () => ['auth', 'getCurrentUser'],
);

export const updateUser = createAPIQuery(
  (payload: MyInfo) =>
    request({
      method: FetchMethod.Put,
      path: 'users',
      payload,
      skipJson: true,
    }),
  (payload: MyInfo) => ['auth', updateUser, payload],
);

export const updatePassword = createAPIQuery(
  (payload: UpdatePasswordInfo) =>
    request<UpdatePasswordInfo>({
      method: FetchMethod.Patch,
      path: 'research/password',
      payload,
      skipJson: true,
    }),
  (payload: UpdatePasswordInfo) => ['auth', updatePassword, payload],
);

export const deleteUser = createAPIQuery(
  () =>
    request({
      method: FetchMethod.Delete,
      path: 'users',
      skipJson: true,
    }),
  () => ['auth', deleteUser],
);
