import type { ComponentsOverrides } from '@mui/material/styles';
import { createBreakpoints } from '@mui/system';

const breakpoints = createBreakpoints({});

const styleOverrides: ComponentsOverrides['MuiToolbar'] = {
  regular: {
    minHeight: 48,
    [breakpoints.up('sm')]: {
      minHeight: 48,
    },
  },
};
export default {
  styleOverrides,
};
