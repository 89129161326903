import * as React from 'react';
import type { SVGProps } from 'react';
const SvgIcFilterNotioutlined20Px = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M10.1 5H6.937a2 2 0 0 0-3.874 0H2a.5.5 0 0 0 0 1h1.063a2 2 0 0 0 3.874 0H10c0-.342.034-.677.1-1Zm2.55 4.238a2 2 0 0 1 3.787.262h.501a3.973 3.973 0 0 1-1.438.47 1 1 0 0 0-1.962-.245 3.993 3.993 0 0 1-.888-.487Zm-1.22.262H2a.5.5 0 0 0 0 1h10.563a2 2 0 0 0 3.76.323 5.005 5.005 0 0 1-1.669.165 1.002 1.002 0 0 1-.544-.067A4.989 4.989 0 0 1 11.43 9.5Zm5.752 1H18a.5.5 0 0 0 .384-.82 5.01 5.01 0 0 1-1.202.82ZM11 6c0-.345.044-.68.126-1H18a.5.5 0 0 1 0 1h-7Zm-7-.5a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm6.437 9.5a2 2 0 0 1-3.874 0H2a.5.5 0 0 1 0-1h4.563a2 2 0 0 1 3.874 0H18a.5.5 0 0 1 0 1h-7.563ZM7.5 14.5a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"
      clipRule="evenodd"
    />
    <path
      fill="#E63B28"
      d="M19 6a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
    />
  </svg>
);
export default SvgIcFilterNotioutlined20Px;
