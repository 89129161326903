import type { ComponentsOverrides } from '@mui/material/styles';
import palette from '../colors';
import typographyOptions from '../system/typography';

const styleOverrides: ComponentsOverrides['MuiTableCell'] = {
  root: {
    ...typographyOptions.body4,
    borderBottom: '1px solid rgba(134, 148, 177, 0.16)',
    whiteSpace: 'nowrap',
    padding: 8,
    '&:first-of-type': {
      paddingLeft: 16,
    },
    '&:last-child': {
      paddingRight: 16,
    },
  },
  head: {
    color: palette.text.secondary,
  },
  paddingCheckbox: {
    padding: '0 3px 0 3px',
    '&:first-of-type': {
      paddingLeft: 3,
    },
    '&:last-child': {
      paddingLeft: 3,
      paddingRight: 3,
    },
  },
};
export default {
  styleOverrides,
};
