const theme = {
  primary: '#728DF9',
  bg: '#242E3E',
  darkBg: '#12171F',
  text: '#d7dce1',
  primaryText: '#e2eaff',
  lightText: '#fafafb',
  darkText: '#98a0a7',
  disabled: '#868e96',
  slideBg: '#ADB5BC',
  grade1: '#ffd43b',
  grade2: '#ff922b',
  grade3: '#f03e3e',
};
export default theme;
