import type { ComponentsOverrides } from '@mui/material/styles';
import palette from '../colors';

const styleOverrides: ComponentsOverrides['MuiCircularProgress'] = {
  root: {
    color: palette.scope1,
  },
};
export default {
  styleOverrides,
};
