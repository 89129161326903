import OpenSeadragon from 'openseadragon';
import {
  MouseTrackerEvent,
  OSDViewerRef,
  ViewportProps,
} from '@lunit/osd-react-renderer/dist/types/types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import useViewerZoom from 'src/hooks/useViewerZoom';
import { slideOpenAtom } from 'src/state';

const DEFAULT_MAX_ZOOM = 160;

interface UseWheelButtonPanningProps {
  throttle?: number;
  viewer: OpenSeadragon.Viewer | undefined;
}

const WHEEL_BUTTON = 1;

export default function useWheelButtonPanning({ throttle, viewer }: UseWheelButtonPanningProps) {
  const lastPoint = useRef<OpenSeadragon.Point | null>(null);
  const isPanning = !lastPoint;

  const prevDelta = useRef<OpenSeadragon.Point | null>(null);
  const prevTime = useRef<number>(-1);

  const cancelPanning = useCallback(() => {
    lastPoint.current = null;
    prevDelta.current = null;
    prevTime.current = -1;
  }, []);

  const onNonPrimaryPress = useCallback((e: MouseTrackerEvent) => {
    if (e.button === WHEEL_BUTTON) {
      lastPoint.current = e.position?.clone() || null;
      prevDelta.current = new OpenSeadragon.Point(0, 0);
      prevTime.current = 0;
    }
  }, []);

  const onNonPrimaryRelease = useCallback(
    (e: MouseTrackerEvent) => {
      if (e.button === WHEEL_BUTTON) {
        cancelPanning();
      }
    },
    [cancelPanning],
  );

  const onMove = useCallback(
    (e: MouseTrackerEvent) => {
      if (viewer && viewer.viewport) {
        if (lastPoint.current && e.position) {
          const deltaPixels = lastPoint.current.minus(e.position);
          const deltaPoints = viewer.viewport.deltaPointsFromPixels(deltaPixels);
          lastPoint.current = e.position.clone();
          if (!throttle || throttle < 0) {
            viewer.viewport.panBy(deltaPoints);
          } else if (prevDelta.current) {
            const newTimeDelta = Date.now() - prevTime.current;
            const newDelta = prevDelta.current.plus(deltaPoints);
            if (newTimeDelta > throttle) {
              viewer.viewport.panBy(newDelta);
              prevDelta.current = new OpenSeadragon.Point(0, 0);
              prevTime.current = 0;
            } else {
              prevDelta.current = newDelta;
              prevTime.current = newTimeDelta;
            }
          }
        }
      }
    },
    [viewer, throttle],
  );

  return {
    isPanning,
    cancelPanning,
    onNonPrimaryPress,
    onNonPrimaryRelease,
    onMove,
  };
}

export function useViewportHandlers(mpp: number, defaultZoom: number) {
  const viewerRef = useRef<OSDViewerRef>(null);
  const { setZoomLevel, setPhysicalWidthPx, physicalWidthPx, zoomState } = useViewerZoom();
  const { zoom, refPoint } = zoomState;
  const setSlideOpen = useSetRecoilState(slideOpenAtom);
  const [imageWidth, setImageWidth] = useState(0);
  const { onNonPrimaryPress, onMove, onNonPrimaryRelease, cancelPanning } = useWheelButtonPanning({
    throttle: 150,
    viewer: viewerRef.current?.viewer,
  });

  const onLeave = useCallback(() => {
    // temporary fix about malfunction(?) of mouseup and onNonPrimaryRelease event
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    cancelPanning && cancelPanning();
  }, [cancelPanning]);

  // Function for syncronising OSDViewer's zoom with the app's zoom state
  const onZoom = useCallback(
    (event: any) => {
      const {
        eventSource: viewer,
        zoom: viewportZoom,
        // eslint-disable-next-line @typescript-eslint/no-shadow
        refPoint,
        immediately,
      } = event;
      if (viewer == null || viewportZoom == null || immediately) {
        return;
      }
      const microscopeWidth1x = physicalWidthPx * 10;
      const viewportWidth = viewer.viewport.getContainerSize().x;
      const scaleFactor = microscopeWidth1x / viewportWidth;
      viewer.viewport.maxZoomLevel = DEFAULT_MAX_ZOOM * scaleFactor;
      viewer.viewport.minZoomLevel = 0.1 * scaleFactor;
      const targetZoom = viewportZoom / scaleFactor;
      setZoomLevel(targetZoom, refPoint);
    },
    [setZoomLevel, physicalWidthPx],
  );

  const getViewportZoomFromZoom = (
    // eslint-disable-next-line @typescript-eslint/no-shadow
    zoom: number,
    microscopeWidth1x: number,
    viewer?: OpenSeadragon.Viewer,
  ) => {
    if (viewer) {
      const viewportWidth = viewer.viewport.getContainerSize().x;
      const scaleFactor = microscopeWidth1x / viewportWidth;
      return zoom * scaleFactor;
    }
    return zoom;
  };

  const onSlideOpen = useCallback(() => {
    // onViewerInit(() => viewer.forceRedraw());
    // viewerRef.current = viewer;
    setZoomLevel(defaultZoom);
    setSlideOpen(true);
  }, [setZoomLevel, setSlideOpen, defaultZoom]);

  const onSlideClose = () => {
    setSlideOpen(false);
  };

  const onHome = (event: any) => {
    const viewer = event.eventSource;
    const imageSize = viewer.world.getItemAt(0).getContentSize();
    setImageWidth(imageSize.x);
  };

  const onCanvasDoubleClick: ViewportProps['onCanvasDoubleClick'] = useCallback(
    (event: OpenSeadragon.ViewerEvent) => {
      // eslint-disable-next-line no-param-reassign
      event.preventDefaultAction = true;
      if (zoom < 40 && event.position && event.eventSource) {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const refPoint = event.eventSource.viewport.pointFromPixel(event.position);
        setZoomLevel(40, refPoint);
      }
    },
    [setZoomLevel, zoom],
  );

  const onCanvasKey = useCallback((event: any) => {
    // Prevent setting zoom top default on 0 key
    if (event.originalEvent.keyCode === 48) {
      // eslint-disable-next-line no-param-reassign
      event.preventDefaultAction = true;
    }
  }, []);

  useEffect(() => {
    const physWidthPx = ((imageWidth * mpp) / 25400) * 96;
    setPhysicalWidthPx(physWidthPx);
  }, [imageWidth, mpp, setPhysicalWidthPx]);

  return {
    onZoom,
    onHome,
    onSlideOpen,
    onSlideClose,
    onCanvasDoubleClick,
    onCanvasKey,
    getViewportZoomFromZoom,
    currentZoomLevel: zoom,
    zoomOSDRef: refPoint,
    physicalWidthPx,
    viewerRef,
    mouseTracker: {
      onLeave,
      onNonPrimaryPress,
      onNonPrimaryRelease,
      onMove,
    },
  };
}
