import { Box, styled, Switch } from '@mui/material';
import { ToggleProps } from './Toggle.types';

export const Container = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  gap: theme.spacing(2),
  pointerEvents: 'none',
  '&.is-disabled': {
    opacity: 0.4,
  },
  '&:not(:last-child)': {
    marginBottom: '6px',
  },
}));

export const StyledToggle = styled(Switch, {
  shouldForwardProp: (prop) => prop !== 'toggleColor',
})<ToggleProps>(({ theme, toggleColor }) => ({
  width: '32px',
  height: '22px',
  borderRadius: '9px',
  padding: '2px',
  backgroundColor: 'transparent',
  pointerEvents: 'auto',
  '& .MuiSwitch-switchBase': {
    transition: 'all 100ms ease-in-out',
    padding: '4px',
  },
  '& .MuiSwitch-thumb': {
    width: '14px',
    height: '14px',
    background: theme.palette.neutralGrey[0],
    boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.4)',
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    borderRadius: '12px',
    backgroundColor: theme.palette.neutralGrey[45],
  },
  '& .MuiSwitch-switchBase .MuiSwitch-input': {
    width: '200%',
    height: '100%',
    top: 0,
    left: '-50%',
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    transform: 'translateX(10px)',
    color: theme.palette.neutralGrey[0],
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: toggleColor || theme.palette.lunitTeal[40],
  },
  '& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track': {
    opacity: 1,
  },
  '& .MuiSwitch-switchBase.Mui-focusVisible': {
    boxShadow: 'none',
    background: 'transparent',
    borderColor: 'transparent',
    outline: 'none',
  },
  '& .MuiSwitch-switchBase.Mui-focusVisible + .MuiSwitch-track::after': {
    borderRadius: '12px',
    content: '""',
    position: 'absolute',
    border: `1px solid ${theme.palette.lunitTeal[10]}`,
    boxSizing: 'border-box',
    top: '1px',
    left: '1px',
    bottom: '1px',
    right: '1px',
  },
}));

export const StyledIndeterminateToggle = styled(StyledToggle)({
  padding: '2px',
  cursor: 'pointer',
  '& .MuiSwitch-thumb': {
    height: '2px',
    width: '12px',
    borderRadius: '2px',
    boxShadow: 'none',
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    padding: '10px 5px',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    borderRadius: '12px',
  },
});
