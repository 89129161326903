import { BaseSyntheticEvent, useEffect, useRef, useState } from 'react';
import { Button, TextArea } from '@lunit/oncology-components';
import { ArrowDownSm, ArrowUpSm } from '@lunit/oncology-icons';
import { Box, Typography, Collapse } from '@mui/material';
import { saveComment } from 'src/api/slide/queries';
import { useMutation } from 'react-query';
import useSnackbar from 'src/hooks/useSnackbar';
import { useRecoilState, useRecoilValue } from 'recoil';
import { commentAtom, slideInfoAtom } from 'src/state/slides';

const Comment = ({ isLoading }: { isLoading: boolean }) => {
  const [isOpened, setIsOpened] = useState<boolean>(true);
  const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(true);
  const [commentData, setCommentData] = useRecoilState(commentAtom);
  const slideUrl = useRecoilValue(slideInfoAtom);
  const prevCommentRef = useRef(commentData.comment);
  const [initialComment, setInitialComment] = useState<string>(commentData.comment);
  const [comment, setComment] = useState<string>(commentData.comment);
  const { enqueueSnackbar } = useSnackbar();
  const params = new URLSearchParams(window.location.search);
  const onClickArrow = () => {
    setIsOpened(!isOpened);
  };

  const onChangeComment = (event: BaseSyntheticEvent) => {
    setComment(event.target.value);
    setCommentData({ comment: event.target.value });
    setIsBtnDisabled(event.target.value === initialComment);
  };

  const saveCommentData = useMutation(saveComment, {
    onSuccess: () => {
      setInitialComment(comment);
      setIsBtnDisabled(true);
      enqueueSnackbar('Successfully saved comment.', { variant: 'success' }, { isLight: true });
    },
    onError: () => {
      enqueueSnackbar(
        'Something went wrong. Please try again.',
        { variant: 'error' },
        { isLight: true },
      );
    },
  });

  const onClickSaveButton = () => {
    saveCommentData.mutate({
      comment,
      group: params.get('group') || '',
      slide: slideUrl,
      customer_code: params.get('customer_code') || '',
    });
  };

  useEffect(() => {
    if (prevCommentRef.current !== commentData.comment) {
      setComment(commentData.comment);
      setInitialComment(commentData.comment);
      prevCommentRef.current = commentData.comment;
    }
  }, [commentData, isBtnDisabled]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          height: '36px',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="body_b1">Comment</Typography>
        <Box
          sx={{ cursor: 'pointer' }}
          onClick={onClickArrow}
        >
          {isOpened ? <ArrowUpSm /> : <ArrowDownSm />}
        </Box>
      </Box>
      <Collapse
        in={isOpened}
        timeout="auto"
        unmountOnExit
      >
        <TextArea
          height="240px"
          placeholder="Please leave a comment for the analysis."
          readOnly={isLoading}
          value={comment}
          onChange={onChangeComment}
        />
        <Button
          color="primary"
          disableRipple
          disabled={isBtnDisabled}
          label="Save"
          size="medium"
          variant="contained"
          sx={{
            width: '100%',
            marginTop: '8px',
          }}
          onClick={onClickSaveButton}
        />
      </Collapse>
    </>
  );
};

export default Comment;
