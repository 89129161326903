import { useEffect, useMemo, useRef } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { findIndex as _findIndex } from 'lodash';
import { ListItemText, Typography } from '@mui/material';
import { SlideDetails } from 'src/api/slide/models';
import { getSlideIndexFromList } from 'src/utils/projects';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { slideInfoAtom } from 'src/state/slides';
import { isCommentEnabledAtom, projectsAtom } from 'src/state/projects';
import SlideLink from './SlideLink';
import { Footer } from './styled';

interface SlideListProps {
  slides: SlideDetails[];
}

function SlideList({ slides }: SlideListProps) {
  const listRef = useRef<any>(null);
  const setSlideInfo = useSetRecoilState(slideInfoAtom);
  const setIsCommentEnabled = useSetRecoilState(isCommentEnabledAtom);
  const projectData = useRecoilValue(projectsAtom);

  const params = useMemo(() => new URLSearchParams(window.location.search), []);
  const targetProject = projectData.projects.find(
    (project) => project.title === params.get('group'),
  );

  setIsCommentEnabled(targetProject?.isCommentEnabled!);

  useEffect(() => {
    const slide = params.get('slide');
    if (slides && slide) {
      const index = _findIndex(slides, (sld) => sld.resultUrl === slide);
      setSlideInfo(slides[index]?.description);
      const slideId = getSlideIndexFromList(slide, slides);
      if (
        slideId &&
        !Number.isNaN(slideId) &&
        slideId > 0 &&
        slideId <= slides.length &&
        listRef.current
      ) {
        listRef.current.scrollToItem(slideId, 'center');
      }
    }
  }, [params, listRef, slides, setSlideInfo]);

  return (
    <>
      <ListItemText
        sx={{
          flex: 0,
          ml: (theme) => theme.spacing(2),
          mt: (theme) => theme.spacing(2.25),
          mb: (theme) => theme.spacing(1),
        }}
      >
        <Typography
          variant="body3"
          color="darkGrey.0"
        >
          Case List
        </Typography>
      </ListItemText>
      <AutoSizer>
        {({ height }) => (
          <FixedSizeList
            className="slideList"
            ref={listRef}
            height={height - 130} // 130: toolbar + 'case list' + footer
            width={240}
            itemCount={slides.length}
            itemData={slides}
            itemSize={118}
            overscanCount={5}
          >
            {SlideLink}
          </FixedSizeList>
        )}
      </AutoSizer>

      <Footer>
        <span>Copyright Lunit © 2022</span>
      </Footer>
    </>
  );
}

export default SlideList;
