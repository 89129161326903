import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { styled } from '@mui/material/styles';

const StyledDatePicker = styled(DesktopDatePicker)(({ theme }) => ({
  '.MuiInputBase-root': {
    height: '36px',
    backgroundColor: theme.palette.neutralGrey[75],

    '&:hover fieldset': {
      borderColor: theme.palette.neutralGrey[45],
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.lunitTeal[10],
      borderWidth: '1px',
    },
  },
}));

export { StyledDatePicker };
