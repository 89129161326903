import React from 'react';
import OSDViewer from '@lunit/osd-react-renderer';

import useSnackbar from 'src/hooks/useSnackbar';
import { OSDSlideContainer } from './SlideViewerMUI';

import { useViewportHandlers } from './slideViewerHooks';

import { OSD_OPTIONS } from './osdConfig';

import { SlideUrlData } from './types';

interface ReadOnlyViewerProps {
  slideData: SlideUrlData;
  mpp?: number;
  canvasOverlay?: React.ReactNode;
  tooltipOverlay?: React.ReactNode;
  webGLOverlay?: React.ReactNode;
  scaleBar?: React.ReactNode;
  defaultZoom?: number;
}

function ReadOnlyViewer({
  slideData,
  defaultZoom = 1,
  mpp = 0.25,
  canvasOverlay,
  tooltipOverlay,
  webGLOverlay,
  scaleBar,
}: ReadOnlyViewerProps) {
  const {
    onZoom,
    onSlideOpen,
    onSlideClose,
    onHome,
    onCanvasDoubleClick,
    onCanvasKey,
    getViewportZoomFromZoom,
    mouseTracker,
    currentZoomLevel,
    zoomOSDRef,
    viewerRef,
    physicalWidthPx,
  } = useViewportHandlers(mpp, defaultZoom);
  const { enqueueSnackbar } = useSnackbar();

  const microscopeWidth1x = physicalWidthPx * 10;

  return (
    <OSDSlideContainer>
      {/* eslint-disable react/no-unknown-property */}
      <OSDViewer
        ref={viewerRef}
        options={OSD_OPTIONS}
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        {viewerRef?.current && (
          <>
            <viewport
              rotation={0}
              zoom={
                microscopeWidth1x
                  ? getViewportZoomFromZoom(
                      currentZoomLevel,
                      microscopeWidth1x,
                      viewerRef?.current?.viewer ?? undefined,
                    )
                  : 1
              }
              refPoint={zoomOSDRef || undefined}
              onZoom={onZoom}
              onCanvasKey={onCanvasKey}
              onCanvasDoubleClick={onCanvasDoubleClick}
              onOpen={onSlideOpen}
              onHome={onHome}
              onClose={onSlideClose}
              onOpenFailed={() => {
                enqueueSnackbar(
                  'Tile image source not found',
                  {
                    variant: 'error',
                    preventDuplicate: true,
                  },
                  { isLight: true },
                );
              }}
            />
            <tiledImage
              url={slideData.slideDziMetaUrl}
              tileUrlBase={slideData.slideDziMetaUrl}
            />
            <mouseTracker
              onLeave={mouseTracker.onLeave}
              onNonPrimaryPress={mouseTracker.onNonPrimaryPress}
              onNonPrimaryRelease={mouseTracker.onNonPrimaryRelease}
              onMove={mouseTracker.onMove}
            />
            {canvasOverlay}
            {webGLOverlay}
            {tooltipOverlay}
            {scaleBar}
          </>
        )}
      </OSDViewer>
      {/* eslint-enable react/no-unknown-property */}
    </OSDSlideContainer>
  );
}

export default ReadOnlyViewer;
