import { Project } from 'src/api/project/models';

// eslint-disable-next-line import/prefer-default-export
export const generateProjectLink = (project: Project, customerCode?: string) => {
  if (customerCode?.toLowerCase() === 'lunit') {
    return `/visualizer?group=${project.dirName}&customer_code=${project.customerCode}`;
  }
  return `/visualizer?group=${project.dirName}`;
};

export const getSlideIndexFromList = (resultUrl: string, list: any[]) => {
  if (list === undefined || list.length === 0) {
    return 1;
  }
  return list.findIndex((item) => item.resultUrl === resultUrl) + 1;
};
