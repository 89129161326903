import { LinearProgress as MuiLinearProgress, linearProgressClasses, styled } from '@mui/material';
import type { LinearProgressProps } from './LinearProgress.types';

const BaseLinearProgress = styled(MuiLinearProgress, {
  shouldForwardProp: (prop) => prop !== 'barColor',
})<LinearProgressProps>(({ theme, height, barColor }) => ({
  width: '100%',
  height,
  backgroundColor: 'transparent',
  [`& .${linearProgressClasses.barColorPrimary}`]: {
    backgroundColor: barColor || theme.palette.lunitTeal[40],
  },
}));

export default BaseLinearProgress;
