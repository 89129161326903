import { styled, List, ListSubheader } from '@mui/material';
import type { ListSubheaderProps } from './List.types';

export const MuiList = styled(List)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export const MuiListSubheader = styled(ListSubheader, {
  shouldForwardProp: (prop) => !['type', 'clickable'].includes(prop.toString()),
})<ListSubheaderProps>(({ type, clickable, theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '36px',
  padding: '0px 8px',
  backgroundColor: 'transparent',
  ...(type === 'bold'
    ? {
        ...theme.typography.body_b1,
        color: theme.palette.neutralGrey[0],
      }
    : {
        ...theme.typography.small_body_m1,
        color: theme.palette.neutralGrey[40],
      }),
  ...(clickable && { cursor: 'pointer' }),
  svg: {
    width: '20px',
    height: '20px',
  },
}));
