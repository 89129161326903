import { useState, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthTemplate from '@lunit/oncology-components/Auth/AuthTemplate';
import AuthHeading from '@lunit/oncology-components/Auth/AuthHeading';
import AuthTextField from '@lunit/oncology-components/Auth/AuthTextField';
import AuthActionButton from '@lunit/oncology-components/Auth/AuthActionButton';
import AuthNav from '@lunit/oncology-components/Auth/AuthNav';
import AuthFooter from '@lunit/oncology-components/Auth/AuthFooter';
import AuthAlert from '@lunit/oncology-components/Auth/AuthAlert';
import { styled, Box, Link } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useMutation } from 'react-query';
import { APIError } from '@lunit/oncology-components/api/models';
import { useSetRecoilState } from 'recoil';
import * as authAPI from '../../api/auth/queries';
import { UserToken } from '../../api/auth/models';
import { authAtom } from '../../state/auth';
import { setTokens, validateEmail } from './utils';

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
  />
))({ [`& .${tooltipClasses.tooltip}`]: { maxWidth: 442 } });

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const setAuth = useSetRecoilState(authAtom);

  const actionDisabled = !email.trim() || !password.trim();
  const hasError = !!errorMessage.trim();

  const login = useMutation(authAPI.login, {
    onSuccess: async (data: UserToken) => {
      setAuth(data);
      setTokens(data);
      navigate('/');
    },
    onError: (error: APIError) => {
      if (String(error.details).includes('activate')) {
        setErrorMessage(
          'Customer is not yet activated. Please contact your administrator or email cs-scope-io@lunit.io',
        );
      } else if (error.code === 4002) {
        setErrorMessage('The email or password you entered is incorrect.');
      } else {
        setErrorMessage('Something went wrong while logging in. Please try again.');
      }
    },
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateEmail(email)) {
      login.mutate({
        email,
        password,
      });
    } else setErrorMessage('The email you entered is invalid.');
  };

  return (
    <AuthTemplate
      logo="/scope-w.svg"
      logoLink="/login"
    >
      <form
        onSubmit={handleSubmit}
        noValidate
      >
        <AuthHeading>Sign in</AuthHeading>
        <AuthTextField
          id="email"
          type="email"
          aria-label="Email"
          placeholder="Email*"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoComplete="username"
        />
        <AuthTextField
          id="password"
          type="password"
          aria-label="Password"
          placeholder="Password*"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          autoComplete="current-password"
        />
        <AuthActionButton
          size="large"
          disabled={actionDisabled}
        >
          Log in
        </AuthActionButton>
        {hasError && (
          <AuthAlert
            sx={{ marginTop: 3 }}
            severity="error"
          >
            {errorMessage}
          </AuthAlert>
        )}
        <AuthNav>
          <Link href="forgot-password">Forgot your password?</Link>
          <Link
            href="mailto:cs-scope@lunit.io"
            target="_blank"
            sx={{ textAlign: 'right' }}
          >
            cs-scope@lunit.io
          </Link>
        </AuthNav>
        <AuthFooter
          sx={{ textAlign: 'start' }}
          description={
            <>
              <strong>SCOPE for Research</strong> runs on Google&apos;s Chrome browser with the
              following conditions:
              <ul style={{ paddingInlineStart: '15px' }}>
                <li>Chrome browser version 90+</li>
                <li>Memory(RAM): 16GB(Recommended), 8GB(Minimum)</li>
                <li>
                  Turn on{' '}
                  <CustomTooltip
                    title={
                      <>
                        <span>
                          To turn hardware acceleration on or off, open Google Chrome, then navigate
                          to Settings &gt; System.
                        </span>
                        <br />
                        <span>
                          Use the toggle next to &quot;Use Hardware Acceleration When
                          Available&quot;to control Google Chrome&apos;s hardware acceleration.
                        </span>
                      </>
                    }
                    sx={{ maxWidth: '600px' }}
                  >
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <Link>Chrome browser&apos;s hardware acceleration option</Link>
                  </CustomTooltip>
                </li>
              </ul>
            </>
          }
        />
        <AuthNav>
          <Box>
            <Link
              href="https://www.lunit.io/en/doc/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </Link>
          </Box>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link sx={{ color: '#AFAFB1', textAlign: 'right' }}>Lunit Inc. All rights reserved</Link>
        </AuthNav>
      </form>
    </AuthTemplate>
  );
}

export default Login;
