import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

import outlined from "../../assets/ic_arrow_up_sm=outlined_20px.svg";

const variants = ['outlined', ] as const;
type Variant = typeof variants[number];
type IconProps = SvgIconProps & {
  variant?: Variant;
};
const ArrowUpSm = ({variant = 'outlined', ...rest}: IconProps) => {
  if (variant === 'outlined') {
    return <SvgIcon {...rest} component={ outlined } inheritViewBox />;
  }

  return null;
};

ArrowUpSm.displayName = 'ArrowUpSm';
ArrowUpSm.variants = variants;

export default ArrowUpSm;
