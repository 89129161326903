import type { ComponentsOverrides } from '@mui/material/styles';

const styleOverrides: ComponentsOverrides['MuiInputBase'] = {
  root: {
    '&': {
      padding: 0,
    },
  },
  input: {
    height: '36px',
  },
  inputAdornedStart: {
    'html &': {
      paddingLeft: 0,
    },
  },
  inputAdornedEnd: {
    'html &': {
      paddingRight: 0,
    },
  },
};
export default {
  styleOverrides,
};
