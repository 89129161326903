import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

import filled from "../../assets/ic_error=filled_16px.svg";
import outlined from "../../assets/ic_error=outlined_16px.svg";

const variants = ['filled', 'outlined', ] as const;
type Variant = typeof variants[number];
type IconProps = SvgIconProps & {
  variant?: Variant;
};
const Error16 = ({variant = 'outlined', ...rest}: IconProps) => {
  if (variant === 'filled') {
    return <SvgIcon {...rest} component={ filled } inheritViewBox />;
  }
  if (variant === 'outlined') {
    return <SvgIcon {...rest} component={ outlined } inheritViewBox />;
  }

  return null;
};

Error16.displayName = 'Error16';
Error16.variants = variants;

export default Error16;
