import React, { ReactNode } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { useMatch, RouteObject } from 'react-router-dom';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import BaseDrawer from '@mui/material/Drawer';

import { Collapse, ListItem, ListItemButton, ListItemIcon, Typography } from '@mui/material';
import { ArrowIcon } from '@lunit/scope-icons';
import useToggleState from '../hooks/useToggleState';
import { DrawerNavLink } from './DrawerNavLink';

export interface NavLinkObject extends RouteObject {
  icon?: React.ReactNode;
  children?: NavLinkObject[];
  title: string;
}

export interface DrawerProps {
  drawerOpen: boolean;
  routes: NavLinkObject[];
  extraContent?: ReactNode;
}

interface TopLevelNavLinkProps {
  route: NavLinkObject;
  routesLength: number;
  routesWithChildren: number;
}

function TopLevelNavLink({ route, routesLength, routesWithChildren }: TopLevelNavLinkProps) {
  const [subNavOpen, toggleSubNavOpen] = useToggleState(false);
  const match = useMatch(route.path || '');
  if (!route.path) {
    return null;
  }
  if (!route.children) {
    return (
      <ListItem sx={{ '&:hover': { color: (theme) => theme.palette.brand.primary } }}>
        <ListItemButton
          to={route.path}
          component={DrawerNavLink}
          className="colorHover"
          activeClassName="colorActive"
          disableRipple
        >
          <ListItemIcon>{route.icon}</ListItemIcon>
          <ListItemText primary={route.title} />
        </ListItemButton>
      </ListItem>
    );
  }
  return (
    <>
      <ListItem
        sx={{ '&:hover': { color: (theme) => theme.palette.brand.primary } }}
        secondaryAction={
          <IconButton
            sx={{
              padding: 0.5,
              color: (theme) => (match ? theme.palette.brand.primary : 'inherit'),
              ':hover': {
                color: 'inherit',
                backgroundColor: (theme) => theme.palette.darkGrey[80],
              },
            }}
            onClick={toggleSubNavOpen}
          >
            <ArrowIcon
              style={{
                transform: subNavOpen ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          </IconButton>
        }
      >
        <ListItemButton
          to={route.path}
          component={DrawerNavLink}
          className="colorHover"
          activeClassName="colorActive"
          disableRipple
        >
          <ListItemIcon>{route.icon}</ListItemIcon>
          <ListItemText primary={route.title} />
        </ListItemButton>
      </ListItem>
      <Collapse
        sx={{
          maxHeight: `calc(${100 / routesWithChildren}% - ${40 * routesLength}px)`,
          overflow: 'auto',
        }}
        in={subNavOpen}
      >
        <>
          {route.children.map(
            (subRoute, idx) =>
              subRoute.path && (
                // eslint-disable-next-line react/no-array-index-key
                <ListItem key={`${subRoute.path}_${idx}`}>
                  <ListItemButton
                    to={subRoute.path}
                    component={DrawerNavLink}
                    activeClassName="bgActive"
                    sx={{ width: '100%' }}
                  >
                    <Typography
                      sx={{
                        ml: 0.5,
                        display: 'block',
                        whiteSpace: 'nowrap',
                      }}
                      variant="body5"
                      title={subRoute.title}
                    >
                      {`- ${subRoute.title}`}
                    </Typography>
                  </ListItemButton>
                </ListItem>
              ),
          )}
        </>
      </Collapse>
    </>
  );
}

const DrawerList = styled(List)({
  paddingTop: 1,
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
});

function Drawer({ drawerOpen, routes, extraContent = null }: DrawerProps) {
  const theme = useTheme();
  const routesLength = routes.length;
  const routesWithChildren = routes.reduce((sum, route) => (route.children ? sum + 1 : sum), 0);

  return (
    <BaseDrawer
      sx={{
        width: 240,
        flexShrink: 0,
      }}
      variant="persistent"
      anchor="left"
      open={drawerOpen}
      PaperProps={{
        sx: {
          color: theme.palette.text.secondary,
          width: 240,
          borderRight: 'none',
          overflowX: 'hidden',
        },
      }}
    >
      <Toolbar />
      {routes.length > 0 && (
        <DrawerList>
          {routes.map((route) => (
            <TopLevelNavLink
              key={route.path}
              route={route}
              routesLength={routesLength}
              routesWithChildren={routesWithChildren}
            />
          ))}
        </DrawerList>
      )}
      {extraContent}
    </BaseDrawer>
  );
}

export default Drawer;
