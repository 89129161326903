import { styled, Box, Typography } from '@mui/material';

export const InfoSection = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const ProductName = styled(Typography)(({ theme }) => ({
  ...theme.typography.body3,
  marginBottom: '18px',
}));

export const LabelRow = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: 12,
  alignItems: 'center',
  '&:last-child': { marginBottom: 0 },
});

export const LabelColumn = styled(Box)({ flex: 0 });

export const LabelValue = styled(Box)({
  flex: 1,
  marginLeft: 16,
});

export const FramedText = styled(Typography)({
  border: '1px solid #000',
  padding: 2,
  fontSize: 12,
  lineHeight: '16px',
  fontWeight: 700,
  wordBreak: 'normal',
});

export const LabelSubTitleTypography = styled(Box)({
  fontSize: 12,
  lineHeight: '16px',
  fontWeight: 700,
});
