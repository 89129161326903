import OpenSeadragon from 'openseadragon';

// eslint-disable-next-line import/prefer-default-export
export const OSD_OPTIONS: OpenSeadragon.Options = {
  imageLoaderLimit: 8,
  smoothTileEdgesMinZoom: Infinity,
  showNavigator: true,
  timeout: 60000,
  navigatorAutoResize: false,
  preserveImageSizeOnResize: true,
  immediateRender: false,
  zoomPerScroll: 1.3,
  showZoomControl: false,
  showHomeControl: false,
  showFullPageControl: false,
  showRotationControl: false,
  animationTime: 0.3,
  constrainDuringPan: true,
  visibilityRatio: 0.8,
  loadTilesWithAjax: true, // for Glob tiling
  showNavigationControl: false,
  gestureSettingsMouse: {
    clickToZoom: false,
    dblClickToZoom: true,
  },
  gestureSettingsTouch: {
    flickEnabled: false,
    clickToZoom: false,
    dblClickToZoom: true,
  },
  overlays: [
    {
      px: 0,
      py: 0,
      class: 'drawing',
    },
  ],
};
