import { Typography, styled } from '@mui/material';

const Contianer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
});

const MatchBox = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export default function NoMatchContainer() {
  return (
    <Contianer>
      <MatchBox>
        <Typography
          variant="h3"
          color="darkGrey.15"
          sx={{
            mt: (theme) => theme.spacing(12.5),
            mb: (theme) => theme.spacing(2),
          }}
        >
          Nothing matches your search
        </Typography>
        <Typography
          variant="subtitle3"
          color="darkGrey.15"
        >
          Please try again!
        </Typography>
      </MatchBox>
    </Contianer>
  );
}
