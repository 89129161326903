import { keyframes } from '@mui/styled-engine';
import { Box, Button, List, styled } from '@mui/material';
import theme from '../theme';

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 300px;
  h2 {
    margin: 0;
    padding: 16px 16px 14px;
    border-bottom: 1px solid ${theme.darkBg};
    font-size: 12px;
    font-weight: 500;
    color: ${theme.lightText};
    letter-spacing: 1.9px;
  }
  ul {
    flex: 1 1 auto;
    height: 0;
    margin: 0;
    padding: 0;
    list-style: none;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 11px;
    }
    &::-webkit-scrollbar-track {
      background: ${theme.bg};
    }
    &::-webkit-scrollbar-thumb {
      background: #4c5563;
    }
  }
`;

export const Item = styled('li')<{ active?: boolean; disabled?: boolean }>`
  display: flex;
  height: 130px;
  padding: 16px;
  border-bottom: 1px solid ${theme.darkBg};
  background-color: ${(props) => (props.active ? theme.darkBg : theme.bg)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export const Thumbnail = styled('div')`
  position: relative;
  width: 60px;
  height: 98px;
  margin-right: 16px;
  background-color: white;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const spin = keyframes`
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
`;

export const Spinner = styled('div')`
  border: 0.2em solid rgba(0, 0, 0, 0.1);
  border-top: 0.2em solid #767676;
  border-radius: 50%;
  width: 2.28571429rem;
  height: 2.28571429rem;
  animation: ${spin} 0.6s linear infinite;
  margin-right: 40px;
  margin-top: 16px;
  display: cursor: ${(props) => (props.hidden ? 'none' : 'block')};
`;

export const Title = styled('div')`
  flex: 1;
  h3 {
    margin: 0 0 7px 0;
    font-size: 14px;
    font-weight: bold;
    color: ${theme.lightText};
  }
  p {
    margin: 0;
    font-size: 12px;
    color: ${theme.text};
    white-space: pre-line;
  }
`;

export const DrawerList = styled(List)({
  paddingTop: 1,
  paddingBottom: 0,
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
});

export const Footer = styled('footer')`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30px;
  padding: 5px 16px;
  span {
    color: ${theme.darkText};
    font-size: 12px;
  }
`;

export const SlideLinkContainer = styled(Box)({
  width: 208,
  height: 98,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
});

export const LoadableImageContainer = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#fff',
  position: 'relative',
  overflow: 'hidden',
  borderRadius: 4,
  '& img': {
    width: '100%',
    height: 'auto',
  },
});

export const SlideItemButton = styled(Button)({
  textDecoration: 'none',
  '&.Mui-selected': {
    color: 'white',
    backgroundColor: '#3E3E40 !important',
    opacity: '1 !important',
  },
  '&.Mui-disabled': { color: '#AFAFB1' },
});
