import { SyntheticEvent, useState } from 'react';

export default function usePopper() {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const onOpen = (e: SyntheticEvent<HTMLElement, MouseEvent>) => setAnchorEl(e && e.currentTarget);
  const onClose = () => setAnchorEl(null);
  return {
    anchorEl,
    open,
    onOpen,
    onClose,
  };
}
