import { ComponentsOverrides } from '@mui/material/styles';
import { palette, typography } from '../foundations';

const styleOverrides: ComponentsOverrides['MuiOutlinedInput'] = {
  root: {
    borderRadius: 8,
    backgroundColor: palette.neutralGrey[75],
    color: palette.neutralGrey[45],
    '&.MuiInputBase-adornedStart': {
      paddingLeft: '16px',
    },
    '&.MuiInputBase-adornedEnd': {
      paddingRight: '16px',
    },
    '&.Mui-disabled': {
      backgroundColor: 'transparent',
      borderWidth: '1px',
      borderColor: palette.neutralGrey[45],
    },
  },
  notchedOutline: {
    border: '1px solid transparent',
    borderRadius: 8,
    padding: 0,
    'html .MuiOutlinedInput-root:hover &': {
      borderColor: palette.neutralGrey[45],
    },
    'html .MuiOutlinedInput-root.Mui-focused &': {
      borderWidth: '1px',
      borderColor: palette.lunitTeal[10],
    },
    'html .MuiOutlinedInput-root.Mui-disabled &': {
      border: 'none',
    },
  },
  input: {
    ...typography.body5,
    color: palette.neutralGrey[5],
    padding: '8px 16px',
    boxSizing: 'border-box',
    '&::placeholder': {
      color: palette.neutralGrey[45],
    },
    '&:-webkit-autofill': {
      boxShadow: `0 0 0 100px ${palette.neutralGrey[75]}`,
      WebkitTextFillColor: palette.neutralGrey[5],
    },
    '&.Mui-disabled': {
      backgroundColor: 'inherit',
      borderRadius: '8px',
      WebkitTextFillColor: palette.neutralGrey[45],
    },
  },
};
export default {
  styleOverrides,
};
