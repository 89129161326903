import { ThemeOptions } from '@mui/material';

declare module '@mui/material/styles' {
  interface Theme {
    leftDrawer: {
      width: React.CSSProperties['width'];
    };
    rightDrawer: {
      width: React.CSSProperties['width'];
    };
  }
  interface ThemeOptions {
    leftDrawer: {
      width: React.CSSProperties['width'];
    };
    rightDrawer: {
      width: React.CSSProperties['width'];
    };
  }
}

const drawer: ThemeOptions = {
  leftDrawer: { width: 240 },
  rightDrawer: { width: 260 },
};

export default drawer;
