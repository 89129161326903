import { Box, styled } from '@mui/material';

const commonStyle = {
  display: 'flex' as const,
  flexDirection: 'column' as const,
  alignItems: 'center' as const,
  justifyContent: 'center' as const,
};
const FileDnDZoneContainer = styled(Box)(() => {
  return {
    position: 'relative' as const,
    height: '100%',
    width: '100%',
  };
});

const BaseFileDnDZone = styled(Box)(() => {
  return {
    ...commonStyle,
    position: 'absolute',
    left: 'calc(50% - 213px)',
    top: '100px',
    height: '196px',
    width: '426px',
    gap: '16px',
  };
});

const DnDSection = styled(Box)(() => {
  return {
    ...commonStyle,
    gap: '4px',
  };
});

const DnDMainBox = styled(Box)(() => {
  return {
    ...commonStyle,
    gap: '24px',
  };
});

export { FileDnDZoneContainer, BaseFileDnDZone, DnDSection, DnDMainBox };
