import { Alert as MuiAlert, alertClasses, styled, svgIconClasses } from '@mui/material';
import type { AlertProps } from './Alert.types';
import { getAccentColor, getBackgroundColor } from './Alert.utils';

const BaseAlert = styled(MuiAlert)<AlertProps>(({ theme, severity }) => ({
  [`&.${alertClasses.root}`]: {
    display: 'flex',
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    maxWidth: '456px',
    borderRadius: '8px',
    border: `1px solid ${getAccentColor(severity)}`,
    backgroundColor: getBackgroundColor(severity),
    boxShadow: 'none',
  },
  [`& .${alertClasses.icon}`]: {
    fontSize: '20px',
    alignItems: 'center',
    padding: `${theme.spacing(1)} 0`,
    marginRight: theme.spacing(2),
    color: `${getAccentColor(severity)} !important`,
  },
  [`& .${alertClasses.message}`]: {
    padding: `${theme.spacing(1)} 0`,
    width: '100%',
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    WebkitLineClamp: '5',
    WebkitBoxOrient: 'vertical',
    color: theme.palette.neutralGrey[0],
  },
  [`& .${alertClasses.action}`]: {
    margin: 0,
    padding: 0,
    paddingLeft: theme.spacing(2),
    alignItems: 'center',
  },
  [`& .${svgIconClasses.root}`]: {
    height: '20px',
    width: '20px',
  },
}));

export default BaseAlert;
