import { AlertColor } from '@mui/material';
import { Success, Error, Information } from '@lunit/oncology-icons';
import theme from '../../theme';

export const getIcons = (severity: AlertColor | undefined) => {
  switch (severity) {
    case 'info':
      return <Information />;
    case 'success':
      return <Success />;
    case 'error':
      return <Error />;
    default:
      return <Error />;
  }
};

export const getBackgroundColor = (severity: AlertColor | undefined) => {
  switch (severity) {
    case 'info':
      return theme.palette.alert.InfoBG;
    case 'success':
      return theme.palette.alert.SuccessBG;
    case 'error':
      return theme.palette.alert.ErrorBG;
    default:
      return theme.palette.alert.WarningBG;
  }
};

export const getAccentColor = (severity: AlertColor | undefined) => {
  switch (severity) {
    case 'info':
      return theme.palette.blue[25];
    case 'success':
      return theme.palette.lunitTeal[40];
    case 'error':
      return theme.palette.red[5];
    default:
      return theme.palette.yellow[20];
  }
};
