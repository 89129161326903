import { Typography as MUITypography } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { BaseEllipsisTypographyProps } from './EllipsisTypography.types';

const StyledTypography = styled(MUITypography, {
  shouldForwardProp: (prop) =>
    !['direction', 'heightThreshold', 'maxLines'].includes(prop.toString()),
})<BaseEllipsisTypographyProps>(({ direction, maxLines }) => ({
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  lineBreak: 'anywhere',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  whiteSpace: 'normal',
  ...(direction === 'row' ? { WebkitLineClamp: 1 } : { WebkitLineClamp: maxLines }),
}));

export default StyledTypography;
