const ACCESS_TOKEN_KEY = 'LUNIT_RESEARCH_SCOPE_TOKEN_KEY';
const REFRESH_TOKEN_KEY = 'LUNIT_RESEARCH_SCOPE_REFRESH_TOKEN_KEY';

function atou(str: string) {
  return decodeURIComponent(escape(window.atob(str)));
}

function decodePayload(payload: string) {
  const replaced = payload.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(replaced)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );
  return JSON.parse(jsonPayload);
}

const accessTokenManager = {
  hasAccessTokenInStorage() {
    return !!this.getAccessTokenFromStorage();
  },
  isAccessTokenSane() {
    const accessToken = this.getAccessTokenFromStorage();
    if (!accessToken) return false;
    const pieces = accessToken.split('.').map((each) => each.replace(/_/g, '/').replace(/-/g, '+'));
    if (pieces.length !== 3) return false;

    try {
      const header = JSON.parse(atou(pieces[0]));
      const payload = JSON.parse(atou(pieces[1]));
      if (!header || !payload) {
        return false;
      }
    } catch (e) {
      return false;
    }
    return true;
  },
  getAccessTokenFromStorage() {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
  },
  hasRefreshTokenInStorage() {
    return !!this.getRefreshTokenFromStorage();
  },
  getRefreshTokenFromStorage() {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  },
  // @todo return값 memoization
  getAccessTokenPayloadFromStorage() {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const accessToken = this.getAccessTokenFromStorage();
    return accessToken && decodePayload(accessToken.split('.')[1]);
  },
  getMFARequiredFromToken() {
    return this.getAccessTokenPayloadFromStorage().mfa_required;
  },
  getMFACertifiedFromToken() {
    return this.getAccessTokenPayloadFromStorage().mfa_certified;
  },
  getMFAVerifiedFromToken() {
    return this.getAccessTokenPayloadFromStorage().mfa_verified;
  },
  getCustomerIdFromToken() {
    return this.getAccessTokenPayloadFromStorage().ctm_id;
  },
  getCustomerEmailFromToken() {
    return this.getAccessTokenPayloadFromStorage().email;
  },
  getRolesFromToken(): string[] {
    return this.getAccessTokenPayloadFromStorage().roles;
  },
  setAccessToken(token?: string) {
    if (token) {
      localStorage.setItem(ACCESS_TOKEN_KEY, token);
    }
    const accessToken = this.getAccessTokenFromStorage();
    return accessToken;
  },
  hasAccessToken() {
    const accessToken = this.getAccessTokenFromStorage();
    return !!accessToken;
  },
  getAccessToken() {
    const accessToken = this.getAccessTokenFromStorage();
    return accessToken;
  },
  removeAccessToken() {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
  },
  setRefreshToken(token?: string) {
    if (token) {
      localStorage.setItem(REFRESH_TOKEN_KEY, token);
    }
    const refreshToken = this.getRefreshTokenFromStorage();
    return refreshToken;
  },
  hasRefreshToken() {
    const refreshToken = this.getRefreshTokenFromStorage();
    return !!refreshToken;
  },
  getRefreshToken() {
    const refreshToken = this.getRefreshTokenFromStorage();
    return refreshToken;
  },
  removeRefreshToken() {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  },
};

accessTokenManager.setAccessToken();
accessTokenManager.setRefreshToken();

export default accessTokenManager;
