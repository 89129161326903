import { styled } from '@mui/material';
import React from 'react';
import { NavLink as BaseNavLink, NavLinkProps } from 'react-router-dom';

interface V5NavLinkProps extends NavLinkProps {
  isActiveFn?: () => boolean;
  activeClassName?: string;
  activeStyle?: React.CSSProperties;
}

const NavLink = React.forwardRef<HTMLAnchorElement, V5NavLinkProps>(
  ({ activeClassName, activeStyle, isActiveFn, ...props }: V5NavLinkProps, ref) => (
    <BaseNavLink
      ref={ref}
      {...props}
      className={({ isActive }) => {
        let active = isActive;
        if (isActiveFn) active = isActiveFn();
        return [props.className, active ? activeClassName : null].filter(Boolean).join(' ');
      }}
      style={({ isActive }) => ({
        ...props.style,
        ...(isActive ? activeStyle : null),
      })}
    />
  ),
);

// eslint-disable-next-line import/prefer-default-export
export const DrawerNavLink = styled(NavLink)(({ theme }) => ({
  textDecoration: 'none',
  '&.colorHover': {
    color: 'inherit',
    '&:hover': {
      color: theme.palette.brand.primary,
      backgroundColor: 'transparent',
    },
  },
}));
