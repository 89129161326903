import { ButtonBase, styled } from '@mui/material';
import type { Theme } from '@mui/material';
import { baseButtonStyle, baseLargeStyle, baseSmallStyle } from './BaseButton.styled';
import { baseLargeIconStyle, baseMediumIconStyle, baseSmallIconStyle } from './IconButton.styled';
import type { BaseButtonProps } from '../Button.types';

const outlinedPrimaryStyle = (theme: Theme, focusOutline: boolean | undefined) => {
  const mainColor = theme.palette.lunitTeal[40];
  return {
    ...baseButtonStyle(theme, false),
    backgroundColor: 'transparent',
    color: mainColor,
    border: `1px solid ${mainColor}`,
    '&:hover': {
      backgroundColor: theme.palette.neutralGrey[70],
      opacity: 0.8,
    },
    '&:disabled': {
      color: mainColor,
      backgroundColor: 'transparent',
      opacity: 0.4,
    },
    '&:focus': {
      border: `1px solid ${mainColor}`,
      outline: focusOutline ? `2px solid ${mainColor}` : 'none',
    },
    '&:focus-visible': {
      border: `1px solid ${mainColor}`,
      outline: focusOutline ? `2px solid ${mainColor}` : 'none',
    },
  };
};

const outlinedSecondaryStyle = (theme: Theme, focusOutline: boolean | undefined) => {
  const mainColor = theme.palette.neutralGrey[25];
  return {
    backgroundColor: 'transparent',
    color: mainColor,
    border: `1px solid ${mainColor}`,
    '&:hover': {
      backgroundColor: theme.palette.neutralGrey[70],
      opacity: 0.8,
    },
    '&:disabled': {
      backgroundColor: 'transparent',
      color: mainColor,
      opacity: 0.4,
    },
    '&:focus': {
      border: `1px solid ${mainColor}`,
      outline: focusOutline ? `2px solid ${mainColor}` : 'none',
    },
    '&:focus-visible': {
      border: `1px solid ${mainColor}`,
      outline: focusOutline ? `2px solid ${mainColor}` : 'none',
    },
  };
};
const outlinedErrorStyle = (theme: Theme, focusOutline: boolean | undefined) => {
  const mainColor = theme.palette.red[5];
  return {
    backgroundColor: 'transparent',
    color: mainColor,
    border: `1px solid ${mainColor}`,
    '&:hover': {
      backgroundColor: theme.palette.neutralGrey[70],
      opacity: 0.8,
    },
    '&:disabled': {
      backgroundColor: 'transparent',
      color: mainColor,
      opacity: 0.4,
    },
    '&:focus': {
      border: `1px solid ${mainColor}`,
      outline: focusOutline ? `2px solid ${mainColor}` : 'none',
    },
    '&:focus-visible': {
      border: `1px solid ${mainColor}`,
      outline: focusOutline ? `2px solid ${mainColor}` : 'none',
    },
  };
};

const BaseOutlinedButton = styled(ButtonBase, {
  shouldForwardProp: (prop) =>
    !['color', 'variant', 'size', 'focusOutline', 'isIconOnly'].includes(prop.toString()),
})<BaseButtonProps>(({ theme, color, size, focusOutline, isIconOnly }) => {
  let resultStyle = outlinedPrimaryStyle(theme, focusOutline);

  // Apply icon sizing if icon only
  if (isIconOnly) {
    if (size === 'small') {
      resultStyle = {
        ...resultStyle,
        ...baseSmallIconStyle,
      };
    } else if (size === 'large') {
      resultStyle = {
        ...resultStyle,
        ...baseLargeIconStyle,
      };
    } else {
      resultStyle = {
        ...resultStyle,
        ...baseMediumIconStyle,
      };
    }
  } else {
    // Apply label or icon-label
    if (size === 'small') {
      resultStyle = {
        ...resultStyle,
        ...baseSmallStyle,
      };
    }

    if (size === 'large') {
      resultStyle = {
        ...resultStyle,
        ...baseLargeStyle,
      };
    }
  }

  // Apply color
  if (color === 'error') {
    resultStyle = {
      ...resultStyle,
      ...outlinedErrorStyle(theme, focusOutline),
    };
  }

  if (color === 'secondary') {
    resultStyle = {
      ...resultStyle,
      ...outlinedSecondaryStyle(theme, focusOutline),
    };
  }

  return resultStyle;
});

export { outlinedPrimaryStyle, outlinedSecondaryStyle, outlinedErrorStyle, BaseOutlinedButton };
