import { forwardRef } from 'react';
import { InputMsg, BaseTextFieldContainer } from '../BaseTextField/BaseTextField.styled';
import BaseTextArea from './TextArea.styled';
import type { TextAreaProps } from './TextArea.types';

const TextArea = forwardRef(
  (props: TextAreaProps, ref?: React.ForwardedRef<HTMLTextAreaElement>) => {
    const { error, helperMsg, OuterBoxProps, ...areaProps } = props;

    return (
      <BaseTextFieldContainer
        {...OuterBoxProps}
        width={props.width}
      >
        <BaseTextArea
          ref={ref}
          {...areaProps}
          multiline="true"
          error={!!error}
        />
        {error ? (
          <InputMsg
            variant="body5"
            error
          >
            {error}
          </InputMsg>
        ) : (
          helperMsg && <InputMsg variant="body5">{helperMsg}</InputMsg>
        )}
      </BaseTextFieldContainer>
    );
  },
);
export default TextArea;
