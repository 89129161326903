import { useContext } from 'react';
import { ListContext } from '../List/List';
import {
  StyledListItem,
  StyledListItemButton,
  StyledListItemText,
  StyledListItemStartSlot,
  StyledListItemEndSlot,
  CheckIcon,
  EmptyIcon,
} from './ListItem.styled';
import type { ListItemComponent, ListItemProps } from './ListItem.types';

const ListItem = (({
  // Root props
  rootProps,
  className,
  classes,
  style,
  sx,
  // Button props
  label,
  startElement,
  endElement,
  selected,
  ...props
}: ListItemProps) => {
  const { size, showCheck } = useContext(ListContext);

  const checkElement = (() => {
    if (showCheck) {
      if (selected) {
        return <CheckIcon />;
      }
      return <EmptyIcon />;
    }
    return null;
  })();

  return (
    <StyledListItem
      {...rootProps}
      disablePadding
      className={className}
      classes={classes}
      style={style}
      sx={sx}
    >
      <StyledListItemButton
        {...props}
        disableRipple
        component={(props as any).component ?? 'span'}
        size={size}
        selected={selected}
      >
        {checkElement && <StyledListItemStartSlot>{checkElement}</StyledListItemStartSlot>}
        {startElement && <StyledListItemStartSlot>{startElement}</StyledListItemStartSlot>}
        <StyledListItemText primary={label} />
        {endElement && <StyledListItemEndSlot>{endElement}</StyledListItemEndSlot>}
      </StyledListItemButton>
    </StyledListItem>
  );
}) as ListItemComponent;

export default ListItem;
