import { Chip as MUIChip } from '@mui/material';
import { styled } from '@mui/material/styles';
import tinycolor from 'tinycolor2';
import theme from '../../theme';
import { ChipProps } from './Chip.types';

const StyledChip = styled(MUIChip, {
  shouldForwardProp: (prop) => prop !== 'disableRipple',
})<ChipProps>(({ className }) => {
  return {
    height: '24px',

    // Preset Colors
    '&.presetDefault.MuiChip-colorDefault': {
      backgroundColor: theme.palette.neutralGrey[70],
      color: theme.palette.neutralGrey[25],
    },

    '&.presetUploaded': {
      backgroundColor: theme.palette.analysisStatus.Uploaded,
      color: theme.palette.lunitTeal[90],
    },

    '&.presetAnalyzing': {
      backgroundColor: theme.palette.analysisStatus.Analyzing,
      color: theme.palette.purple[80],
    },

    '&.presetAnalyzed': {
      backgroundColor: theme.palette.analysisStatus.Analyzed,
      color: theme.palette.green[50],
    },

    '&.presetFailed': {
      color: theme.palette.neutralGrey[0],
      backgroundColor: `${tinycolor(theme.palette.analysisStatus.FailedEnabled).setAlpha(0.5)}`,
      border: `1px solid ${theme.palette.analysisStatus.FailedEnabled}`,

      '&.MuiChip-clickable:hover': {
        backgroundColor: `${tinycolor(theme.palette.analysisStatus.FailedHover).setAlpha(0.5)}`,
        border: `1px solid ${theme.palette.analysisStatus.FailedHover}`,
      },
      '&.MuiChip-clickable:active': {
        backgroundColor: theme.palette.analysisStatus.FailedSelected,
        border: `1px solid ${theme.palette.analysisStatus.FailedSelected}`,
      },
    },

    '&.presetReady': {
      color: theme.palette.neutralGrey[0],
      backgroundColor: `${tinycolor(theme.palette.analysisStatus.Ready).setAlpha(0.5)}`,
      border: `1px solid ${theme.palette.analysisStatus.Ready}`,
      '&.MuiChip-clickable:hover': {
        backgroundColor: `${tinycolor(theme.palette.analysisStatus.ReadyHover).setAlpha(0.5)}`,
        border: `1px solid ${theme.palette.analysisStatus.ReadyHover}`,
      },
      '&.MuiChip-clickable:active': {
        backgroundColor: theme.palette.analysisStatus.ReadySelected,
        border: `1px solid ${theme.palette.analysisStatus.ReadySelected}`,
      },
    },

    '&.presetInferred': {
      color: theme.palette.neutralGrey[0],
      backgroundColor: `${tinycolor(theme.palette.analysisStatus.Inferred).setAlpha(0.5)}`,
      border: `1px solid ${theme.palette.analysisStatus.Inferred}`,
      '&.MuiChip-clickable:hover': {
        backgroundColor: `${tinycolor(theme.palette.analysisStatus.InferredHover).setAlpha(0.5)}`,
        border: `1px solid ${theme.palette.analysisStatus.InferredHover}`,
      },
      '&.MuiChip-clickable:active': {
        backgroundColor: theme.palette.analysisStatus.InferredSelected,
        border: `1px solid ${theme.palette.analysisStatus.InferredSelected}`,
      },
    },

    '&.presetInvalid': {
      color: theme.palette.neutralGrey[0],
      backgroundColor: `${tinycolor(theme.palette.analysisStatus.Invalid).setAlpha(0.5)}`,
      border: `1px solid ${tinycolor(theme.palette.analysisStatus.Invalid).setAlpha(0.5)}`,
      '&.MuiChip-clickable:hover': {
        backgroundColor: `${tinycolor(theme.palette.analysisStatus.InvalidHover).setAlpha(0.5)}`,
        border: `1px solid ${theme.palette.neutralGrey[0]}`,
      },
      '&.MuiChip-clickable:active': {
        backgroundColor: theme.palette.analysisStatus.InvalidSelected,
        border: `1px solid ${theme.palette.neutralGrey[0]}`,
      },
    },

    // Label Font
    '.MuiChip-label': {
      fontStyle: 'normal',
      fontWeight: className === 'presetDefault' ? '400' : '500',
      fontSize: '14px',
      lineHeight: '20px',
    },

    // Icons position
    '.MuiChip-icon': {
      height: '18px',
      marginRight: '-7px',
      color: 'unset', // used default set by preset
    },

    '.MuiChip-deleteIcon': {
      height: '18px',
      width: '18px',
      marginLeft: '-7px',
      color: theme.palette.neutralGrey[0],
      '&:hover': {
        color: theme.palette.neutralGrey[0],
        borderRadius: '50%',
        backgroundColor: theme.palette.neutralGrey[90],
      },
    },
  };
});

export default StyledChip;
