import { ButtonBase, styled } from '@mui/material';
import type { Theme } from '@mui/material';
import { baseButtonStyle, baseLargeStyle, baseSmallStyle } from './BaseButton.styled';
import { baseLargeIconStyle, baseMediumIconStyle, baseSmallIconStyle } from './IconButton.styled';
import type { BaseButtonProps } from '../Button.types';

const ghostPrimaryStyle = (theme: Theme, focusOutline: boolean | undefined) => {
  const mainColor = theme.palette.lunitTeal[40];

  return {
    ...baseButtonStyle(theme, false),
    backgroundColor: 'transparent',
    color: mainColor,
    '&:hover': {
      backgroundColor: theme.palette.neutralGrey[70],
      opacity: 0.8,
    },
    '&:disabled': {
      backgroundColor: 'transparent',
      color: mainColor,
      opacity: 0.4,
    },
    '&:focus': {
      border: focusOutline ? `1px solid ${mainColor}` : '1px solid transparent',
    },
    '&:focus-visible': {
      outline: 'none',
      border: focusOutline ? `1px solid ${mainColor}` : '1px solid transparent',
    },
  };
};

const ghostSecondaryStyle = (theme: Theme, focusOutline: boolean | undefined) => {
  const mainColor = theme.palette.neutralGrey[25];
  return {
    color: mainColor,
    '&:hover': {
      backgroundColor: theme.palette.neutralGrey[75],
      opacity: 0.7,
    },
    '&:disabled': {
      backgroundColor: 'transparent',
      color: mainColor,
      opacity: 0.4,
    },
    '&:focus': {
      border: focusOutline ? `1px solid ${mainColor}` : '1px solid transparent',
    },
    '&:focus-visible': {
      outline: 'none',
      border: focusOutline ? `1px solid ${mainColor}` : '1px solid transparent',
    },
  };
};

const ghostErrorStyle = (theme: Theme, focusOutline: boolean | undefined) => {
  const mainColor = theme.palette.red[5];

  return {
    color: mainColor,
    '&:hover': {
      backgroundColor: theme.palette.neutralGrey[70],
      opacity: 0.7,
    },
    '&:disabled': {
      backgroundColor: 'transparent',
      color: mainColor,
      opacity: 0.4,
    },
    '&:focus': {
      border: focusOutline ? `1px solid ${mainColor}` : '1px solid transparent',
    },
    '&:focus-visible': {
      outline: 'none',
      border: focusOutline ? `1px solid ${mainColor}` : '1px solid transparent',
    },
  };
};

const BaseGhostButton = styled(ButtonBase, {
  shouldForwardProp: (prop) =>
    !['color', 'variant', 'size', 'focusOutline', 'isIconOnly'].includes(prop.toString()),
})<BaseButtonProps>(({ theme, color, size, focusOutline, isIconOnly }) => {
  let resultStyle = ghostPrimaryStyle(theme, focusOutline);

  // Apply icon sizing if icon only
  if (isIconOnly) {
    if (size === 'small') {
      resultStyle = {
        ...resultStyle,
        ...baseSmallIconStyle,
      };
    } else if (size === 'large') {
      resultStyle = {
        ...resultStyle,
        ...baseLargeIconStyle,
      };
    } else {
      resultStyle = {
        ...resultStyle,
        ...baseMediumIconStyle,
      };
    }
  } else {
    // Apply label or icon-label
    if (size === 'small') {
      resultStyle = {
        ...resultStyle,
        ...baseSmallStyle,
      };
    }

    if (size === 'large') {
      resultStyle = {
        ...resultStyle,
        ...baseLargeStyle,
      };
    }
  }

  // Apply color
  if (color === 'error') {
    resultStyle = {
      ...resultStyle,
      ...ghostErrorStyle(theme, focusOutline),
    };
  }

  if (color === 'secondary') {
    resultStyle = {
      ...resultStyle,
      ...ghostSecondaryStyle(theme, focusOutline),
    };
  }

  return resultStyle;
});

export { ghostPrimaryStyle, ghostSecondaryStyle, ghostErrorStyle, BaseGhostButton };
