import { ButtonBase, styled } from '@mui/material';
import type { Theme } from '@mui/material';
import { baseButtonStyle, baseLargeStyle, baseSmallStyle } from './BaseButton.styled';
import { baseLargeIconStyle, baseMediumIconStyle, baseSmallIconStyle } from './IconButton.styled';
import type { BaseButtonProps } from '../Button.types';

const containedPrimaryStyle = (theme: Theme, focusOutline: boolean | undefined) => {
  return baseButtonStyle(theme, focusOutline);
};

const containedSecondaryStyle = (theme: Theme, focusOutline: boolean | undefined) => {
  const mainColor = theme.palette.lunitTeal[40];

  return {
    backgroundColor: theme.palette.neutralGrey[85],
    color: mainColor,
    '&:hover': {
      color: mainColor,
      backgroundColor: theme.palette.neutralGrey[75],
      opacity: 1,
    },
    '&:disabled': {
      color: mainColor,
      backgroundColor: theme.palette.neutralGrey[85],
      opacity: 0.4,
    },
    '&:focus': {
      outline: 'none' as const,
      border: focusOutline ? `1px solid ${mainColor}` : '1px solid transparent',
    },
    '&:focus-visible': {
      outline: 'none' as const,
      border: focusOutline ? `1px solid ${mainColor}` : '1px solid transparent',
    },
  };
};

const containedErrorStyle = (theme: Theme, focusOutline: boolean | undefined) => {
  const mainColor = theme.palette.red[5];

  return {
    backgroundColor: mainColor,
    color: theme.palette.neutralGrey[0],
    '&:hover': {
      backgroundColor: mainColor,
      opacity: 0.8,
    },
    '&:disabled': {
      color: theme.palette.neutralGrey[0],
      backgroundColor: mainColor,
      opacity: 0.4,
    },
    '&:focus': {
      outline: 'none' as const,
      border: focusOutline ? `1px solid ${mainColor}` : '1px solid transparent',
    },
    '&:focus-visible': {
      outline: 'none' as const,
      border: focusOutline ? `1px solid ${mainColor}` : '1px solid transparent',
    },
  };
};

const BaseContainedButton = styled(ButtonBase, {
  shouldForwardProp: (prop) =>
    !['color', 'variant', 'size', 'focusOutline', 'isIconOnly'].includes(prop.toString()),
})<BaseButtonProps>(({ theme, color, size, focusOutline, isIconOnly }) => {
  let resultStyle = containedPrimaryStyle(theme, focusOutline);

  // Apply icon sizing if icon only
  if (isIconOnly) {
    if (size === 'small') {
      resultStyle = {
        ...resultStyle,
        ...baseSmallIconStyle,
      };
    } else if (size === 'large') {
      resultStyle = {
        ...resultStyle,
        ...baseLargeIconStyle,
      };
    } else {
      resultStyle = {
        ...resultStyle,
        ...baseMediumIconStyle,
      };
    }
  } else {
    // Apply label or icon-label
    if (size === 'small') {
      resultStyle = {
        ...resultStyle,
        ...baseSmallStyle,
      };
    }

    if (size === 'large') {
      resultStyle = {
        ...resultStyle,
        ...baseLargeStyle,
      };
    }
  }

  // Apply color
  if (color === 'error') {
    resultStyle = {
      ...resultStyle,
      ...containedErrorStyle(theme, focusOutline),
    };
  }

  if (color === 'secondary') {
    resultStyle = {
      ...resultStyle,
      ...containedSecondaryStyle(theme, focusOutline),
    };
  }

  return resultStyle;
});

export { containedPrimaryStyle, containedSecondaryStyle, containedErrorStyle, BaseContainedButton };
