import { Box, styled, Typography } from '@mui/material';

export const LoadingOverlay = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 99999,
});

export const LoadingIndicatorBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '440px',
  height: '360px',
  padding: `${theme.spacing(13)} ${theme.spacing(4)}`,
  borderRadius: '12px',
  backgroundColor: theme.palette.neutralGrey[85],
  color: theme.palette.neutralGrey[0],
  boxSizing: 'border-box',
}));

export const LoadingMessage = styled(Typography)(({ theme }) => ({
  ...theme.typography.h8,
  marginTop: theme.spacing(12),
  marginBottom: theme.spacing(5),
}));

export const LoadingDetails = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  textAlign: 'center',
}));
