import { useEffect, useState } from 'react';
import env, { DEFAULT_API_TARGET } from 'src/env';

const currentTarget = DEFAULT_API_TARGET;
const TIMEOUT_TIME = 3000;
const POLLING_INTERVAL = 10000;

const connectionTest = (time: number, promise: Promise<Response>) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error('Request time out'));
    }, time);
    promise.then(resolve, reject);
  });

const checkOnlineStatus = async () => {
  const controller = new AbortController();
  const { signal } = controller;
  if (!navigator.onLine) return navigator.onLine;
  try {
    await connectionTest(
      TIMEOUT_TIME,
      fetch(`${env.API_URLS[currentTarget]}/monitor/health`, {
        method: 'get',
        signal,
      }),
    );
    return true;
  } catch (error) {
    controller.abort();
  }
  return false;
};

const useOnlineStatus = () => {
  const [onlineStatus, setOnlineStatus] = useState<boolean>(true);

  const checkStatus = async () => {
    const online = await checkOnlineStatus();
    setOnlineStatus(online);
  };

  useEffect(() => {
    window.addEventListener('offline', () => {
      setOnlineStatus(false);
    });

    const intervalId = setInterval(() => {
      checkStatus();
    }, POLLING_INTERVAL);

    return () => {
      window.removeEventListener('offline', () => {
        setOnlineStatus(false);
      });
      clearInterval(intervalId);
    };
  }, []);
  return onlineStatus;
};

export default useOnlineStatus;
