import { ReactNode } from 'react';
import { AppBar, Box, Container, Link } from '@mui/material';

interface IAuthTemplateProps {
  children: ReactNode;
  headerFixed?: boolean;
  disableHeader?: boolean;
  wide?: boolean;
  logo?: string;
  logoLink?: string;
}

function AuthTemplate({
  disableHeader,
  headerFixed = true,
  children,
  wide,
  logo,
  logoLink = '#',
}: IAuthTemplateProps) {
  return (
    <Box sx={{ overflowY: 'auto', height: '100%' }}>
      {!disableHeader && (
        <AppBar
          position={headerFixed ? 'fixed' : 'relative'}
          elevation={0}
          sx={{
            backgroundColor: (theme) => theme.palette.darkGrey[90],
            padding: (theme) => theme.spacing(4.5, 7.5),
          }}
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: 'fit-content',
            }}
            href={logoLink}
          >
            <img
              src={logo}
              alt="logo"
              style={{ height: '23px' }}
            />
          </Link>
        </AppBar>
      )}
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          display: 'flex',
          height: disableHeader || !headerFixed ? 'auto' : '100%',
          width: '100%',
          overflow: 'auto',
          padding: '100px',
        }}
      >
        <Box
          sx={{
            border: (theme) => `1px solid ${theme.palette.darkGrey[80]}`,
            borderRadius: '12px',
            boxSizing: 'border-box',
            width: wide ? '780px' : '500px',
            padding: 7.5,
            margin: 'auto',
            fontWeight: 400,
            '& .Alert': { marginBottom: 2 },
            zIndex: 1200,
            maxHeight: disableHeader || !headerFixed ? 'none' : '85vh',
          }}
        >
          {children}
        </Box>
      </Container>
    </Box>
  );
}

export default AuthTemplate;
