import { ComponentsOverrides, Theme } from '@mui/material';
import {
  SMALL_SCROLLBAR_WIDTH,
  SCROLLBAR_WIDTH,
  DRAWER_WIDTH_OPEN,
  DRAWER_WIDTH_CLOSE,
} from '../systems';
import { palette, typography } from '../foundations';

const html = {
  fontSize: '14px',
  boxSizing: 'border-box',
  fontFamily: `Pretendard, sans-serif`,
  fontFeatureSettings: `'tnum', 'ss01', 'ss02', 'ss08'`,
};

const body = {
  ...html,
  width: '100vw',
  height: '100vh',
  margin: 0,
  padding: 0,
  overflow: 'hidden',
  lineHeight: 'normal',
  color: 'white',
  backgroundColor: palette.neutralGrey?.[95],
  '& a': {
    textDecoration: 'none',
  },
};

const styleOverrides: ComponentsOverrides<Theme>['MuiCssBaseline'] = {
  html,
  body,
  '.App': body,
  '#root': body,

  '*::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  '*::-webkit-scrollbar-corner': {
    backgroundColor: 'transparent',
  },
  '*::-webkit-scrollbar': {
    // + 4 for padding
    width: SCROLLBAR_WIDTH + 4,
    height: SCROLLBAR_WIDTH + 4,
  },
  '.small-scrollbar::-webkit-scrollbar, .small-scrollbar *::-webkit-scrollbar': {
    // + 4 for padding
    width: SMALL_SCROLLBAR_WIDTH + 4,
    height: SMALL_SCROLLBAR_WIDTH + 4,
  },

  '*::-webkit-scrollbar-thumb': {
    backgroundColor: palette.neutralGrey?.[65],
    borderRadius: 6,
    border: '2px transparent solid',
    backgroundClip: 'padding-box',
  },
  '*::-webkit-scrollbar-thumb:hover': {
    backgroundColor: palette.neutralGrey?.[60],
  },
  '*::-webkit-scrollbar-thumb:active': {
    backgroundColor: palette.neutralGrey?.[40],
  },
  '.Mui-disabled::-webkit-scrollbar-thumb:hover, .Mui-disabled *::-webkit-scrollbar-thumb:hover': {
    backgroundColor: palette.neutralGrey?.[65],
  },
  '.Mui-disabled::-webkit-scrollbar-thumb:active, .Mui-disabled *::-webkit-scrollbar-thumb:active':
    {
      backgroundColor: palette.neutralGrey?.[65],
    },

  'svg.MuiCircularProgress-svg *': {
    fill: 'none',
  },
  'svg *.stroke-inherit': {
    fill: 'none',
    stroke: 'currentColor',
  },
  '.snackbarVariantError': {
    background: 'rgba(238, 81, 64, 0.18)',
    border: '1px solid #EE5140',
    ...typography.body5,
  },
  '#page-root.drawer-open ~ .SnackbarContainer-left': {
    left: `${DRAWER_WIDTH_OPEN + 20}px !important`,
  },
  '#page-root.drawer-close ~ .SnackbarContainer-left': {
    left: `${DRAWER_WIDTH_CLOSE + 20}px !important`,
  },
};
export default {
  styleOverrides,
};
