import React from 'react';
import ReactDOM from 'react-dom';

interface PageTemplateProps {
  children: React.ReactNode;
  toolbar?: React.ReactNode;
}

function Toolbar({ children }: { children: React.ReactNode }) {
  const toolbarEl = document.getElementById('toolbar-container');
  return toolbarEl && ReactDOM.createPortal(children, toolbarEl, 'toolbar');
}

export default function PageTemplate({ children, toolbar }: PageTemplateProps) {
  return (
    <>
      <Toolbar>{toolbar}</Toolbar>
      {children}
    </>
  );
}
