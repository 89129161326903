import type { ComponentsOverrides, ComponentsProps } from '@mui/material/styles';
import palette from '../colors';

const styleOverrides: ComponentsOverrides['MuiIconButton'] = {
  root: {
    padding: 8,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: palette.darkGrey[80],
      color: 'white',
    },
  },
  colorPrimary: {
    color: palette.darkGrey[20],
  },
  colorSecondary: {
    color: palette.darkGrey[20],
    '&:hover': {
      backgroundColor: palette.darkGrey[80],
      color: palette.brand.primary,
    },
  },
  colorInherit: {
    color: 'inherit',
  },
  sizeMedium: { padding: 8 },
  sizeSmall: { padding: 4 },
};
const defaultProps: ComponentsProps['MuiIconButton'] = {
  color: 'primary',
};
export default {
  styleOverrides,
  defaultProps,
};
