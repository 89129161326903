/* eslint-disable import/prefer-default-export */

export function supportsOffscreenCanvas(): boolean {
  const canvas = document.createElement('canvas');
  if (
    canvas.transferControlToOffscreen &&
    typeof canvas.transferControlToOffscreen === 'function'
  ) {
    return true;
  }
  return false;
}
