import { useState, useMemo } from 'react';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';

import AuthTemplate from '@lunit/oncology-components/Auth/AuthTemplate';
import AuthHeading from '@lunit/oncology-components/Auth/AuthHeading';
import AuthTextField from '@lunit/oncology-components/Auth/AuthTextField';
import AuthActionButton from '@lunit/oncology-components/Auth/AuthActionButton';
import AuthNav from '@lunit/oncology-components/Auth/AuthNav';
import AuthDescription from '@lunit/oncology-components/Auth/AuthDescription';
import AuthAlert from '@lunit/oncology-components/Auth/AuthAlert';
import { APIError } from '@lunit/oncology-components/api/models';
import { Link } from '@mui/material';
import { AlertColor } from '@mui/material/Alert';

import * as authAPI from '../../api/auth/queries';

interface Alert {
  severity: AlertColor;
  message: string;
}

function ForgotPassword() {
  const [email, setEmail] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const alert: Alert | null = useMemo(() => {
    if (errorMessage) {
      return {
        severity: 'error',
        message: errorMessage,
      };
    }
    if (successMessage) {
      return {
        severity: 'success',
        message: successMessage,
      };
    }
    return null;
  }, [errorMessage, successMessage]);

  const forgotPassword = useMutation(authAPI.forgotPassword, {
    onSuccess: () => {
      setSuccessMessage('Successfully sent you an email. Please check your email now.');
    },
    onError: (error: APIError) => {
      if (error.code === 4001) {
        setErrorMessage("Couldn't find your account.");
      } else {
        setErrorMessage('Something went wrong. Please try again.');
      }
    },
  });

  const onSubmit = () => {
    setSuccessMessage('');
    setErrorMessage('');
    forgotPassword.mutate({ email });
  };

  const onSubmitError = (err: any) => {
    setErrorMessage('');
    let errorMessageSet = false;
    if (err.email && !errorMessageSet) {
      setErrorMessage(err.email.message);
      errorMessageSet = true;
    }
  };

  return (
    <AuthTemplate
      logo="/scope-w.svg"
      logoLink="/login"
    >
      <form
        onSubmit={handleSubmit(onSubmit, onSubmitError)}
        noValidate
      >
        <AuthHeading>Forgot password?</AuthHeading>
        <AuthNav>
          <Link
            href="/login"
            sx={{ textAlign: 'right' }}
          >
            Back to sign-in
          </Link>
        </AuthNav>
        <AuthDescription sx={{ marginTop: 5 }}>
          Please enter your registered email. A link will be sent to your email account to reset
          your password.
          <ul style={{ margin: '4px 0', paddingLeft: '20px' }}>
            <li>
              The link will remain <b>valid for 1 hour</b> for security reasons.
            </li>
            <li>Please be aware of capital letters.</li>
            <li>Please contact the support email, if you forgot your registered email address.</li>
            <li>Please check junk, spam, or other folders, if you can&apos;t find the email.</li>
          </ul>
        </AuthDescription>
        <AuthTextField
          id="email"
          type="email"
          aria-label="Business email"
          placeholder="Business email"
          value={email}
          {...register('email', { required: 'This field is required.' })}
          onChange={(e) => setEmail(e.target.value)}
          error={!!errors.email}
        />
        <AuthActionButton>Send</AuthActionButton>
        {alert && (
          <AuthAlert
            sx={{ marginTop: 3 }}
            severity={alert.severity}
          >
            {alert.message}
          </AuthAlert>
        )}
        <AuthNav>
          <Link
            href="mailto:cs-scope@lunit.io"
            target="_blank"
            sx={{ textAlign: 'right' }}
          >
            cs-scope@lunit.io
          </Link>
        </AuthNav>
      </form>
    </AuthTemplate>
  );
}

export default ForgotPassword;
