// @ts-nocheck
// import { MaskWorkerMessage } from '../workers/MaskWorker.worker';

// class MaskWorkerMessageEvent implements MessageEvent {
//   data: MaskWorkerMessage;
// }

type InferenceData = {
  classId: String;
  color: String;
  prerenderedBitmap: ImageBitmap;
};

export default class OffscreenImageMask {
  worker: Worker;

  results: Array<InferenceData>;

  mergedCanvas: HTMLCanvasElement;

  mergedBitmap: ImageBitmap | null;

  mergedClassIds: String;

  minX: Number;

  minY: Number;

  downsampling: Number;

  constructor(results: Array<InferenceData>, idx: number = 0) {
    window.lunitScopeMaskWorker = window.lunitScopeMaskWorker || {};
    window.lunitScopeMaskWorker[idx] =
      window.lunitScopeMaskWorker[idx] ||
      new Worker(new URL('../workers/MaskWorker.worker.ts', import.meta.url));
    this.worker = window.lunitScopeMaskWorker[idx];
    this.results = results;
    this.minX = 0;
    this.minY = 0;
    this.downsampling = 16; // default
    this.mergedCanvas = document.createElement('canvas');
    this.mergedBitmap = null;
    this.mergedClassIds = '';
    const offscreen = this.mergedCanvas.transferControlToOffscreen();
    this.worker.postMessage(
      {
        action: 'canvas',
        canvas: offscreen,
      },
      [offscreen],
    );
  }

  prepare(src: String, minX: Number, minY: Number, downsampling?: Number): Promise<any> {
    if (!Number.isNaN(downsampling)) {
      this.downsampling = downsampling;
    }
    if (Number.isNaN(minX) || Number.isNaN(minY)) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('invalid mask offset');
    }
    this.minX = minX;
    this.minY = minY;

    return new Promise((resolve) => {
      this.worker.onmessage = (evt) => {
        if (evt.data.action === 'prepare') {
          resolve(this);
        }
      };
      this.worker.postMessage({
        action: 'prepare',
        src,
        results: this.results,
      });
    });
  }

  mergeSelectedClasses(taskClassIds: string[]): Promise<OffscreenImageMask> {
    const selected = this.results.filter((data) => taskClassIds.includes(data.labelId));
    const selectedIds = selected.reduce((ids, cls) => `${ids}|${cls.classId}`, '');
    if (this.mergedClassIds === taskClassIds) {
      return Promise.resolve(this);
    }
    return new Promise((resolve) => {
      this.worker.onmessage = (evt) => {
        if (evt.data.action === 'merge') {
          if (this.mergedBitmap) {
            this.mergedBitmap.close();
          }
          this.mergedBitmap = evt.data.mergedBitmap;
          this.mergedClassIds = selectedIds;
          resolve(this);
        }
      };
      this.worker.postMessage({ action: 'merge', selected });
    });
  }

  draw(ctx: CanvasRenderingContext2D, baseAlpha: Number): void {
    if (this.mergedBitmap) {
      ctx.imageSmoothingEnabled = false;
      ctx.globalAlpha = baseAlpha;
      ctx.drawImage(
        this.mergedBitmap,
        this.minX,
        this.minY,
        this.mergedBitmap.width * this.downsampling,
        this.mergedBitmap.height * this.downsampling,
      );
      ctx.globalAlpha = 1;
    }
  }

  destroy(): void {
    if (this.worker) {
      this.worker.postMessage({ action: 'destroy' });
    }
    if (this.mergedBitmap) this.mergedBitmap.close();
    this.mergedCanvas = null;
    this.results = [];
  }
}
