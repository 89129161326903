import TabPanel from '@mui/lab/TabPanel/TabPanel';
import { Tab, Tabs, styled } from '@mui/material';
import type { Theme } from '@mui/material';
import type { BaseTabProps, BaseTabsProps, TabSize } from './TabbedPanel.types';

const tabSizeStyle = (size: TabSize) => {
  return {
    minHeight: `${size === 'small' ? '28px' : '44px'} !important`,
  };
};

// Style for Tabs (Tab Group)
const baseTabMenuStyle = (theme: Theme) => {
  return {
    padding: 0,
    borderBottom: `1px solid ${theme.palette.neutralGrey[65]}`,
  };
};

const toggleTabMenuStyle = {
  borderRadius: '8px',
  outline: 'none',
  boxShadow: 'none',
  borderBottom: 'none',
};

// Style for Tab (Tab Button)
const baseTabStyle = (theme: Theme, size: TabSize) => {
  return {
    background: theme.palette.neutralGrey[90],
    borderRadius: 'inherit',
    color: theme.palette.neutralGrey[45],
    minWidth: '100px',
    padding: `${size === 'small' ? '4px 8px' : '12px 16px'}`,
    textTransform: 'none' as const,
    '&:hover': {
      opacity: 0.8,
    },
    '&:active': {
      background: theme.palette.neutralGrey[60],
      color: theme.palette.neutralGrey[0],
    },
    '&.Mui-selected': {
      background: theme.palette.neutralGrey[90],
      color: theme.palette.neutralGrey[0],
    },
  };
};

const toggleTabStyle = (theme: Theme) => {
  return {
    outline: 'none',
    borderRadius: 'inherit',
    border: `1px solid ${theme.palette.neutralGrey[75]}`,
    boxShadow: 'none',
    background: theme.palette.neutralGrey[90],
    color: theme.palette.neutralGrey[45],
    '&:hover': {
      opacity: 0.8,
    },
    '&:active': {
      borderRadius: 'inherit',
      boxShadow: 'none',
      outline: 'none',
      borderColor: theme.palette.neutralGrey[60],
      background: theme.palette.neutralGrey[60],
      color: theme.palette.neutralGrey[0],
    },
    '&.Mui-selected': {
      borderRadius: 'inherit',
      boxShadow: 'none',
      outline: 'none',
      borderColor: 'transparent',
      background: theme.palette.neutralGrey[75],
      color: theme.palette.neutralGrey[0],
      '&:hover': { opacity: 1 },
    },
    '&:first-of-type': {
      borderRadius: '8px 0 0 8px',
    },
    '&:last-of-type': {
      borderRadius: '0 8px 8px 0',
    },
  };
};

const baseTabbedPanelStyle = {
  padding: '0',
};

const BaseTabMenu = styled(Tabs, {
  shouldForwardProp: (prop) => !['size', 'buttonVariant'].includes(prop.toString()),
})<BaseTabsProps>(({ theme, size, buttonVariant }) => {
  let style = {
    ...baseTabMenuStyle(theme),
    ...tabSizeStyle(size ?? 'medium'),
  };
  if (buttonVariant === 'toggle') {
    style = {
      ...style,
      ...toggleTabMenuStyle,
    };
  }
  return style;
});

const BaseTab = styled(Tab, {
  shouldForwardProp: (prop) => !['size', 'buttonVariant'].includes(prop.toString()),
})<BaseTabProps>(({ theme, size, buttonVariant }) => {
  let style = {
    ...baseTabStyle(theme, size ?? 'medium'),
    ...tabSizeStyle(size ?? 'medium'),
  };

  if (buttonVariant === 'toggle') {
    style = {
      ...style,
      ...toggleTabStyle(theme),
    };
  }

  return style;
});

const BaseTabPanel = styled(TabPanel)(() => {
  return baseTabbedPanelStyle;
});

export { BaseTabMenu, BaseTab, BaseTabPanel };
