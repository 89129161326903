import { styled, Box, Checkbox } from '@mui/material';

export const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'touchRippleRef',
})(({ theme }) => ({
  display: 'inline-flex',
  gap: theme.spacing(2),
}));

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  width: '22px',
  height: '22px',
  padding: 0,
  '&.Mui-disabled, &.Mui-disabled + .MuiTypography-root': {
    opacity: 0.4,
  },
  '&.Mui-focusVisible > .checkbox-icon': {
    borderRadius: '4px',
    boxShadow: `0 0 0 1px ${theme.palette.lunitTeal[10]}`,
  },
}));

export const iconStyle = {
  display: 'inline-block',
  width: '18px',
  height: '18px',
};
