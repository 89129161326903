import { AlertColor } from '@mui/material';
import {
  useSnackbar as useNotiSnackbar,
  VariantType,
  SnackbarKey,
  ProviderContext,
  OptionsObject,
} from 'notistack';
import AlertSnackBar, {
  AlertSnackBarOptionalProps,
} from '@lunit/oncology-components/AlertSnackBar';

const getSeverityFromVariant = (variant?: VariantType): AlertColor => {
  switch (variant) {
    case 'error':
      return 'error';
    case 'success':
      return 'success';
    case 'warning':
      return 'warning';
    default:
      return 'info';
  }
};

const useSnackbar = (): {
  enqueueSnackbar: (
    message: string,
    options?: OptionsObject,
    props?: AlertSnackBarOptionalProps,
  ) => SnackbarKey;
  closeSnackbar: ProviderContext['closeSnackbar'];
} => {
  const { enqueueSnackbar: enqueueNotiSnackbar, closeSnackbar } = useNotiSnackbar();
  const enqueueSnackbar = (
    message: string,
    options?: OptionsObject,
    props?: AlertSnackBarOptionalProps,
  ) =>
    enqueueNotiSnackbar('', {
      ...options,
      content: (key) => (
        <AlertSnackBar
          id={key}
          severity={getSeverityFromVariant(options?.variant)}
          action={options?.action}
          message={message}
          {...props}
        />
      ),
    });

  return {
    enqueueSnackbar,
    closeSnackbar,
  };
};

export default useSnackbar;
