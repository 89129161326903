import * as React from 'react';
import type { SVGProps } from 'react';
const SvgIcRefreshRightoutlined20Px = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M14.436 3.343a8 8 0 1 0 3.294 8.718.5.5 0 0 0-.966-.258 7 7 0 1 1-.273-4.424h-2.957a.5.5 0 0 0 0 1h4.243a.5.5 0 0 0 .5-.5V3.636a.5.5 0 0 0-1 0v3.04a8 8 0 0 0-2.84-3.333Z" />
  </svg>
);
export default SvgIcRefreshRightoutlined20Px;
