import {
  MenuItem,
  styled,
  buttonBaseClasses,
  ListItemIcon,
  menuItemClasses,
  ListItemText,
  typographyClasses,
} from '@mui/material';
import type { StyledMenuItemProps } from './MenuItem.types';

export { CheckIcon, EmptyIcon } from '../ListItem/ListItem.styled';

export const StyledMenuItem = styled(MenuItem)<StyledMenuItemProps>(({ size, theme }) => {
  return {
    [`&.${buttonBaseClasses.root}`]: {
      padding: size === 'small' ? theme.spacing(2) : theme.spacing(2, 4),
      margin: size === 'small' ? theme.spacing(0, -2) : theme.spacing(0, -4),
      '&.Mui-selected': {
        background: 'transparent',
      },
      '&:hover, &.Mui-selected:hover, &.Mui-focusVisible, &.Mui-selected.Mui-focusVisible': {
        borderRadius: '8px',
        backgroundColor: theme.palette.neutralGrey[75],
      },
    },
  };
});

const iconStyles = {
  minWidth: '20px',
  width: '20px',
  height: '20px',
  color: 'currentColor',
  svg: {
    width: '100%',
    height: '100%',
    color: 'currentColor !important',
  },
};

export const StyledMenuItemStartIcon = styled(ListItemIcon)(({ theme }) => ({
  [`.${menuItemClasses.root} &`]: {
    ...iconStyles,
    marginRight: theme.spacing(2),
  },
}));

export const StyledMenuItemEndIcon = styled(ListItemIcon)(({ theme }) => ({
  [`.${menuItemClasses.root} &`]: {
    ...iconStyles,
    justifyContent: 'flex-end',
    marginLeft: theme.spacing(2),
  },
}));

export const StyledMenuItemText = styled(ListItemText)(({ theme }) => ({
  margin: 0,
  [`& .${typographyClasses.body1}`]: {
    ...theme.typography.body5,
  },
}));
