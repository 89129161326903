import { styled, listItemButtonClasses, listItemTextClasses } from '@mui/material';
import { ListItem } from '../ListItem';
import { ListItemCaptionClassName } from '../ListItemCaption/ListItemCaption';

export { EmptyIcon } from '../ListItem/ListItem.styled';

export const StyledNavigationItem = styled(ListItem)(({ theme }) => ({
  width: '100%',
  [`& > .${listItemButtonClasses.root}`]: {
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  [`& > .${listItemButtonClasses.root} .${listItemTextClasses.root}`]: {
    wordBreak: 'break-all',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    overflow: 'hidden',
  },
  [`& > .${listItemButtonClasses.root}.Mui-selected`]: {
    color: theme.palette.lunitTeal[40],
    [`.${ListItemCaptionClassName}`]: {
      color: theme.palette.lunitTeal[10],
    },
  },
})) as typeof ListItem;
