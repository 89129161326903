import type { ButtonProps, IconButtonProps, NormalButtonProps } from './Button.types';

/**
 * @param props
 * @returns boolean
 * @description checks for presence of `label` prop to assert between Icon and Normal Button
 */
function isIconButton(props: ButtonProps): props is IconButtonProps {
  const p = props as NormalButtonProps;
  return !p.label;
}

export { isIconButton };
