import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import toUpper from 'lodash/toUpper';
// import SettingsDialog from "components/SettingsDialog";
import { useState } from 'react';
import HeaderButton from './HeaderButton';

const AvatarBackground = styled('div')(({ theme }) => ({
  width: 28,
  height: 28,
  borderRadius: 14,
  backgroundColor: theme.palette.brand.primary,
  color: theme.palette.darkGrey[0],
  fontSize: 16,
  fontWeight: 600,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

interface AvatarProps {
  name: string;
}
interface AvatarButtonProps extends AvatarProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

function Avatar({ name }: AvatarProps) {
  return <AvatarBackground>{toUpper(name[0])}</AvatarBackground>;
}

function AvatarButton({ name, onClick }: AvatarButtonProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [settingsOpen, setSettingsOpen] = useState(false);
  return (
    <>
      <Tooltip title={name}>
        <HeaderButton
          sx={{ paddingRight: '18px', width: 54 }}
          hasMenu
          onClick={onClick}
        >
          <Avatar name={name} />
        </HeaderButton>
      </Tooltip>
      {/* TODO: uncomment when we know what to do with settings <SettingsDialog
        open={settingsOpen}
        onClose={() => setSettingsOpen(false)}
      /> */}
    </>
  );
}

export default AvatarButton;
