import { createAPIQuery } from '@lunit/oncology-components/api/utils/apiQueryHelper';
import { FetchMethod, request } from '../utils/apiRequestHelper';
import { CommentApi, SaveCommentParams, SlidesApi } from './models';

const getSlides = createAPIQuery(
  (group: string, customerCode: string) =>
    request<SlidesApi>({
      method: FetchMethod.Get,
      path: `research/analysis/results?group=${group}&customer_code=${customerCode}`,
    }),
  () => ['slides', 'getSlides'],
);

const getSlideMsgpackPresignedUrl = createAPIQuery(
  (group: string, customerCode: string, msgpackPath: string) =>
    request<string>({
      method: FetchMethod.Get,
      path: `research/analysis/results/${group}/files/${encodeURIComponent(
        msgpackPath,
      )}?customer_code=${customerCode}`,
      getText: true,
    }),
  (msgpackPath: string) => ['BiomarkerMsgpackData', msgpackPath],
);

const getComment = createAPIQuery(
  (group: string, slide: string, customer_code?: string) => {
    const appendix = customer_code ? `&customer_code=${customer_code}` : '';
    return request<CommentApi>({
      method: FetchMethod.Get,
      path: `research/comments?group=${encodeURIComponent(group)}&slide=${encodeURIComponent(
        slide,
      )}${appendix}`,
    });
  },
  () => ['comment'],
);

const saveComment = (payload: SaveCommentParams) =>
  request({
    method: FetchMethod.Patch,
    path: 'research/comments',
    payload,
  });

export { getSlides, getSlideMsgpackPresignedUrl, getComment, saveComment };
