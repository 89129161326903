import { Coord, TaskClass } from '../../types';

const getColor = (color: string): string => {
  if (!color) return 'rgb(127, 127, 127)';
  const hexColor = color;
  const r = Number.parseInt(hexColor.substr(1, 2), 16);
  const g = Number.parseInt(hexColor.substr(3, 2), 16);
  const b = Number.parseInt(hexColor.substr(5, 2), 16);
  return `rgb(${r}, ${g}, ${b})`;
};

export default class ContourList {
  coordsArray: Array<Array<Coord>>;

  classId: string;

  color: string;

  constructor(coordsArray: Array<Array<Coord>>, cls: TaskClass) {
    this.coordsArray = coordsArray;
    this.classId = cls.id;
    this.color = cls.color;
  }

  draw(ctx: CanvasRenderingContext2D): void {
    ctx.lineWidth = 20;
    ctx.strokeStyle = getColor(this.color);
    this.coordsArray.forEach((coords) => {
      ctx.beginPath();
      ctx.moveTo(coords[0].x, coords[0].y);
      coords.forEach((coord) => {
        ctx.lineTo(coord.x, coord.y);
      });
      ctx.closePath();
      ctx.stroke();
    });
  }

  destroy(): void {
    this.coordsArray = [];
  }
}
