import { ReactNode, forwardRef } from 'react';
import { Typography, Alert, AlertColor, AlertTitle, styled } from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { CloseIcon, ErrorIcon, InfoIcon, SuccessIcon } from '@lunit/scope-icons';
import { SnackbarKey, useSnackbar } from 'notistack';

interface AlertIconButtonProps extends IconButtonProps {
  severity?: AlertColor;
}

export interface AlertSnackBarOptionalProps {
  noAction?: boolean;
  isLight?: boolean;
  children?: ReactNode;
  action?: ReactNode | ((key: SnackbarKey) => React.ReactNode);
}
interface AlertSnackBarProps extends AlertSnackBarOptionalProps {
  id: SnackbarKey;
  customCloseSnackbar?: (key: SnackbarKey) => void;
  severity: AlertColor;
  message: string;
}

const Container = styled('div')({
  maxWidth: 456,
  minWidth: 456,
});

const IconColor = (severity: AlertColor) => {
  switch (severity) {
    case 'success':
      return '#00C9EA';
    case 'error':
      return '#EE5140';
    case 'info':
      return '#7292FD';
    default:
      return '#FFE600';
  }
};

const AlertIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'severity',
})<AlertIconButtonProps>(({ severity }) => ({
  '&.MuiIconButton-root:hover': { background: `${IconColor(severity)}20` },
}));

function AlertIcon(severity: AlertColor) {
  switch (severity) {
    case 'success':
      return <SuccessIcon style={{ color: IconColor(severity) }} />;
    case 'error':
      return <ErrorIcon style={{ color: IconColor(severity) }} />;
    case 'info':
      return <InfoIcon style={{ color: IconColor(severity) }} />;
    default:
      return false;
  }
}

const AlertSnackBar = forwardRef<HTMLDivElement, AlertSnackBarProps>(
  (props: AlertSnackBarProps, ref) => {
    const { id, severity, message, isLight, children, action, noAction, customCloseSnackbar } =
      props;
    const { closeSnackbar } = useSnackbar();

    let alertAction;

    if (action || noAction) {
      if (typeof action === 'function') {
        alertAction = action(id);
      } else {
        alertAction = action;
      }
    } else {
      alertAction = (
        <AlertIconButton
          onClick={() => (customCloseSnackbar ? customCloseSnackbar(id) : closeSnackbar(id))}
          severity={severity}
        >
          <CloseIcon style={{ color: IconColor(severity) }} />
        </AlertIconButton>
      );
    }

    return (
      <Container ref={ref}>
        <Alert
          severity={severity}
          icon={AlertIcon(severity)}
          sx={{ backdropFilter: 'blur(30px)' }}
          action={alertAction}
        >
          <AlertTitle sx={{ marginBottom: 0 }}>
            <Typography
              variant="body5"
              color={isLight ? '#000' : '#fff'}
            >
              {message}
            </Typography>
          </AlertTitle>
          {children}
        </Alert>
      </Container>
    );
  },
);

export default AlertSnackBar;
