import BaseLinearProgress from './LinearProgress.styled';
import type { LinearProgressProps } from './LinearProgress.types';

function LinearProgress({ barColor, progress, height = 4, ...otherProps }: LinearProgressProps) {
  return (
    <BaseLinearProgress
      value={progress}
      height={height}
      barColor={barColor}
      {...otherProps}
    />
  );
}

export default LinearProgress;
