import { useState } from 'react';
import { Tooltip } from '@mui/material';
import HeaderButton from '@lunit/oncology-components/HeaderButton';
import { LabelIcon } from '@lunit/scope-icons';
import { LabelDialog } from '@lunit/oncology-components';
import LabelContent from './LabelContent';

function ProductLabelButton() {
  const [labelOpen, setLabelOpen] = useState<boolean>(false);

  const handleLabelDialog = () => {
    setLabelOpen(!labelOpen);
  };

  return (
    <Tooltip title={!labelOpen ? 'Label' : ''}>
      <span>
        <HeaderButton onClick={handleLabelDialog}>
          <LabelIcon />
        </HeaderButton>
        <LabelDialog
          open={labelOpen}
          handleClose={handleLabelDialog}
          // eslint-disable-next-line react/no-children-prop
          children={<LabelContent />}
        />
      </span>
    </Tooltip>
  );
}

export default ProductLabelButton;
