import { Button, Tooltip, Typography, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import PlusIcon from '@mui/icons-material/AddOutlined';
import MinusIcon from '@mui/icons-material/RemoveOutlined';

// import { ZoomControllerProps } from '../types';
interface ZoomControllerProps {
  noSubdrawer?: boolean;
}

const drawerWidth = 300;

const ZoomWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'noSubdrawer',
})<ZoomControllerProps>(({ noSubdrawer }) => ({
  position: 'absolute',
  bottom: 70,
  right: `calc(16px + ${drawerWidth}px)`,
  ...(noSubdrawer && { right: '20px' }),
}));

const SlideContainer = styled('div')(({ theme }) => ({
  borderRadius: '8px',
  backgroundColor: theme.palette.darkGrey[80],
  color: theme.palette.darkGrey[10],
}));

const LabelController = styled('div')({
  color: '#7292FD',
  cursor: 'pointer',
  '&:hover': { textDecoration: 'underline' },
});

const ZoomButton = styled(Button)({
  width: '42px',
  height: '32px',
  borderRadius: '8px',
  minWidth: 'inherit',
  color: 'white',
  '&:hover': { backgroundColor: '#587EFC' },
});

const ZoomToggleButton = styled(ToggleButton)({
  height: '32px',
  border: 'none',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '16px',
  color: 'white',
  '&:hover': { backgroundColor: '#587EFC' },
  '&.Mui-selected': {
    '&:hover': { backgroundColor: '#587EFC' },
    backgroundColor: '#587EFC',
  },
});

export { ZoomToggleButton, ZoomButton, LabelController, SlideContainer, ZoomWrapper };

export { Tooltip, Typography, ToggleButtonGroup, styled };

export { PlusIcon, MinusIcon };
