import type { ComponentsOverrides } from '@mui/material/styles';

const styleOverrides: ComponentsOverrides['MuiAccordion'] = {
  root: {
    '&$expanded': {
      margin: 0,
    },
  },
};
export default {
  styleOverrides,
};
