/* eslint-disable @typescript-eslint/no-empty-interface */
import { PaletteOptions } from '@mui/material';

type PaletteOptionItem<T extends PropertyKey> = Record<T, React.CSSProperties['color']>;

// Global Colors
type LunitTealKey = '5' | '10' | '20' | '30' | '40' | '50' | '60' | '70' | '80' | '90';

type NeutralColorKey =
  | '0'
  | '5'
  | '10'
  | '15'
  | '20'
  | '25'
  | '30'
  | '35'
  | '40'
  | '45'
  | '50'
  | '55'
  | '60'
  | '65'
  | '70'
  | '75'
  | '80'
  | '85'
  | '90'
  | '95'
  | '96'
  | '97'
  | '100';

type YellowKey = '5' | '10' | '20';
type OrangeKey = '5' | '10' | '20';

type MagentaKey = '5' | '10' | '20' | '30' | '40' | '50' | '60' | '70' | '80' | '90' | '100';

type RedKey = '5' | '10' | '20' | '30' | '40' | '50';

type BlueKey =
  | '5'
  | '10'
  | '15'
  | '20'
  | '25'
  | '30'
  | '35'
  | '40'
  | '45'
  | '50'
  | '55'
  | '60'
  | '65'
  | '70'
  | '75';

type GreenKey = '10' | '20' | '30' | '40' | '50';

type PurpleKey = '5' | '10' | '15' | '20' | '30' | '40' | '50' | '60' | '70' | '80';

export type LunitTeal = PaletteOptionItem<LunitTealKey>;
export type NeutralColor = PaletteOptionItem<NeutralColorKey>;
export type Yellow = PaletteOptionItem<YellowKey>;
export type Orange = PaletteOptionItem<OrangeKey>;
export type Magenta = PaletteOptionItem<MagentaKey>;
export type Red = PaletteOptionItem<RedKey>;
export type Blue = PaletteOptionItem<BlueKey>;
export type Green = PaletteOptionItem<GreenKey>;
export type Purple = PaletteOptionItem<PurpleKey>;

// Histological Features
type TPSKey = 'Cutoff1' | 'Cutoff49' | 'Cutoff50' | 'PositiveTc' | 'NegativeTc';
type CPSKey =
  | 'Cutoff1'
  | 'Cutoff10'
  | 'Ca'
  | 'Cis'
  | 'Pch'
  | 'PcL'
  | 'PositiveTc'
  | 'NegativeTc'
  | 'PositiveIc'
  | 'NegativeIc';

type IOKey =
  | 'BmrkCutoff1'
  | 'BmrkCutoff20'
  | 'Inflamed'
  | 'ImmuneEx'
  | 'ImmuneDsrt'
  | 'Ca'
  | 'Cs'
  | 'Lc'
  | 'Tc';

type TumorPurityKey = 'DensityBar' | 'Ca' | 'Tc' | 'Ntc';

type HER2Key =
  | 'Ca'
  | 'Cis'
  | 'Her2_Negative'
  | 'Her2_1_Positive'
  | 'Her2_2_Positive'
  | 'Her2_3_Positive';

type ERPRKey =
  | 'Ca'
  | 'Cis'
  | 'Erpr_Negative'
  | 'Erpr_1_Positive'
  | 'Erpr_2_Positive'
  | 'Erpr_3_Positive'
  | 'Cutoff0'
  | 'Cutoff1'
  | 'Cutoff10';

export type TPS = PaletteOptionItem<TPSKey>;
export type CPS = PaletteOptionItem<CPSKey>;
export type IO = PaletteOptionItem<IOKey>;
export type TumorPurity = PaletteOptionItem<TumorPurityKey>;
export type HER2 = PaletteOptionItem<HER2Key>;
export type ERPR = PaletteOptionItem<ERPRKey>;

// Others
type OthersKey =
  | 'RowHover'
  | 'DatePickerHover'
  | 'Teal50Op20'
  | 'Teal50Op18'
  | 'Grn20Op32'
  | 'Grn30OP32';

export type Others = PaletteOptionItem<OthersKey>;

type AlertKey = 'InfoBG' | 'SuccessBG' | 'ErrorBG' | 'WarningBG';

export type Alert = PaletteOptionItem<AlertKey>;

// Analysis Status
type AnalysisStatusKey =
  | 'Uploaded'
  | 'Ready'
  | 'ReadyHover'
  | 'ReadySelected'
  | 'Inferred'
  | 'InferredHover'
  | 'InferredSelected'
  | 'Analyzing'
  | 'Analyzed'
  | 'FailedEnabled'
  | 'FailedHover'
  | 'FailedSelected'
  | 'Ready'
  | 'ReadyHover'
  | 'ReadySelected'
  | 'Inferred'
  | 'InferredHover'
  | 'InferredSelected'
  | 'Invalid'
  | 'InvalidHover'
  | 'InvalidSelected';

export type AnalysisStatus = PaletteOptionItem<AnalysisStatusKey>;

export interface LunitPalette {
  lunitTeal: LunitTeal;
  neutralGrey: NeutralColor;
  yellow: Yellow;
  orange: Orange;
  magenta: Magenta;
  red: Red;
  blue: Blue;
  green: Green;
  purple: Purple;

  pdl1Tps: TPS;
  pdl1Cps: CPS;
  io: IO;
  tumorPurity: TumorPurity;
  her2: HER2;
  erpr: ERPR;

  others: Others;
  alert: Alert;
  /**
   * @todo The dot, text colors for Analysis Status have not yet been determined.
   * We will be updated once they are decided in the all-in-one plan. (hjkwon)
   */
  analysisStatus: AnalysisStatus;
}

declare module '@mui/material/styles' {
  interface Palette extends LunitPalette {}
  interface PaletteOptions extends LunitPalette {}
}

const palette: PaletteOptions = {
  brand: {
    primary: '#3BC9DB',
    secondary: '#96EDFF',
  },
  text: {
    primary: '#FFFFFF',
    secondary: '#AFAFB1',
    disabled: '#A8A8A8',
  },
  background: {},
  common: {},
  lunitTeal: {
    5: '#EAFAFF',
    10: '#96EDFF',
    20: '#30DFFC',
    30: '#3BC9DB',
    40: '#00C9EA',
    50: '#0ABEE5',
    60: '#00B3DB',
    70: '#048AB4',
    80: '#05495F',
    90: '#002930',
  },
  neutralGrey: {
    0: '#FFFFFF',
    5: '#FAFAFB',
    10: '#FAFAFA',
    15: '#F1F1F4',
    20: '#DFDFE2',
    25: '#D4D4D6',
    30: '#C6C6C8',
    35: '#C4C4C4',
    40: '#AFAFB1',
    45: '#99999B',
    50: '#7F7F81',
    55: '#737377',
    60: '#747477',
    65: '#626264',
    70: '#4E4E50',
    75: '#3E3E40',
    80: '#363B3E',
    85: '#333336',
    90: '#2D2D2F',
    95: '#1F1F21',
    96: '#1E1E1E',
    97: '#111113',
    100: '#000000',
  },
  yellow: {
    5: '#FAF089',
    10: '#FFE500',
    20: '#FFE600',
  },
  orange: {
    5: '#FFD9BE',
    10: '#FFB784',
    20: '#FF9B54',
  },
  magenta: {
    5: '#F9D1D1',
    10: '#FFC9C9',
    20: '#FAB2D3',
    30: '#FFA4B6',
    40: '#FF8787',
    50: '#FF8389',
    60: '#FB7791',
    70: '#F765A3',
    80: '#FF50A0',
    90: '#E41976',
    100: '#B20052',
  },
  red: {
    5: '#FA4D56',
    10: '#FE353F',
    20: '#FF0000',
    30: '#E63B28',
    40: '#DA1E28',
    50: '#A60D0D',
  },
  blue: {
    5: '#A5D8FF',
    10: '#A6C0D6',
    15: '#4DABF7',
    20: '#78A9FF',
    25: '#748FFC',
    30: '#587EFC',
    35: '#1971D7',
    40: '#3A5DD9',
    45: '#4F5DC5',
    50: '#443AFF',
    55: '#165BAA',
    60: '#0055AB',
    65: '#132A9B',
    70: '#034480',
    75: '#0B1354',
  },
  green: {
    10: '#73FF0C',
    20: '#66BB6A',
    30: '#42BE65',
    40: '#24A148',
    50: '#014B58',
  },
  purple: {
    5: '#BAC8FF',
    10: '#B0C0F4',
    15: '#D0BFFF',
    20: '#C378DB',
    30: '#9775FA',
    40: '#9E6BF0',
    50: '#A155B9',
    60: '#8D2FAB',
    70: '#7E41DA',
    80: '#3612A1',
  },
  pdl1Tps: {
    Cutoff1: '#5091FF',
    Cutoff49: '#0CA678',
    Cutoff50: '#F76707',
    PositiveTc: '#AD1EFF',
    NegativeTc: '#1EFCFF',
  },
  pdl1Cps: {
    Cutoff1: '#5091FF',
    Cutoff10: '#F76707',
    Ca: '#0CA678',
    Cis: '#5B8DEF',
    Pch: '#FF8989',
    PcL: '#D6C896',
    PositiveTc: '#AD1EFF',
    NegativeTc: '#1EFCFF',
    PositiveIc: '#FFEE59',
    NegativeIc: '#FF5403',
  },
  io: {
    BmrkCutoff1: '#5091FF',
    BmrkCutoff20: '#EE5140',
    Inflamed: '#C65F6E',
    ImmuneEx: '#91C4A3',
    ImmuneDsrt: '#143DDE',
    Ca: '#0CA678',
    Cs: '#91C4A3',
    Lc: '#FFF950',
    Tc: '#F76707',
  },
  tumorPurity: {
    DensityBar: '#EE5140',
    Ca: '#0CA678',
    Tc: '#F76707',
    Ntc: '#FFF950',
  },
  her2: {
    Ca: '#0CA678',
    Cis: '#5B8DEF',
    Her2_Negative: '#4B93F9',
    Her2_1_Positive: '#79FD4B',
    Her2_2_Positive: '#F8E71C',
    Her2_3_Positive: '#EF0B0B',
  },
  erpr: {
    Ca: '#0CA678',
    Cis: '#5B8DEF',
    Erpr_Negative: '#4B93F9',
    Erpr_1_Positive: '#79FD4B',
    Erpr_2_Positive: '#F8E71C',
    Erpr_3_Positive: '#EF0B0B',
    Cutoff0: '#75F0DA',
    Cutoff1: '#FD6D0D',
    Cutoff10: '#9E5EFF',
  },
  others: {
    RowHover: 'rgba(134, 148, 177, 0.08)',
    DatePickerHover: 'rgba(114, 146, 253, 0.3)',
    Teal50Op20: 'rgba(0, 201, 234, 0.2)',
    Teal50Op18: 'rgba(0, 201, 234, 0.18)',
    Grn20Op32: 'rgba(102, 187, 106, 0.32)',
    Grn30OP32: 'rgba(66, 190, 101, 0.32)',
  },
  alert: {
    InfoBG: 'rgba(55, 81, 167, 0.92)',
    SuccessBG: 'rgba(0, 137, 159, 0.92)',
    ErrorBG: 'rgba(116, 25, 15, 0.9)',
    WarningBG: 'rgba(209, 188, 0, 0.7)',
  },
  analysisStatus: {
    Uploaded: '#99B8D1',
    Analyzing: '#AA99EC',
    Analyzed: '#8DCEC3',
    FailedEnabled: '#CA1414',
    FailedHover: '#FF8787',
    FailedSelected: '#A60D0D',
    Ready: '#00D1FF',
    ReadyHover: '#38E3FF',
    ReadySelected: '#006379',
    Inferred: '#3E63DD',
    InferredHover: '#46BCFF',
    InferredSelected: '#273E89',
    Invalid: '#838389',
    InvalidHover: '#A7A7A7',
    InvalidSelected: '#6E6E74',
  },
  info: { main: '#748FFC' },
  success: { main: '#00C9EA' },
  error: { main: '#FA4D56' },
  warning: { main: '#FFE600' },
};

export default palette;
