import { hexToRgbVector } from './func';

class WebGLTile {
  public x;

  public y;

  public w;

  public h;

  private color;

  private data;

  private pCoordBuffer;

  constructor(x: number, y: number, w: number, h: number, color: string, data: number[]) {
    this.x = x;
    this.y = y;
    this.w = w;
    this.h = h;
    this.color = color;
    this.data = data;
    this.pCoordBuffer = new Float32Array(data);
  }

  get rgba() {
    return hexToRgbVector(this.color);
  }

  get coordBuffer() {
    return this.pCoordBuffer;
  }
}

export default WebGLTile;
