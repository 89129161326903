// eslint-disable-next-line @typescript-eslint/naming-convention
export enum API_TARGETS {
  'production' = 'production',
  'qe' = 'qe',
  'dev' = 'dev',
}

export const DEFAULT_API_TARGET = (() => {
  switch (process.env.REACT_APP_VERCEL_ENV) {
    case 'production':
      return API_TARGETS.production;
    case 'qe':
      return API_TARGETS.qe;
    case 'dev':
      return API_TARGETS.dev;
    default:
      return API_TARGETS.dev;
  }
})();

const env = {
  isProduction: process.env.NODE_ENV === 'production',
  isOnPrem: process.env.REACT_APP_IS_ON_PREM === 'true',
  SENTRY_CONFIG_URL: process.env.REACT_APP_SENTRY_DSN,
  API_URLS: {
    [API_TARGETS.production]: 'https://tiler.researchscope.api.scope.lunit.io',
    [API_TARGETS.qe]: 'https://tiler.main.qe.researchscope.api.scope.lunit.io',
    [API_TARGETS.dev]: 'https://tiler.staging.researchscope.api.scope.lunit.io',
  },

  API_SLIDE_URLS: {
    [API_TARGETS.production]: 'https://tiler-cf.researchscope.api.scope.lunit.io',
    [API_TARGETS.qe]: 'https://tiler-cf.main.qe.researchscope.api.scope.lunit.io',
    [API_TARGETS.dev]: 'https://tiler-cf.staging.researchscope.api.scope.lunit.io',
  },
  // npm expects versions following the SemVer 60 syntax,
  // and that syntax doesn’t allow for 4 digits.
  // So the last digits connected with '-' should be replace \w '.' to make it 4 digits of format.

  // version code commenting to get rid of the 'undefined replace' error @sh.bae
  // version: process.env.REACT_APP_VERSION.replace("-", "."),
};

export default env;
