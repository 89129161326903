import { atom } from 'recoil';

export const slideOpenAtom = atom<boolean>({
  key: 'slideOpen',
  default: false,
});

export const isVisCompleteAtom = atom<boolean>({
  key: 'isVisComplete',
  default: false,
});

export const isLoadingDataAtom = atom<boolean>({
  key: 'isLoadingData',
  default: false,
});
