import { useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { getComment } from 'src/api/slide/queries';
import { commentAtom, isCommentLoadingAtom, slideInfoAtom } from 'src/state/slides';

const useComment = (params: URLSearchParams) => {
  const setComment = useSetRecoilState(commentAtom);
  const initialData = useRecoilValue(commentAtom);
  const slideInfo = useRecoilValue(slideInfoAtom);
  const setIsCommentLoading = useSetRecoilState(isCommentLoadingAtom);
  const prevSlideRef = useRef(params.get('slide'));
  useQuery(
    getComment.key(),
    () => {
      setIsCommentLoading(true);
      return getComment(params.get('group') || '', slideInfo, params.get('customer_code') || '');
    },
    {
      suspense: false,
      cacheTime: 3600 * 1000,
      refetchOnWindowFocus: false,
      useErrorBoundary: false,
      initialData,
      onSuccess: async (commentData) => {
        setComment(commentData);
        setIsCommentLoading(false);
      },
      onError: () => {
        setComment({ comment: '' });
        setIsCommentLoading(false);
      },
    },
  );

  useEffect(() => {
    async function getCommentData() {
      const group = params.get('group') || '';
      const slide = slideInfo;
      const customerCode = params.get('customer_code') || '';
      return getComment(group, slide, customerCode);
    }
    if (prevSlideRef.current !== params.get('slide') || '') {
      getCommentData().then((resp) => setComment(resp));
      prevSlideRef.current = params.get('slide') || '';
    }
  }, [params, slideInfo, setComment]);
};

// eslint-disable-next-line import/prefer-default-export
export { useComment };
