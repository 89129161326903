import { Theme } from '@mui/material';
import { typography } from '../../../foundations';

const baseButtonStyle = (theme: Theme, focusOutline: boolean | undefined) => {
  const mainColor = theme.palette.lunitTeal[40];

  return {
    alignItems: 'center',
    display: 'flex',
    gap: '4px',
    borderRadius: '8px',
    border: '1px solid transparent',
    textTransform: 'none' as const,
    minWidth: '36px',
    width: 'max-content',
    height: '36px',
    padding: '8px 12px',
    ...typography.body4,
    backgroundColor: mainColor,
    color: theme.palette.neutralGrey[97],
    '&:hover': {
      backgroundColor: mainColor,
      opacity: 0.8,
    },
    '&:disabled': {
      color: theme.palette.neutralGrey[97],
      backgroundColor: mainColor,
      opacity: 0.4,
    },
    '&:focus': {
      border: focusOutline ? `1px solid ${theme.palette.lunitTeal[10]}` : '1px solid transparent',
      outline: 'none' as const,
    },
    '&:focus-visible': {
      border: focusOutline ? `1px solid ${theme.palette.lunitTeal[10]}` : '1px solid transparent',
      outline: 'none' as const,
    },
  };
};

const baseSmallStyle = {
  height: '28px',
  minWidth: '28px',
  padding: '4px 8px',
};

const baseLargeStyle = {
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '22px',
  // textTransform: 'capitalize', // This causes a type error in BaseOutlinedButton :S
  height: '44px',
  minWidth: '44px',
  padding: '12px',
};

export { baseButtonStyle, baseSmallStyle, baseLargeStyle };
