import type { ComponentsOverrides } from '@mui/material/styles';
import typographyOptions from '../system/typography';

const styleOverrides: ComponentsOverrides['MuiListSubheader'] = {
  root: {
    ...typographyOptions.body6,
    background: 'none',
    padding: '2px 0 0 16px',
  },
};
export default {
  styleOverrides,
};
