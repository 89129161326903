import { Typography, TypographyProps } from '@mui/material';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { AuthPropKeys } from './types';

type AuthHeadingProps = Pick<TypographyProps, AuthPropKeys>;

const AuthHeading = forwardRef<HTMLHeadingElement, AuthHeadingProps>((props, ref) => (
  <Typography
    ref={ref}
    {...props}
    className={clsx('AuthHeading', props.className)}
    sx={{
      marginBottom: 5,
      ...props.sx,
    }}
    variant="h2"
    textAlign="center"
  />
));

AuthHeading.displayName = 'AuthHeading';

export default AuthHeading;
