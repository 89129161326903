const vertexShaderSource = `#version 300 es
  in vec2 a_position;
uniform vec2 u_resolution;
uniform vec4 u_color;
uniform vec2 u_offset;
uniform int u_pointSize;

out vec4 v_color;

  void main() {

    vec2 zoomOffset = vec2(u_offset.x, u_offset.y);
    vec2 offsetCoords = (a_position.xy - zoomOffset.xy);

    // convert the position from pixels to 0.0 to 1.0

    vec2 zeroToOne = offsetCoords.xy / u_resolution;
 
    // convert from 0->1 to 0->2
    vec2 zeroToTwo = zeroToOne * 2.0;
 
    // convert from 0->2 to -1->+1 (clip space)
    vec2 clipSpace = zeroToTwo - 1.0;

    gl_PointSize = float(u_pointSize);
    gl_Position = vec4(clipSpace.x, -clipSpace.y, 0, 1);
    v_color = u_color;
  }
  `;

const fragmentShaderSource = `#version 300 es
 precision mediump float;
 in vec4 v_color;
 out vec4 pixColor;
  
 void main()
 {

  float r = 0.0, delta = 0.0;
  vec2 cxy = 2.0 * gl_PointCoord - 1.0;
  r = dot(cxy, cxy);
  delta = fwidth(r);
  float alpha = 1.0 - smoothstep(1.0 - delta, 1.0 + delta, r);
  pixColor = vec4(v_color.r, v_color.g, v_color.b, alpha)*alpha;

 }`;

export const source = {
  vertex: vertexShaderSource,
  fragment: fragmentShaderSource,
};

export const vertexAttributeConfig = {
  size: 2, // 2 components per iteration
  normalize: false, // don't normalize the data
  stride: 0, // 0 = move forward size * sizeof(type) each iteration to get the next position
  offset: 0, // start at the beginning of the buffer
};
