import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

import { IcDownloadPdfOutlined20Px as outlined } from '../../assets';

const variants = ['outlined', ] as const;
type Variant = typeof variants[number];
type IconProps = SvgIconProps & {
  variant?: Variant;
};
const DownloadPdf = ({variant = 'outlined', ...rest}: IconProps) => {
  if (variant === 'outlined') {
    return <SvgIcon {...rest} component={ outlined } inheritViewBox />;
  }

  return null;
};

DownloadPdf.displayName = 'DownloadPdf';
DownloadPdf.variants = variants;

export default DownloadPdf;
