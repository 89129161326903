import { TextField, TextFieldProps } from '@mui/material';
import clsx from 'clsx';
import { forwardRef } from 'react';

type AuthTextFieldProps = Omit<
  TextFieldProps,
  | 'variant'
  | 'hiddenLabel'
  | 'size'
  | 'margin'
  | 'fullWidth'
  | 'multiline'
  | 'rows'
  | 'maxRows'
  | 'minRows'
>;

const AuthTextField = forwardRef<HTMLDivElement, AuthTextFieldProps>((props, ref) => (
  <TextField
    ref={ref}
    {...props}
    className={clsx('AuthTextField', props.className)}
    sx={{
      display: 'flex',
      marginBottom: 2,
      ...props.sx,
    }}
    variant="outlined"
    hiddenLabel
  />
));

AuthTextField.displayName = 'AuthTextField';

export default AuthTextField;
