import { Card as MuiCard, styled } from '@mui/material';
import type { CardProps } from './Card.types';

const BaseCard = styled(({ ...props }: CardProps) => <MuiCard {...props} />)(
  ({ theme, size = 'large', selected }) => ({
    width: size === 'small' ? '480px' : '520px',
    height: size === 'small' ? '156px' : '284px',
    cursor: 'pointer',
    borderRadius: '8px',
    backgroundColor: !selected ? theme.palette.neutralGrey[85] : theme.palette.neutralGrey[75],
    '&:hover': {
      backgroundColor: theme.palette.neutralGrey[75],
    },
  }),
);

export default BaseCard;
