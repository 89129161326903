import * as React from 'react';
import type { SVGProps } from 'react';
const SvgIcInformationSmalloutlined20Px = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M4 10a6 6 0 1 1 12 0 6 6 0 0 1-12 0Zm6-7a7 7 0 1 0 0 14 7 7 0 0 0 0-14Zm.09 6.008a.501.501 0 0 0-.18 0 .75.75 0 0 0-.66.745v3.5a.75.75 0 0 0 1.5 0v-3.5a.75.75 0 0 0-.66-.745ZM10 8a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIcInformationSmalloutlined20Px;
