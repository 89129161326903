function formatCSSSize(sizeVal: string | number | undefined) {
  if (typeof sizeVal === 'undefined') {
    return undefined;
  }
  if (typeof sizeVal === 'number') return `${sizeVal}px`;

  return sizeVal;
}

export { formatCSSSize };
