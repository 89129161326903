import { styled } from '@mui/material/styles';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';

interface ActiveIconButtonProps extends IconButtonProps {
  active?: boolean;
}

const ActiveIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'active',
})<ActiveIconButtonProps>(({ active }) => ({
  width: 36,
  height: 36,
  padding: 0,
  color: '#fff',
  ...(active && { color: '#7292FD' }),
}));

export default ActiveIconButton;
