import type { ComponentsOverrides } from '@mui/material/styles';

const styleOverrides: ComponentsOverrides['MuiTable'] = {
  root: {
    borderColor: 'rgba(134, 148, 177, 0.16)',
  },
};
export default {
  styleOverrides,
};
