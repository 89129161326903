// This file facilitates style sharing between text input and text area
import { Box, Theme, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { BaseTextFieldContainerProps, HelperMsgProps } from './BaseTextField.types';
import { formatCSSSize } from './BaseTextField.utils';

const baseTextFieldStyle = (theme: Theme) => {
  return {
    backgroundColor: theme.palette.neutralGrey[75],
    borderRadius: '8px',
    border: '1px solid',
    borderColor: 'transparent',
    color: theme.palette.neutralGrey[5],
    fontWeight: '400',
    fontSize: '14px',
    fontFamily: 'Pretendard, sans-serif',
    lineHeight: '20px',
    padding: '7px 16px',

    '&::placeholder': {
      color: theme.palette.neutralGrey[45],
    },
    '&:focus': {
      outline: 'none',
      border: '1px solid',
      borderColor: theme.palette.lunitTeal[10],
    },
    '&:hover': {
      borderColor: theme.palette.neutralGrey[45],
    },
    '&:disabled': {
      opacity: '0.8',
      pointerEvents: 'none',
    },
    '&:read-only': {
      border: `1px solid ${theme.palette.neutralGrey[70]}`,
      backgroundColor: 'transparent',
      color: theme.palette.neutralGrey[45],
      pointerEvents: 'none',
    },
  };
};

const baseTextFieldErrorStyle = (theme: Theme) => {
  return {
    borderColor: theme.palette.red[5],
  };
};

const baseTextFieldMsgStyle = (theme: Theme) => {
  return {
    width: '312px',
    maxWidth: '312px',
    minHeight: '20px',
    color: theme.palette.neutralGrey[45],
    padding: '4px',
  };
};

const baseTextFieldMsgErrorStyle = (theme: Theme) => {
  return {
    color: theme.palette.red[5],
  };
};

const BaseTextFieldBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'width',
})<BaseTextFieldContainerProps>(({ width }) => {
  return {
    width: formatCSSSize(width),
    minWidth: formatCSSSize(width) ? 'unset' : '208px',
  };
});

const BaseTextFieldContainer = styled(Box, {
  shouldForwardProp: (prop) => !['width'].includes(prop.toString()),
})<BaseTextFieldContainerProps>(({ width }) => {
  return {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: formatCSSSize(width),
    minWidth: formatCSSSize(width) ? 'unset' : '208px',
  };
});

const InputMsg = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'error',
})<HelperMsgProps>(({ theme, error }) => {
  let resultStyle = baseTextFieldMsgStyle(theme);

  if (error) {
    resultStyle = {
      ...resultStyle,
      ...baseTextFieldMsgErrorStyle(theme),
    };
  }

  return resultStyle;
});

const styles = {
  baseTextFieldStyle,
  baseTextFieldErrorStyle,
  baseTextFieldMsgStyle,
  baseTextFieldMsgErrorStyle,
};

export { styles, BaseTextFieldContainer, BaseTextFieldBox, InputMsg };
