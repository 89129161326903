/* eslint-disable no-unexpected-multiline */
import qs from 'qs';
import wretch, { WretchError } from 'wretch';
import { identity } from 'ramda';
import { camelize, decamelize } from '@ridi/object-case-converter';
import env, { DEFAULT_API_TARGET } from '../../env/index';
import accessTokenManager from './accessTokenManager';

wretch().errorType('json');

export enum ErrorMessage {
  AuthError = 'AUTH_ERROR',
  BadRequest = 'BAD_REQUEST',
  ServerError = 'SERVER_ERROR',
  NotFoundError = 'NOT_FOUND',
  TimeoutError = 'Request timed out',
  FetchError = 'Failed to fetch',
}

export enum FetchMethod {
  Get = 'get',
  Post = 'post',
  Put = 'put',
  Delete = 'delete',
  Patch = 'patch',
}

interface RequestParamType {
  method?: FetchMethod;
  path: string;
  skipJson?: boolean;
  skipAuth?: boolean; // Can only be skipped on getting JWT token
  payload?: unknown;
}

export class APIError extends Error {
  status: number;

  details: string;

  timestamp: string;

  constructor(type: ErrorMessage, error: WretchError) {
    super(type);
    this.status = error.status;
    if (error.json) {
      this.details = error.json.details;
      this.timestamp = error.json.timestamp;
    }
  }
}

let currentTarget = DEFAULT_API_TARGET;
export const setAPITarget = (target) => {
  currentTarget = target;
};

const errorHandlingWrapper = (wrapper) =>
  wrapper
    .badRequest((error: WretchError) => {
      throw new APIError(ErrorMessage.BadRequest, error);
    })
    .notFound((error: WretchError) => {
      throw new APIError(ErrorMessage.NotFoundError, error);
    })
    .unauthorized((error: WretchError) => {
      throw new APIError(ErrorMessage.AuthError, error);
    })
    .forbidden((error: WretchError) => {
      throw new APIError(ErrorMessage.AuthError, error);
    })
    .internalError((error: WretchError) => {
      throw new APIError(ErrorMessage.ServerError, error);
    })
    .timeout((err) => {
      throw new APIError(ErrorMessage.TimeoutError, err);
    })
    .fetchError((err) => {
      throw new APIError(ErrorMessage.FetchError, err);
    });

export const request = <Response = any>({
  method,
  path,
  skipJson,
  skipAuth,
  payload,
}: RequestParamType): Promise<Response> => {
  if (!skipAuth && !accessTokenManager.hasAccessTokenInStorage()) {
    throw new Error(ErrorMessage.AuthError);
  }
  const wrapper = errorHandlingWrapper(
    wretch(`${env.API_URLS[currentTarget]}/${path}`)
      .auth(skipAuth ? null : `Bearer ${accessTokenManager.getAccessToken()}`)
      .json(decamelize(payload, { recursive: true }))
      [method](),
  );
  if (skipJson) {
    return wrapper.res((res) => res.statusText);
  }
  return wrapper.json((res) =>
    camelize(res, { recursive: true, excludes: ['presigned_url_info'] }),
  );
};

export const WEB_SOCKET_URL_BASE = env.SOCKET_URLS[currentTarget];

export const DEFAULT_TOKEN_EXP_SEC = 259200;

const blobWretchWrapper = ({ method, path, payload }: RequestParamType) =>
  errorHandlingWrapper(wretch(path)[method](payload));

export const arrayBufferRequest = (requestParam: RequestParamType) =>
  blobWretchWrapper(requestParam).arrayBuffer(identity);

const qsStringfyDefaultOptions = {
  encode: true,
  indices: false,
};

export const queryString = (obj: any) => qs.stringify(obj, qsStringfyDefaultOptions);
