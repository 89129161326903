import { Tooltip as MuiTooltip, styled, tooltipClasses } from '@mui/material';
import typographyOptions from '../../foundations/typography';
import { resizeArrowBySize, setMarginByPlacement, setPaddingBySize } from './Tooltip.utils';
import type { TooltipProps } from './Tooltip.types';

const BaseTooltip = styled(
  ({ className, ref, ...props }: TooltipProps) => (
    <MuiTooltip
      {...props}
      ref={ref}
      classes={{ popper: className }}
    />
  ),
  {
    shouldForwardProp: (prop) => prop !== 'noPadding',
  },
)(({ theme, size = 'small', placement = 'bottom', noPadding }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    ...typographyOptions.body5,
    margin: '0px !important',
    padding: noPadding ? '0px !important' : setPaddingBySize(size),
    backgroundColor: theme.palette.neutralGrey[75],
    borderRadius: size === 'small' ? '4px' : '8px',
    filter:
      'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.32)) drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.4))',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.neutralGrey[75],
    margin: `${setMarginByPlacement(placement, resizeArrowBySize(size, placement))} !important`,
    width: `${resizeArrowBySize(size, placement).width}px  !important`,
    height: `${resizeArrowBySize(size, placement).height}px !important`,
  },
}));

export default BaseTooltip;
