import { styled } from '@mui/material/styles';

const DataGridContainer = styled('div')(({ theme }) => ({
  height: '100%',
  '.MuiDataGrid-root': {
    border: 'none',
    overflowY: 'hidden',
    minWidth: '1200px',
    '.MuiDataGrid-main': {
      overflow: 'hidden',
      '.MuiDataGrid-virtualScroller': { overflowX: 'hidden' },
    },
    '.MuiDataGrid-withBorder': {
      borderRight: '1px solid #3E3E40',
      padding: theme.spacing(0, 1),
      height: '40px',
      '&:last-child': { borderRight: 'none' },
    },
    '.MuiDataGrid-row': {
      '&:last-child .MuiDataGrid-cell': { borderBottom: 'none' },
      '&:hover': { backgroundColor: theme.palette.background.secondary },
      '&.Mui-selected': {
        backgroundColor: theme.palette.background.secondary,
        border: 'transparent',
        '&:hover': { backgroundColor: theme.palette.background.secondary },
      },
      '& .hover-shown': { opacity: 0 },
      '&:hover .hover-shown, & .hover-shown.menu-active': { opacity: 1 },
    },
    '.MuiDataGrid-cell': {
      borderBottom: '1px solid #3E3E40',
      padding: '0 8px',
      '&:focus, &:focus-within, &:focus-visible': { outline: 'none' },
    },
    '.MuiDataGrid-columnSeparator': { position: 'relative' },
    '.MuiDataGrid-iconSeparator': { display: 'none' },
    '.MuiDataGrid-columnHeader': { '&:focus': { outline: 'none' } },
    '.MuiDataGrid-columnHeaderTitleContainer': {
      padding: 0,
      '&:focus': { outline: 'none' },
    },
  },
  '.MuiDataGrid-columnHeaders': {
    borderBottom: '1px solid #3E3E40',
    '&:focus': { outline: 'none' },
  },
  '.MuiDataGrid-columnHeaderTitle': {
    ...theme.typography.body3,
    color: theme.palette.darkGrey[30],
  },
}));

export default DataGridContainer;
