import { MenuItem, Typography, styled } from '@mui/material';
import type { Theme } from '@mui/material';
import type { BaseDropdownItemProps } from './DropdownItem.types';

const baseDropdownItemStyle = (theme: Theme) => {
  return {
    background: theme.palette.neutralGrey[85],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderRadius: '8px',
    position: 'relative' as const,
    margin: '0 8px',
    gap: '9px',
    '&:hover': {
      background: theme.palette.neutralGrey[75],
    },
    '&.Mui-disabled': {
      background: theme.palette.neutralGrey[85],
      opacity: '1',
      color: '#C4C4C4',
      cursor: 'pointer !important',
    },
    '&.Mui-selected': {
      background: 'transparent',
      '&:hover, &.Mui-selected:hover, &.Mui-focusVisible, &.Mui-selected.Mui-focusVisible': {
        borderRadius: '8px',
        backgroundColor: theme.palette.neutralGrey[75],
      },
    },
  };
};

const padNoIconStyle = {
  paddingLeft: '45px !important',
};

const BaseDropdownItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== 'emptyIcon',
})<BaseDropdownItemProps>(({ theme, emptyIcon }) => {
  let style = baseDropdownItemStyle(theme);

  if (emptyIcon) {
    style = {
      ...style,
      ...padNoIconStyle,
    };
  }

  return style;
});

const BaseShortcutLabel = styled(Typography)(({ theme }) => {
  return {
    color: theme.palette.neutralGrey[45],
    position: 'absolute' as const,
    right: '20px',
  };
});

const KeyboardShortcut = ({ label }: { label: string }) => {
  return <BaseShortcutLabel variant="body_m1">{label}</BaseShortcutLabel>;
};

export { BaseDropdownItem, KeyboardShortcut };
