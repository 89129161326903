import { Box, BoxProps, Link, Typography } from '@mui/material';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { AuthPropKeys } from './types';

interface AuthFooterProps extends Pick<BoxProps, AuthPropKeys> {
  description?: React.ReactNode;
  manualLink?: string;
  manualText?: string;
  version?: string;
}

const AuthFooter = forwardRef<HTMLDivElement, AuthFooterProps>((props, ref) => (
  <Box
    ref={ref}
    {...props}
    className={clsx('AuthFooter', props.className)}
    sx={{ marginTop: 3, textAlign: 'center', ...props.sx }}
  >
    <Typography
      variant="body5"
      color="darkGrey.30"
    >
      {props.description}
    </Typography>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: (theme) => theme.spacing(2),
        '& > *': { margin: (theme) => theme.spacing(0, 4) },
      }}
    >
      <Typography
        variant="body5"
        color="darkGrey.30"
      >
        {props.version}
      </Typography>
      <Link
        target="_blank"
        href={props.manualLink}
      >
        {props.manualText}
      </Link>
    </Box>
  </Box>
));

AuthFooter.displayName = 'AuthFooter';

export default AuthFooter;
