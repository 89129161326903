import { DataGrid } from '@mui/x-data-grid';
import DataGridContainer from './DataTable.styled';
import { Checkbox } from '../Checkbox';
import type { DataTableProps } from './DataTable.types';

function DataTable({ rows, columns, ...otherProps }: DataTableProps) {
  return (
    <DataGridContainer>
      <DataGrid
        rows={rows}
        columns={columns}
        headerHeight={40}
        rowHeight={40}
        showColumnRightBorder
        showCellRightBorder
        disableColumnMenu
        hideFooter
        components={{
          NoRowsOverlay: () => <div />,
          BaseCheckbox: Checkbox,
        }}
        {...otherProps}
      />
    </DataGridContainer>
  );
}

export default DataTable;
