import clsx from 'clsx';
import { StyledListItemCaption } from './ListItemCaption.styled';
import { ListItemCaptionProps } from './ListItemCaption.types';

export const ListItemCaptionClassName = 'ListItemCaption';

const ListItemCaption = (props: ListItemCaptionProps) => {
  const { className, ...otherProps } = props;
  return (
    <StyledListItemCaption
      {...otherProps}
      className={clsx(ListItemCaptionClassName, className)}
    />
  );
};

export default ListItemCaption;
