import type { ComponentsOverrides } from '@mui/material/styles';
import palette from '../colors';

const styleOverrides: ComponentsOverrides['MuiAlert'] = {
  root: {
    borderRadius: '8px',
    border: '1px solid transparent',
    color: '#fff',
  },
  standardSuccess: {
    backgroundColor: 'rgba(0, 201, 234, 0.1)',
    borderColor: palette.success.main,
    '& > .MuiAlert-icon': {
      color: palette.success.main,
    },
  },
  standardError: {
    backgroundColor: 'rgba(238, 81, 64, 0.18)',
    borderColor: palette.error.main,
    '& > .MuiAlert-icon': {
      color: palette.error.main,
    },
  },
  standardWarning: {
    backgroundColor: 'rgba(255, 230, 0, 0.18)',
    borderColor: palette.warning.main,
    '& > .MuiAlert-icon': {
      color: palette.warning.main,
    },
  },
  standardInfo: {
    backgroundColor: 'rgba(114, 146, 253, 0.18)',
    borderColor: palette.scope1,
    '& > .MuiAlert-icon': {
      color: palette.scope1,
    },
  },
  action: {
    alignItems: 'center',
  },
  icon: {
    alignItems: 'center',
  },
};
export default {
  styleOverrides,
};
