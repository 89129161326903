import { Select, Typography, styled } from '@mui/material';
import type { Theme } from '@mui/material';
import type {
  BaseIconDropdownProps,
  IconDropdownProps,
  PlaceholderProps,
} from './IconDropdown.types';

const baseIconDropdownStyle = (theme: Theme) => {
  return {
    fontFamily: 'pretendard',
    fontSize: '14px',
    fontWeight: '400',
    width: '52px',
    borderRadius: '8px',
    background: 'transparent',
    border: '1px solid transparent',
    padding: '0 2px 0 0',
    height: '36px',
    color: theme.palette.neutralGrey[5],
    '& fieldset': {
      border: 'none',
    },
    '&.Mui-focused': {
      border: `1px solid transparent !important`,
    },
    '&.Mui-disabled': {
      opacity: '0.4',
    },
    '.MuiSvgIcon-root ': {
      cursor: 'pointer',
      color: theme.palette.neutralGrey[5],
      '&.Mui-disabled': {
        color: theme.palette.neutralGrey[45],
      },
    },
    '.MuiSelect-select': {
      display: 'flex',
      padding: '0 0 0 8px !important',
      width: 'fit-content',
    },
    '&:hover': {
      backgroundColor: theme.palette.neutralGrey[70],
      opacity: 0.8,
      border: `1px solid transparent`,
      '&.Mui-disabled': {
        border: '1px solid transparent',
      },
    },
  };
};

const toggledIconDropdownStyle = (theme: Theme) => {
  return {
    backgroundColor: theme.palette.neutralGrey[70],
    border: `1px solid transparent`,
  };
};

const BaseIconDropdown = styled(Select, {
  shouldForwardProp: (prop) => !['small', 'isToggled'].includes(prop.toString()),
})<BaseIconDropdownProps>(({ theme, isToggled }) => {
  let style = baseIconDropdownStyle(theme);

  if (isToggled) {
    style = {
      ...style,
      ...toggledIconDropdownStyle(theme),
    };
  }

  return style;
});

const DropdownContainer = styled('div')<IconDropdownProps>(() => {
  return {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '32px',
  };
});

const PlaceholderElem = styled(Typography)<PlaceholderProps>(({ theme, disabled }) => {
  return {
    color: theme.palette.neutralGrey[45],
    position: 'absolute' as const,
    left: '16px',
    top: '8px',
    backgroundColor: 'transparent',
    height: '30px',
    pointerEvents: 'none',
    opacity: disabled ? '0.4' : '1',
    cursor: 'pointer !important',
  };
});

const DropdownPlaceholder = ({
  placeholder,
  disabled,
}: {
  placeholder: string;
  disabled?: boolean;
}) => {
  return (
    <PlaceholderElem
      variant="body5"
      disabled={disabled}
    >
      {placeholder}
    </PlaceholderElem>
  );
};

export { BaseIconDropdown, DropdownPlaceholder, DropdownContainer };
