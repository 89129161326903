import max from 'lodash/max';
import min from 'lodash/min';
import OpenSeadragon from 'openseadragon';
import { useRecoilState } from 'recoil';

import { INITIAL_ZOOM } from 'src/const';
import { physWidthPxAtom, zoomAtom } from '../state';

function useViewerZoom() {
  const [zoomState, setZoomState] = useRecoilState(zoomAtom);
  const [physicalWidthPx, setPhysicalWidthPx] = useRecoilState(physWidthPxAtom);

  function setZoomLevel(zoom: number, refPoint: OpenSeadragon.Point | null = null) {
    const clampedZoom = min([max([zoom, 0.1]), 160]) || INITIAL_ZOOM;
    setZoomState({ zoom: clampedZoom, refPoint });
  }

  return {
    zoomState,
    setZoomLevel,
    physicalWidthPx,
    setPhysicalWidthPx,
  };
}

export default useViewerZoom;
