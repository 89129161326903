import * as React from 'react';
import type { SVGProps } from 'react';
const SvgIcShowLargeoutlined20Px = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M3.025 10.228C3.445 9.238 5.178 6 9.977 6c4.789 0 6.559 3.222 6.997 4.224a.23.23 0 0 1-.004.204C16.523 11.285 14.748 14 9.977 14c-4.782 0-6.519-2.728-6.948-3.575a.227.227 0 0 1-.004-.197ZM9.977 5C4.59 5 2.59 8.685 2.1 9.839c-.14.33-.135.706.032 1.036C2.672 11.942 4.7 15 9.977 15c5.262 0 7.325-3.039 7.885-4.112.175-.337.18-.724.033-1.063C17.386 8.662 15.349 5 9.977 5Zm-2.009 5c0-1.105.9-2 2.01-2 1.109 0 2.008.895 2.008 2s-.899 2-2.009 2c-1.11 0-2.009-.895-2.009-2Zm2.01-3a3.007 3.007 0 0 0-3.014 3c0 1.657 1.349 3 3.013 3a3.007 3.007 0 0 0 3.014-3c0-1.657-1.35-3-3.014-3Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIcShowLargeoutlined20Px;
