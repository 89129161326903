import { styled, Menu, listClasses, ListSubheader, dividerClasses } from '@mui/material';
import { forwardRef } from 'react';
import type { StyledMenuProps } from './Menu.types';

const ForwardRefMenu = forwardRef<HTMLDivElement, StyledMenuProps>(
  ({ size, className, ...props }: StyledMenuProps, ref) => (
    <Menu
      {...props}
      ref={ref}
      PopoverClasses={{ paper: className }}
    />
  ),
);

export const StyledMenu = styled(ForwardRefMenu)(({ size, theme }) => ({
  backgroundColor: theme.palette.neutralGrey[90],
  borderRadius: '8px',
  [`& > .${listClasses.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    padding: size === 'small' ? theme.spacing(3, 4) : theme.spacing(5, 7),
    gap: size === 'small' ? theme.spacing(0) : theme.spacing(2),
  },
  [`& > .${listClasses.root} > .${dividerClasses.root}`]: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: size === 'small' ? theme.spacing(-4) : theme.spacing(-7),
    marginRight: size === 'small' ? theme.spacing(-4) : theme.spacing(-7),
    width: 'auto',
  },
}));

export const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
  ...theme.typography.small_body_m1,
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'transparent',
  height: '28px',
  padding: 0,
  color: theme.palette.neutralGrey[40],
}));
