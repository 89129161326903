import * as React from 'react';
import type { SVGProps } from 'react';
const SvgIcRectangleoutlined20Px = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M1 2a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2Zm1 0v16h16V2H2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIcRectangleoutlined20Px;
