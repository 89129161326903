import { Box } from '@mui/system';
import BaseButton from './BaseButton';
import { CircularProgress } from '../CircularProgress';
import theme from '../../theme';

import { isIconButton } from './Button.utils';
import type { ButtonProps } from './Button.types';

function Button(props: ButtonProps) {
  if (isIconButton(props)) {
    const {
      icon,
      loading,
      onClick,
      disableRipple = true,
      focusOutline = false,
      ...otherProps
    } = props;

    return (
      <BaseButton
        {...otherProps}
        isIconOnly
        disableRipple={disableRipple}
        focusOutline={focusOutline}
        onClick={
          loading
            ? (e) => {
                e.preventDefault();
              }
            : onClick
        }
      >
        {loading ? (
          <CircularProgress
            circleColor={theme.palette.neutralGrey[0]}
            size={20}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
        ) : (
          icon
        )}
      </BaseButton>
    );
  }

  const {
    icon,
    label,
    size,
    focusOutline = false,
    disableRipple = true,
    loading,
    onClick,
    ...otherProps
  } = props;
  return (
    <BaseButton
      isIconOnly={false}
      variant={otherProps.variant ?? 'contained'}
      disableRipple={disableRipple}
      size={size}
      color={otherProps.color}
      focusOutline={focusOutline}
      onClick={
        loading
          ? (e) => {
              e.preventDefault();
            }
          : onClick
      }
      sx={
        loading
          ? {
              '&:not(.loadingCircle)': {
                color: 'transparent',
              },
            }
          : {}
      }
      {...otherProps}
    >
      <>
        {icon}
        {label}

        {loading && (
          <Box
            className="loadingCircle"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate3d(-50%, -50%, 0)',
            }}
          >
            <CircularProgress
              circleColor={theme.palette.neutralGrey[0]}
              size={20}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            />
          </Box>
        )}
      </>
    </BaseButton>
  );
}
export default Button;
