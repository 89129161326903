import { styled, Theme } from '@mui/material';
import { InputMsg, styles } from '../BaseTextField/BaseTextField.styled';
import type { RootTextInputProps } from './TextInput.types';

const baseTextInputStyle = (theme: Theme) => {
  return {
    ...styles.baseTextFieldStyle(theme),
    textOverflow: 'ellipsis',
  };
};

const textInputLarge = {
  padding: '11px 16px',
};

const textInputWithLeftIcon = {
  paddingLeft: '44px',
};

const BaseTextInput = styled('input', {
  shouldForwardProp: (prop) =>
    ![
      'error',
      'large',
      'showClear',
      'leftIcon',
      'width',
      'OuterBoxProps',
      'InputContainerProps',
    ].includes(prop.toString()),
})<RootTextInputProps>(({ theme, large, error, leftIcon, width }) => {
  let resultStyle = {
    ...baseTextInputStyle(theme),
    width:
      // eslint-disable-next-line no-nested-ternary
      typeof width !== 'undefined' ? (typeof width === 'number' ? `${width}px` : width) : width,
  };

  if (error) {
    resultStyle = {
      ...resultStyle,
      ...styles.baseTextFieldErrorStyle(theme),
    };
  }

  if (large) {
    resultStyle = {
      ...resultStyle,
      ...textInputLarge,
    };
  }

  if (leftIcon) {
    resultStyle = {
      ...resultStyle,
      ...textInputWithLeftIcon,
    };
  }

  return resultStyle;
});

const LeftIconContainer = styled('div')(() => {
  return {
    position: 'absolute',
    left: '0px',
    top: '0px',
    zIndex: '2',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '16px',
    paddingRight: '8px',
    pointerEvents: 'none',
  };
});
const RightIconContainer = styled('div')<{ error?: string }>(() => {
  return {
    position: 'absolute',
    right: '16px',
    top: '0px',
    zIndex: '2',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
});

const ButtonWrapper = styled('button')(({ theme }) => {
  return {
    width: '28px',
    height: '28px',
    backgroundColor: 'transparent',
    opacity: '0.8',
    border: 'none',
    color: theme.palette.neutralGrey[45],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    padding: 0,
    '&:hover': {
      backgroundColor: theme.palette.neutralGrey[70],
      cursor: 'pointer',
    },
    '&:active': {
      backgroundColor: theme.palette.neutralGrey[60],
    },
  };
});

export { BaseTextInput, InputMsg, LeftIconContainer, RightIconContainer, ButtonWrapper };
