import { Select, Typography, Box, styled } from '@mui/material';
import type { Theme } from '@mui/material';
import type { BaseDropdownProps, DropdownContainerProps, PlaceholderProps } from './Dropdown.types';

const baseDropdownStyle = (theme: Theme) => {
  return {
    fontFamily: 'pretendard',
    fontSize: '14px',
    fontWeight: '400',
    background: theme.palette.neutralGrey[85],
    borderRadius: '8px',
    border: '1px solid transparent',
    height: '36px',
    color: theme.palette.neutralGrey[5],
    padding: '0 2px',
    '.MuiSelect-select': {
      paddingRight: '0px !important',
    },
    '& fieldset': {
      border: 'none',
    },
    '&.Mui-focused': {
      border: `1px solid ${theme.palette.lunitTeal[10]} !important`,
    },
    '&.Mui-disabled': { border: 'none', background: theme.palette.neutralGrey[75] },
    '.MuiSvgIcon-root ': {
      cursor: 'pointer',
      marginRight: '16px',
      color: theme.palette.neutralGrey[5],
      '&.Mui-disabled': {
        color: theme.palette.neutralGrey[45],
      },
    },
    '&:hover': {
      border: `1px solid ${theme.palette.neutralGrey[45]}`,
      '&.Mui-disabled': {
        border: '1px solid transparent',
      },
    },
  };
};

const fixedWidthDropdownStyle = {
  width: '240px',
};

const errorDropdownStyle = (theme: Theme) => ({
  border: `1px solid ${theme.palette.red[5]} !important`,
});

const BaseDropdown = styled(Select, {
  shouldForwardProp: (prop) => prop !== 'small',
})<BaseDropdownProps>(({ theme, error, autoWidth, fullWidth, open }) => {
  let style = baseDropdownStyle(theme);
  if (!autoWidth && !fullWidth) {
    style = {
      ...style,
      ...fixedWidthDropdownStyle,
    };
  }
  if (error) {
    style = {
      ...style,
      ...errorDropdownStyle(theme),
    };
  }
  // add FocusStyle when click arrow
  const openFocusStyle = open
    ? { border: `1px solid ${theme.palette.lunitTeal[10]} !important` }
    : {};
  return { ...style, ...openFocusStyle };
});

const DropdownContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'fullWidth',
})<DropdownContainerProps>(({ fullWidth }) => {
  if (fullWidth) {
    return {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    };
  }

  return {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '240px',
  };
});

const PlaceholderElem = styled(Typography)<PlaceholderProps>(({ theme, disabled }) => {
  return {
    color: theme.palette.neutralGrey[45],
    position: 'absolute' as const,
    left: '16px',
    top: '8px',
    backgroundColor: 'transparent',
    height: '30px',
    pointerEvents: 'none',
    opacity: disabled ? '0.4' : '1',
    cursor: 'pointer !important',
  };
});

const DropdownPlaceholder = ({
  placeholder,
  disabled,
}: {
  placeholder: string;
  disabled?: boolean;
}) => {
  return (
    <PlaceholderElem
      variant="body5"
      disabled={disabled}
    >
      {placeholder}
    </PlaceholderElem>
  );
};

export { BaseDropdown, DropdownPlaceholder, DropdownContainer };
