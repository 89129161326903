import type { ComponentsOverrides } from '@mui/material/styles';

const styleOverrides: ComponentsOverrides['MuiTabs'] = {
  root: {
    minHeight: 44,
  },
};
export default {
  styleOverrides,
};
