import * as React from 'react';
import type { SVGProps } from 'react';
const SvgIcShowSmalloutlined20Px = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M10 7c3.305 0 4.591 2.345 4.975 3.276C14.593 11.042 13.312 13 10 13c-3.312 0-4.593-1.958-4.975-2.724C5.41 9.346 6.695 7 10 7Zm5.95 3.02a.722.722 0 0 1-.016.567C15.538 11.447 14.024 14 10 14c-4.023 0-5.538-2.552-5.934-3.413a.722.722 0 0 1-.016-.566C4.422 9.058 5.923 6 10 6s5.578 3.058 5.95 4.02ZM11 10a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm1 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIcShowSmalloutlined20Px;
