import { find } from 'lodash';
import { TermsOfService, UserToken } from '../../api/auth/models';
import accessTokenManager from '../../api/utils/accessTokenManager';

export const setTokens = (tokens: UserToken) => {
  accessTokenManager.setAccessToken(tokens.accessToken);
  accessTokenManager.setRefreshToken(tokens.refreshToken);
};

export const removeTokens = () => {
  accessTokenManager.removeAccessToken();
  accessTokenManager.removeRefreshToken();
};

export const logout = () => {
  removeTokens();
  window.location.href = '/login';
};

export const validateEmail = (email: string) => {
  // RFC 5322 Standard
  // eslint-disable-next-line prefer-regex-literals
  const regex = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );
  return regex.test(email);
};

export const getTermsBySeq = (terms: TermsOfService[] | undefined, targetSeq: number) => {
  if (terms === undefined) return;
  // eslint-disable-next-line consistent-return
  return find(terms, { seq: targetSeq });
};

export const getTermsDescription = (terms: TermsOfService | undefined) =>
  terms !== undefined ? terms.description : '';

export const termsWithNewLine = (description: string) =>
  description.split('\n').map((line: string, idx: number) => (
    // eslint-disable-next-line react/no-array-index-key
    <div key={`${idx}_${line}`}>
      {line}
      <br />
    </div>
  ));
