import { styled, Dialog, DialogContent, IconButton } from '@mui/material';

export const Container = styled(Dialog)({
  '& .MuiPaper-root': {
    width: 540,
    backgroundColor: '#fff',
    border: '2px solid #000',
    borderRadius: 0,
    color: '#000',
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 300,
  },
});

export const Content = styled(DialogContent)({ padding: '30px' });

export const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: '30px',
  top: '30px',
  padding: 0,
  color: '#000',
  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.darkGrey[60],
  },
}));
