import { CircularProgress as MuiCircularProgress, styled } from '@mui/material';
import type { CircularProgressProps } from './CircularProgress.types';

const BaseCircularProgress = styled(MuiCircularProgress, {
  shouldForwardProp: (prop) => prop !== 'circleColor',
})<CircularProgressProps>(({ theme, circleColor }) => ({
  color: circleColor || theme.palette.lunitTeal[40],
}));

export default BaseCircularProgress;
