import { Coord, TaskClass } from '../../types';

const getAreaColor = (color: string): string => {
  if (!color) return 'rgba(127, 127, 127, 0.8)';
  const hexColor = color;
  const r = Number.parseInt(hexColor.substr(1, 2), 16);
  const g = Number.parseInt(hexColor.substr(3, 2), 16);
  const b = Number.parseInt(hexColor.substr(5, 2), 16);
  return `rgba(${r}, ${g}, ${b}, 0.8)`;
};

export default class AreaList {
  coordsArray: Array<Array<Coord>>;

  classId: string;

  color: string;

  constructor(coordsArray: Array<Array<Coord>>, cls: TaskClass) {
    this.coordsArray = coordsArray;
    this.classId = cls.id;
    this.color = cls.color;
  }

  draw(ctx: CanvasRenderingContext2D): void {
    ctx.lineWidth = 20;
    ctx.strokeStyle = getAreaColor(this.color);
    this.coordsArray.forEach((coords) => {
      const topLeft = coords[0];
      const bottomRight = coords[1];
      if (!topLeft || !bottomRight) return;
      ctx.strokeRect(topLeft.x, topLeft.y, bottomRight.x - topLeft.x, bottomRight.y - topLeft.y);
    });
  }

  destroy(): void {
    this.coordsArray = [];
  }
}
