import type { ComponentsOverrides } from '@mui/material/styles';
import palette from '../colors';

const styleOverrides: ComponentsOverrides['MuiPaper'] = {
  root: {
    backgroundColor: palette.background.secondary,
  },
  elevation1: {
    backgroundImage: 'none',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.36), 0px 6px 12px rgba(0, 0, 0, 0.48)',
  },
  elevation2: {
    backgroundImage: 'none',
    boxShadow: '0px 12px 24px 8px rgba(0, 0, 0, 0.36), 0px 20px 44px 3px rgba(0, 0, 0, 0.48)',
  },
};
export default {
  styleOverrides,
};
