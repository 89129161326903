import { CheckedIcon, ExpandMoreIcon } from '@lunit/scope-icons';
import React, { forwardRef, MouseEventHandler, useState } from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';
import { SxProps, Theme } from '@mui/system';
import MuiMenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import map from 'lodash/map';
import find from 'lodash/find';
import { useHotkeys } from 'react-hotkeys-hook';

export interface HeaderButtonMenuItem {
  id: string;
  title: string;
  icon?: React.ReactNode;
  shortcut?: string;
}

export interface HeaderButtonProps extends IconButtonProps {
  hoverMode?: 'color' | 'bg';
  selected?: boolean;
  hasMenu?: boolean;
  shortcut?: string;
  menuItems?: HeaderButtonMenuItem[];
  selectedMenuId?: string;
  tooltip?: React.ReactNode;
  onMenuClick?: (menuId: string) => void;
  onShortcutPress?: () => void;
}

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'hasMenu',
})<HeaderButtonProps>(({ hasMenu }) => ({
  paddingTop: 14,
  paddingBottom: 14,
  paddingLeft: hasMenu ? 8 : 10,
  paddingRight: hasMenu ? 20 : 10,
  minHeight: 48,
  maxHeight: 48,
  borderRadius: 0,
  position: 'relative',
}));

function hoverStyle(hoverMode: HeaderButtonProps['hoverMode'], selected: boolean): SxProps<Theme> {
  switch (hoverMode) {
    case 'color':
      return {
        color: (theme) => (selected ? theme.palette.primary.main : theme.palette.darkGrey[15]),
        '&:hover': {
          backgroundColor: 'transparent',
          color: (theme) => theme.palette.primary.main,
        },
      };
    case 'bg':
      return {
        color: (theme) => theme.palette.darkGrey[15],
        backgroundColor: (theme) => (selected ? theme.palette.primary.main : undefined),
        '&:hover': { backgroundColor: (theme) => theme.palette.primary.main },
      };
    default:
  }
  return {};
}

const DropdownIcon = styled(ExpandMoreIcon)({
  position: 'absolute',
  right: 5,
  '& path': {
    fill: 'none',
    stroke: 'currentColor',
  },
});

interface MenuItemProps {
  menuItem: HeaderButtonMenuItem;
  selectedMenuId: string;
  onMenuClick?: (menuId: string) => void;
  handleMenuClose: () => void;
}

function MenuItem({ menuItem, selectedMenuId, onMenuClick, handleMenuClose }: MenuItemProps) {
  useHotkeys(
    menuItem.shortcut || ' ',
    (keyboardEvent) => {
      keyboardEvent.preventDefault();
      if (onMenuClick) onMenuClick(menuItem.id);
      handleMenuClose();
    },
    { enabled: !!menuItem.shortcut },
    [onMenuClick, handleMenuClose, menuItem],
  );
  return (
    <MuiMenuItem
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
      onClick={() => {
        if (onMenuClick) onMenuClick(menuItem.id);
        handleMenuClose();
      }}
      key={menuItem.id}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <CheckedIcon style={{ opacity: selectedMenuId === menuItem.id ? 1 : 0 }} />
        <Box sx={{ marginLeft: 1, display: 'flex' }}>{menuItem.icon}</Box>
        <Typography
          sx={{ marginLeft: 1, marginRight: 1 }}
          variant="body5"
        >
          {menuItem.title}
        </Typography>
      </Box>
      {menuItem.shortcut && (
        <Typography
          color="darkGrey.30"
          variant="body4"
        >
          {menuItem.shortcut}
        </Typography>
      )}
    </MuiMenuItem>
  );
}

const HeaderButton = forwardRef<HTMLButtonElement, HeaderButtonProps>(
  (props: HeaderButtonProps, ref) => {
    const {
      hoverMode = 'color',
      selected = false,
      menuItems = [],
      selectedMenuId = menuItems[0] && menuItems[0].id,
      shortcut,
      onMenuClick,
      onShortcutPress,
      tooltip,
      ...iconButtonProps
    } = props;
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const menuOpen = Boolean(anchorEl);
    const sx = { ...hoverStyle(hoverMode, selected || menuOpen), ...props.sx };
    const selectedMenuItem = find(menuItems, (menuItem) => menuItem.id === selectedMenuId);
    useHotkeys(
      shortcut || ' ',
      (keyboardEvent) => {
        keyboardEvent.preventDefault();
        if (onShortcutPress) onShortcutPress();
      },
      { enabled: !!shortcut && !iconButtonProps.disabled },
      [onShortcutPress],
    );

    if (iconButtonProps.hasMenu) {
      const handleClose = () => {
        setAnchorEl(null);
      };

      const handleOpen: MouseEventHandler<HTMLButtonElement> = (e) => {
        if (menuItems.length > 0) {
          setAnchorEl(e.currentTarget);
        }
        if (props.onClick) props.onClick(e);
      };

      const buttonEl = (
        <StyledIconButton
          ref={ref}
          {...iconButtonProps}
          sx={sx}
          disableRipple
          onClick={handleOpen}
        >
          {props.children || (selectedMenuItem && selectedMenuItem.icon)}
          <DropdownIcon />
        </StyledIconButton>
      );

      const menuEl = (
        <Menu
          anchorEl={anchorEl}
          open={menuOpen}
          keepMounted
          onClose={handleClose}
          PaperProps={{
            style: {
              minWidth: 280,
              marginTop: 4,
            },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {map(menuItems, (menuItem, idx) =>
            menuItem.id !== 'divider' ? (
              <MenuItem
                key={menuItem.id}
                menuItem={menuItem}
                handleMenuClose={handleClose}
                selectedMenuId={selectedMenuId}
                onMenuClick={onMenuClick}
              />
            ) : (
              <Divider
                sx={{ marginTop: 1.5, marginBottom: 1.5 }}
                key={`${menuItem.id}_${idx}`}
              />
            ),
          )}
        </Menu>
      );
      if (tooltip && !iconButtonProps.disabled) {
        return (
          <>
            <Tooltip title={!menuOpen ? tooltip : ''}>{buttonEl}</Tooltip>
            {menuItems.length > 0 && menuEl}
          </>
        );
      }
      return buttonEl;
    }
    const buttonEl = (
      <StyledIconButton
        ref={ref}
        {...iconButtonProps}
        sx={sx}
        disableRipple
      />
    );
    if (tooltip && !iconButtonProps.disabled) {
      return <Tooltip title={tooltip}>{buttonEl}</Tooltip>;
    }
    return buttonEl;
  },
);

export default HeaderButton;
