import KDBush from 'kdbush';
import { Coord, TaskClass } from '../../types';

type Rect = { x: number; y: number; width: number; height: number };

const POINT_SIZE = 70;

const getCellColor = (color: string): string => {
  if (!color) return 'rgb(127,127,127)';
  const hexColor = color;
  const r = Number.parseInt(hexColor.substr(1, 2), 16);
  const g = Number.parseInt(hexColor.substr(3, 2), 16);
  const b = Number.parseInt(hexColor.substr(5, 2), 16);
  return `rgb(${r}, ${g}, ${b})`;
};

export default class CellList {
  coordsArray: Array<Coord>;

  classId: string;

  color: string;

  mppScaleFactor: number;

  cellsFilled: boolean;

  cellLineWidth: number;

  indexedPoints: KDBush<Coord> | null;

  constructor(
    coordsArray: Array<Coord>,
    cls: TaskClass,
    mppScaleFactor: number,
    cellsFilled: boolean,
    cellLineWidth: number,
  ) {
    this.classId = cls.id;
    this.color = cls.color;
    this.coordsArray = coordsArray;
    this.mppScaleFactor = mppScaleFactor;
    this.cellsFilled = cellsFilled;
    this.cellLineWidth = cellLineWidth;
    this.indexedPoints = new KDBush(
      this.coordsArray,
      (p) => p.x,
      (p) => p.y,
      64,
      // @ts-ignore
      Uint32Array,
    );
  }

  draw(ctx: CanvasRenderingContext2D, zoom: number, bounds: Rect): void {
    const pointSize = (POINT_SIZE * this.mppScaleFactor) / Math.sqrt(zoom);
    const { cellsFilled } = this;
    const cellLineWidth = (POINT_SIZE * this.cellLineWidth) / Math.sqrt(zoom);
    ctx.fillStyle = getCellColor(this.color);
    ctx.strokeStyle = getCellColor(this.color);
    ctx.lineWidth = cellLineWidth;
    if (this.indexedPoints) {
      this.indexedPoints
        .range(
          bounds.x - pointSize * 2,
          bounds.y - pointSize * 2,
          bounds.x + bounds.width + pointSize * 2,
          bounds.y + bounds.height + pointSize * 2,
        )
        .forEach((id: number) => {
          const coords = this.coordsArray[id];
          ctx.beginPath();
          ctx.arc(coords.x, coords.y, pointSize, 0, 2 * Math.PI);
          if (cellsFilled) {
            ctx.fill();
          } else {
            ctx.stroke();
          }
        });
    }
  }

  destroy(): void {
    this.coordsArray = [];
    this.indexedPoints = null;
  }
}
