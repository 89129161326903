import React from 'react';

export default (WrappedComponent: any) =>
  class ErrorBoundary extends React.Component<any> {
    // eslint-disable-next-line react/state-in-constructor
    state = { hasError: false };

    componentDidCatch(error: any) {
      // Display fallback UI
      this.setState({ hasError: true });
      // You can also log the error to an error reporting service
      // eslint-disable-next-line no-console
      console.log(error);
    }

    render() {
      const { hasError } = this.state;
      if (hasError) {
        // You can render any custom fallback UI
        return <h1>Something went wrong.</h1>;
      }
      return <WrappedComponent {...this.props} />;
    }
  };
