import { Typography, styled } from '@mui/material';

const BaseDropdownSubtitle = styled(Typography)(({ theme }) => {
  return {
    color: theme.palette.neutralGrey[40],
    width: '140px',
    height: '20px',
    padding: '8px',
    paddingLeft: '16px',
  };
});

export default BaseDropdownSubtitle;
