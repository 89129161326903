import { Paper, Toolbar, Collapse } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  overflow: 'hidden',
  borderRadius: '10px',
  width: '500px',
  backgroundColor: theme.palette.neutralGrey[90],
}));

const StyledToolbar = styled(Toolbar)({
  padding: '20px 40px 20px 32px',
  paddingRight: '40px !important',
  paddingLeft: '32px !important',
  color: '#fff',
  minHeight: 'unset !important',
});

const StyledToolbarButtonContainer = styled(Toolbar)({
  position: 'absolute',
  display: 'flex',
  gap: '12px',
  right: '30px',
  paddingRight: '4px !important',
});

const StyledCollapse = styled(Collapse)({
  maxHeight: '320px',
  overflowY: 'auto',
  overflowX: 'hidden',
});

const StyledUploadFile = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '16px',
  alignItems: 'center',
  padding: '20px 30px 20px 32px',
  paddingRight: '30x !important',
  paddingLeft: '32px !important',
});

export {
  StyledPaper,
  StyledToolbar,
  StyledToolbarButtonContainer,
  StyledCollapse,
  StyledUploadFile,
};
