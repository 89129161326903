import { useContext, useEffect, useRef, useState } from 'react';
import StyledTypography from './EllipsisTypography.styled';
import type { EllipsisTypographyProps } from './EllipsisTypography.types';
import { Tooltip } from '../Tooltip';
import { ResizeObserverContext } from '../ResizeObserver/ResizeObserverContext';

function EllipsisTypography({
  children,
  heightThreshold: heightThresholdProp,
  tooltipPlacement = 'bottom',
  maxLines = 1,
  ...otherProps
}: EllipsisTypographyProps) {
  const typographyRef = useRef<HTMLElement>(null);
  const direction = otherProps.direction || 'row';
  const heightThreshold = heightThresholdProp != null ? heightThresholdProp : 1;
  const [showTooltip, setShowTooltip] = useState(false);
  const { addResizeHandler, removeResizeHandler } = useContext(ResizeObserverContext);

  useEffect(() => {
    if (typographyRef.current) {
      const target: Element = typographyRef.current;
      const resizeHandler = () => {
        switch (direction) {
          case 'row':
            if (target.scrollHeight - target.clientHeight > heightThreshold && !showTooltip) {
              setShowTooltip(true);
            } else if (
              target.scrollHeight - target.clientHeight <= heightThreshold &&
              showTooltip
            ) {
              setShowTooltip(false);
            }
            break;
          case 'column':
          default:
            if (target.scrollHeight - target.clientHeight > heightThreshold && !showTooltip) {
              setShowTooltip(true);
            } else if (
              target.scrollHeight - target.clientHeight <= heightThreshold &&
              showTooltip
            ) {
              setShowTooltip(false);
            }
        }
      };

      addResizeHandler(target, resizeHandler);
      return () => removeResizeHandler(target);
    }
    return () => {};
  }, [
    typographyRef,
    direction,
    showTooltip,
    heightThreshold,
    addResizeHandler,
    removeResizeHandler,
  ]);

  if (showTooltip) {
    return (
      <Tooltip
        title={children}
        placement={tooltipPlacement}
        size="small"
      >
        <StyledTypography
          {...otherProps}
          ref={typographyRef}
          direction={direction}
          maxLines={maxLines}
        >
          {children}
        </StyledTypography>
      </Tooltip>
    );
  }
  return (
    <StyledTypography
      {...otherProps}
      ref={typographyRef}
      direction={direction}
      maxLines={maxLines}
    >
      {children}
    </StyledTypography>
  );
}

export default EllipsisTypography;
