/**
 * Project information
 */

import { format } from 'date-fns';
import { FetchMethod } from '../utils/apiRequestHelper';
import { FetchStatus } from '../utils/commonEnums';
import { FetchStatusResult, Paging } from '../utils/commonModels';
import { DATE_FORMAT, SERVER_DATE_FORMAT } from '../../utils/format';
// eslint-disable-next-line import/no-cycle
import { SlideUUID } from '../slide/models';

export type ProjectUUID = number;

export interface ProjectDtoInput {
  title: string;
  description?: string;
  fromDate?: string;
  toDate?: string;
}

export interface ProjectDtoOutput {
  id: ProjectUUID;
  title: string;
  description?: string;
  fromDate?: string;
  toDate?: string;
  slideCount?: number;
}

export interface APIGetProjectsParams {
  sortBy?: string;
  sortOrder?: string;
  title?: string;
}

export interface APIGetProjectsPerPageParams {
  page: number;
  size: number;
  sort?: string[];
  title?: string;
}

export type APICreateProjectParams = ProjectDtoInput;

export interface APIDeleteProjectsParams {
  ids: ProjectUUID[];
}

export interface APIUpdateProjectParams extends ProjectDtoInput {
  id?: ProjectUUID;
}

export interface APIProjectBulkMappingDeleteParams {
  id: ProjectUUID;
  targets: SlideUUID[];
}

export interface Project extends Omit<ProjectDtoOutput, 'id'> {
  id?: ProjectDtoOutput['id'];
}

export type ProjectBase = Pick<Project, 'id' | 'title' | 'description'>;

export interface ProjectFormValues extends ProjectBase {
  dateRange?: [Project['fromDate'], Project['toDate']];
}

export interface ProjectRequestValues extends ProjectDtoInput {
  id?: ProjectDtoOutput['id'];
}

export function createProjectFormValues(project: Project): ProjectFormValues {
  return {
    id: project.id,
    title: project.title,
    description: project.description,
    dateRange: [
      project.fromDate && format(new Date(project.fromDate), DATE_FORMAT),
      project.toDate && format(new Date(project.toDate), DATE_FORMAT),
    ],
  };
}

export function createProjectRequestValues(values: ProjectFormValues): ProjectRequestValues {
  return {
    id: values.id,
    title: values.title,
    description: values.description,
    fromDate:
      values.dateRange &&
      values.dateRange[0] &&
      format(new Date(values.dateRange[0]), SERVER_DATE_FORMAT),
    toDate:
      values.dateRange &&
      values.dateRange[1] &&
      format(new Date(values.dateRange[1]), SERVER_DATE_FORMAT),
  };
}

// add pagination later?
export interface AllProjectsState {
  fetchStatus: FetchStatus;
  list: Project[];
}

export interface ProjectListRequestParams {
  page?: number;
  title?: string;
}

export interface ProjectListState {
  fetchStatus: FetchStatus;
  pagination: Paging;
  list: Project[];
}

export interface ProjectMgmtState extends FetchStatusResult {
  createdProject?: Project;
  currentMethod: FetchMethod;
  loading: boolean;
  success: boolean;
}

export interface ProjectsState {
  allProjects: AllProjectsState;
  projectList: ProjectListState;
  projectMgmt: ProjectMgmtState;
}
