/* eslint-disable no-alert */
import { useEffect, useMemo } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { SkeletonTheme } from 'react-loading-skeleton';
import { PageRoot } from '@lunit/oncology-components';
import Link from '@mui/material/Link';

import { useRecoilValue } from 'recoil';
import { projectsAtom } from './state/projects';
import Login from './pages/auth/Login';
import Visualizer from './pages/visualizer/index';
import Projects from './pages/projects/Projects';
import ForgotPassword from './pages/auth/ForgotPassword';
import withAuthGuard from './hoc/withAuthGuard';

import ProfileAvatarButton from './components/common/Toolbar/ProfileAvatar/ProfileAvatarButton';
import AppListButton from './components/common/Toolbar/AppList/AppListButton';

import './App.css';
import SlideList from './pages/visualizer/component/SlideList';
import { useSlides } from './hooks/useSlideQueries';
import { supportsOffscreenCanvas } from './components/utils';
import useOnlineStatus from './hooks/useOnlineStatus';
import useSnackbar from './hooks/useSnackbar';
import ProductLabelButton from './components/common/Toolbar/ProductLabels/ProductLabelButton';

function Dashboard() {
  const projects = useRecoilValue(projectsAtom);
  const location = useLocation();
  const params = new URLSearchParams(window.location.search);

  const enableGetSlide = location.pathname.includes('/visualizer');
  const { data: slideData, isError, error } = useSlides(params, enableGetSlide);

  useEffect(() => {
    if (isError && error) {
      if (error) {
        if ((error as Error).message.includes('AUTH_ERROR')) {
          window.location.replace('/login');
        }
        if (projects.projects.length > 1) {
          window.alert(
            `You cannot access this resource. You either don't have permission to this resource or have the wrong group code.
You will now be redirect to your dashboard.`,
          );
        } else {
          window.alert(
            "You cannot access this resource. You either don't have permission to this resource or have the wrong group code.",
          );
        }
        window.location.replace('/');
      } else {
        window.alert('Woops, something went wrong.');
      }
    }
  }, [error, isError, projects.projects.length]);

  return (
    <PageRoot
      headerDrawerButtonOnLeft
      homeLink={
        <Link
          href="/"
          style={{ paddingLeft: 4 }}
        >
          <img
            alt="lunit-scope"
            src="/scope-w.svg"
            height="23px"
          />
        </Link>
      }
      drawerExtraContent={
        slideData &&
        location.pathname.includes('/visualizer') && <SlideList slides={slideData.results} />
      }
      rightContainer={
        <>
          <ProfileAvatarButton />
          <AppListButton />
          <ProductLabelButton />
        </>
      }
    >
      <Routes>
        <Route
          index
          element={<Projects />}
        />
        <Route
          path="visualizer"
          element={
            slideData && (
              <Visualizer
                results={slideData.results}
                labels={slideData.labels}
              />
            )
          }
        />
      </Routes>
    </PageRoot>
  );
}

const GuardedDashboard = withAuthGuard(Dashboard);

function App() {
  const isOnline = useOnlineStatus();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const checkedOffscreen = useMemo(() => supportsOffscreenCanvas(), []);

  useEffect(() => {
    let rcVersion = '';
    rcVersion = process.env['REACT_APP_RC_VERSION']
      ? `.rc${process.env['REACT_APP_RC_VERSION']}`
      : '';
    // }
    // eslint-disable-next-line no-console
    console.log(`
███████╗ ██████╗ ██████╗ ██████╗ ███████╗    ██████╗ ███████╗███████╗███████╗ █████╗ ██████╗  ██████╗██╗  ██╗
██╔════╝██╔════╝██╔═══██╗██╔══██╗██╔════╝    ██╔══██╗██╔════╝██╔════╝██╔════╝██╔══██╗██╔══██╗██╔════╝██║  ██║
███████╗██║     ██║   ██║██████╔╝█████╗      ██████╔╝█████╗  ███████╗█████╗  ███████║██████╔╝██║     ███████║
╚════██║██║     ██║   ██║██╔═══╝ ██╔══╝      ██╔══██╗██╔══╝  ╚════██║██╔══╝  ██╔══██║██╔══██╗██║     ██╔══██║
███████║╚██████╗╚██████╔╝██║     ███████╗    ██║  ██║███████╗███████║███████╗██║  ██║██║  ██║╚██████╗██║  ██║
╚══════╝ ╚═════╝ ╚═════╝ ╚═╝     ╚══════╝    ╚═╝  ╚═╝╚══════╝╚══════╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝ ╚═════╝╚═╝  ╚═╝

    v${process.env['REACT_APP_COMPONENT_VERSION']}${rcVersion}
    `);
  }, []);

  useEffect(() => {
    if (!isOnline) {
      enqueueSnackbar('Connection unavailable', {
        variant: 'error',
        preventDuplicate: true,
        persist: true,
      });
    } else closeSnackbar();
  }, [enqueueSnackbar, closeSnackbar, isOnline]);

  useEffect(() => {
    if (!checkedOffscreen) {
      enqueueSnackbar(
        'Please use your Google Chrome browser on PC with 16GB memory (RAM)',
        {
          persist: true,
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
        },
        {
          children: (
            <Link
              variant="body5"
              color="inherit"
              sx={{ textDecoration: 'underline !important' }}
              href="https://www.google.com/chrome/?brand=BNSD&gclid=Cj0KCQjwktKFBhCkARIsAJeDT0jQQUll7N8dzF5yValx0o3UIBAAXsX52h__0fwCQAD8XRSKXvAiZLoaAjlSEALw_wcB&gclsrc=aw.ds"
            >
              Download Google Chrome
            </Link>
          ),
        },
      );
    }
  }, [checkedOffscreen, enqueueSnackbar, closeSnackbar]);

  if (!checkedOffscreen) {
    return null;
  }

  return (
    <SkeletonTheme
      baseColor="#626264"
      highlightColor="#7F7F81"
    >
      <BrowserRouter>
        <Routes>
          <Route
            path="login"
            element={<Login />}
          />
          <Route
            path="forgot-password"
            element={<ForgotPassword />}
          />
          <Route
            path="/*"
            element={<GuardedDashboard />}
          />
        </Routes>
      </BrowserRouter>
    </SkeletonTheme>
  );
}

export default App;
