import AreaList from 'src/pages/visualizer/component/Classes/AreaList';
import CellList from 'src/pages/visualizer/component/Classes/BoundedCellList';
import ContourList from 'src/pages/visualizer/component/Classes/ContourList';
import OffscreenImageMask from 'src/pages/visualizer/component/Classes/OffscreenImageMask';
import { DataList, SlideData, TaskClass, TaskClasses } from 'src/pages/visualizer/types';

export function assembleAreaList(areaData: any, taskClasses: TaskClasses) {
  return areaData.map((data: any) => {
    const cls: TaskClass = taskClasses[data.labelId];
    if (!cls) {
      throw new Error('invalid labelId');
    }
    return new AreaList(data.groups, cls);
  });
}

export function assembleContourList(contourData: any, taskClasses: TaskClasses) {
  return contourData
    .map((data: any) => {
      const cls: TaskClass = taskClasses[data.labelId];
      if (!cls) {
        console.warn(`labelId ${data.labelId} is not exists in taskClasses. skipping...`);
        return false;
      }
      const coordinates = data.xCoordinates.map((xCoords: number[]) =>
        xCoords.map((xCoord: number) => ({ x: xCoord })),
      );
      coordinates.forEach((coords: any[], outerIdx: number) =>
        coords.map(
          // eslint-disable-next-line no-return-assign
          (_, innerIdx: number) =>
            (coordinates[outerIdx][innerIdx].y = data.yCoordinates[outerIdx][innerIdx]),
        ),
      );
      return new ContourList(coordinates, cls);
    })
    .filter((item: any) => !!item);
}

export function assembleCellList(cellData: any, taskClasses: TaskClasses) {
  const mppScaleFactor = 1;
  const cellsFilled = true;
  const cellLineWidth = 1;

  return cellData
    .map((data: any) => {
      const cls = taskClasses[data.labelId] as TaskClass;
      if (!cls) {
        console.warn(`labelId ${data.labelId} is not exists in taskClasses. skipping...`);
        return false;
      }
      const coordinates = data.xCoordinates.map((coord: number, id: number) => ({
        x: coord,
        y: data.yCoordinates[id],
      }));
      return new CellList(coordinates, cls, mppScaleFactor, cellsFilled, cellLineWidth);
    })
    .filter((item: any) => !!item);
}

export function assembleTissueData(tissueDataCandidate: any, taskClasses: TaskClasses) {
  return [
    // For image type
    ...tissueDataCandidate
      .map((data: DataList, idx: number) =>
        data.labelIds
          ? [
              ...data.labelIds.map((id) => {
                const cls = taskClasses[id] as TaskClass;
                if (!cls) {
                  // This will generate a fake task class item.
                  // It won't be shown up on the right panel
                  // but still necessary not to modify the original array.length
                  // since their position is calculated based on the item index
                  return { labelId: id, color: '#000' };
                }
                return {
                  labelId: cls.id,
                  color: cls.color,
                  maskIdx: idx,
                };
              }),
            ]
          : [],
      )
      // @ts-ignore
      .reduce((acc, labelIds: [string]) => acc.concat(labelIds), []),
    // For others
    ...tissueDataCandidate.map((data: DataList) => {
      if (!data.labelId) return { labelId: data.labelId, color: '#000' };
      const cls = taskClasses[data.labelId] as TaskClass;
      if (!cls) {
        // This will generate a fake task class item.
        // It won't be shown up on the right panel
        // but still necessary not to modify the original array.length
        // since their position is calculated based on the item index
        return { labelId: data.labelId, color: '#000' };
      }
      return {
        labelId: cls.id,
        color: cls.color,
      };
    }),
  ];
}

export function makeImageMasks(
  tissueDataCandidate: any,
  slideData: SlideData,
  taskClasses: TaskClasses,
) {
  return slideData.currentMasks.map(
    (_, idx: number) =>
      new OffscreenImageMask(
        tissueDataCandidate[idx].labelIds.map((id: string) => {
          const cls = taskClasses[id] as TaskClass;
          if (!cls) {
            return { labelId: id, color: '#000', type: undefined };
          }
          return {
            labelId: cls.id,
            color: cls.color,
            type: cls.type,
          };
        }),
        idx,
      ),
  );
}
