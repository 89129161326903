import type { BaseButtonProps } from './Button.types';
import { BaseContainedButton } from './components/ContainedButton.styled';
import { BaseGhostButton } from './components/GhostButton.styled';
import { BaseOutlinedButton } from './components/OutlinedButton.styled';

const BaseButton = ({ variant, ...otherProps }: BaseButtonProps) => {
  if (variant === 'ghost') {
    return <BaseGhostButton {...otherProps} />;
  }

  if (variant === 'outlined') {
    return <BaseOutlinedButton {...otherProps} />;
  }

  return <BaseContainedButton {...otherProps} />;
};

export default BaseButton;
