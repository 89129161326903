import type { ComponentsOverrides, ComponentsProps } from '@mui/material/styles';
import palette from '../colors';
import typographyOptions from '../system/typography';

const styleOverrides: ComponentsOverrides['MuiTooltip'] = {
  tooltip: {
    ...typographyOptions.body5,
    backgroundColor: palette.darkGrey[80],
    marginTop: '0 !important',
    padding: '6px 8px',
    filter:
      'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.36)) drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.48))',
  },
  popper: {
    '&[data-popper-placement*="bottom"]': {
      '& .MuiTooltip-arrow': {
        marginTop: -7.81,
        width: 11,
        height: 7.81,
        color: palette.darkGrey[80],
      },
    },
    '&[data-popper-placement*="left"]': {
      '& .MuiTooltip-arrow': {
        marginRight: -7.81,
        height: 11,
        width: 7.81,
        color: palette.darkGrey[80],
      },
    },
  },
  arrow: {
    color: palette.darkGrey[80],
  },
};
const defaultProps: ComponentsProps['MuiTooltip'] = {
  arrow: true,
  PopperProps: {
    popperOptions: {
      modifiers: [
        {
          name: 'offset',
          enabled: true,
          options: {
            offset: [0, 6],
          },
        },
      ],
    },
  },
};
export default {
  styleOverrides,
  defaultProps,
};
