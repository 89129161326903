import { DataList, SlideProps } from '../../types';

export default class Slide {
  mpp: number;

  minX: number;

  minY: number;

  downsampling: number;

  description: string;

  dataList: DataList[];

  constructor(slide: SlideProps) {
    this.mpp = slide.mppX;
    this.minX = slide.minX;
    this.minY = slide.minY;
    this.downsampling = slide.downsampling;
    this.description = slide.description;
    this.dataList = [];
  }

  destroy() {
    this.dataList = [];
  }
}
