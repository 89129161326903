import BaseDivider from './Divider.styled';
import type { DividerProps } from './Divider.types';

function Divider({ orientation = 'vertical', ...props }: DividerProps) {
  return (
    <BaseDivider
      orientation={orientation}
      {...props}
    />
  );
}
export default Divider;
