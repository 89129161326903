import { createAPIQuery } from '@lunit/oncology-components/api/utils/apiQueryHelper';
import { FetchMethod, request } from '../utils/apiRequestHelper';
import { Projects } from './models';

const getProjects = createAPIQuery(
  () =>
    request<Projects>({
      method: FetchMethod.Get,
      path: 'research/projects',
    }),
  () => ['projects', 'getProjects'],
);

// eslint-disable-next-line import/prefer-default-export
export { getProjects };
