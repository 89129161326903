import { Error16, StartT2 } from '@lunit/oncology-icons';

const presetMap: any = {
  default: {
    className: 'presetDefault',
    label: 'label',
    color: 'default',
  },
  uploaded: {
    className: 'presetUploaded',
    label: 'Uploaded',
  },
  analyzing: {
    className: 'presetAnalyzing',
    label: 'Analyzing',
  },
  analyzed: {
    className: 'presetAnalyzed',
    label: 'Analyzed',
  },
  analysis_failed: {
    className: 'presetFailed',
    label: 'Failed',
    icon: <Error16 />,
  },
  qc_failed: {
    className: 'presetFailed',
    label: 'Failed',
    icon: <Error16 />,
  },
  ready: {
    className: 'presetReady',
    label: 'Ready',
    icon: <StartT2 />,
  },
  inferred: {
    className: 'presetInferred',
    label: 'Inferred',
    icon: <StartT2 />,
  },
  inferencing: {
    className: 'presetAnalyzing',
    label: 'Analyzing',
  },
  invalid: {
    className: 'presetInvalid',
    label: 'Invalid',
    icon: <Error16 />,
  },
};

// eslint-disable-next-line import/prefer-default-export
export { presetMap };
