import { Divider, Menu, MenuItem } from '@mui/material';
import AvatarButton from '@lunit/oncology-components/AvatarButton';

import { useState } from 'react';

import { useMyInfoQuery } from 'src/hooks/useAuthQueries';
import usePopper from 'src/hooks/usePopper';
import { logout } from 'src/pages/auth/utils';
import useSnackbar from 'src/hooks/useSnackbar';
import PasswordDialog from './Dialog/PasswordDialog';

function ProfileAvatarButton() {
  const { anchorEl, onClose, onOpen, open } = usePopper();
  const [passwordOpen, setPasswordOpen] = useState<boolean>(false);
  const [hasNotifiedError, setHasNotifiedError] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const { data: user, isError, isFetching } = useMyInfoQuery();

  if (isError && !hasNotifiedError) {
    enqueueSnackbar(
      'User not found',
      {
        variant: 'error',
        preventDuplicate: true,
      },
      { isLight: false },
    );
    setHasNotifiedError(true);
  }

  return (
    <span id="profileAvatarButton">
      {isFetching ? (
        <AvatarButton
          name=""
          onClick={onOpen}
        />
      ) : (
        <AvatarButton
          name={user ? user.firstName : 'User'}
          onClick={onOpen}
        />
      )}

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        PaperProps={{
          elevation: 1,
          sx: { width: '200px' },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div>
          <MenuItem
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setPasswordOpen(true);
              onClose();
            }}
          >
            Change password
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => logout()}>Sign out</MenuItem>
        </div>
      </Menu>
      <PasswordDialog
        open={passwordOpen}
        onCancel={() => setPasswordOpen(false)}
      />
    </span>
  );
}

export default ProfileAvatarButton;
