import type { ComponentsOverrides } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import typographyOptions from '../system/typography';
import palette from '../colors';

const defaultTheme = createTheme();

const styleOverrides: ComponentsOverrides['MuiOutlinedInput'] = {
  root: {
    borderRadius: 8,
    backgroundColor: palette.background.selected,
    color: palette.darkGrey[5],
    '&.MuiInputBase-adornedStart': {
      paddingLeft: '16px',
    },
    '&.MuiInputBase-adornedEnd': {
      paddingRight: '16px',
    },
    '&.Mui-disabled': {
      backgroundColor: 'transparent',
      borderWidth: '1px',
      borderColor: palette.darkGrey[40],
    },
  },
  notchedOutline: {
    border: '1px solid transparent',
    borderRadius: 8,
    padding: 0,
    transition: defaultTheme.transitions.create('border'),
    'html .MuiOutlinedInput-root:hover &': {
      borderColor: palette.darkGrey[40],
    },
    'html .MuiOutlinedInput-root.Mui-focused &': {
      borderWidth: '1px',
      borderColor: palette.brand.primary,
      boxShadow: '0 0 0 3px rgba(114, 146, 253, 0.2)',
    },
    'html .MuiOutlinedInput-root.Mui-disabled &': {
      border: 'none',
    },
  },
  input: {
    ...typographyOptions.body5,
    color: palette.darkGrey[5],
    padding: '8px 16px',
    boxSizing: 'border-box',
    '&::placeholder': {
      color: palette.darkGrey[40],
    },
    '&:-webkit-autofill': {
      boxShadow: `0 0 0 100px ${palette.background.selected} inset`,
      WebkitTextFillColor: palette.darkGrey[5],
      // color: `${palette.darkGrey[5]} !important`,
    },
    '&.Mui-disabled': {
      backgroundColor: 'inherit',
      borderRadius: '8px',
      border: `1px solid ${palette.darkGrey[70]}`,
      WebkitTextFillColor: palette.darkGrey[40],
    },
  },
};
export default {
  styleOverrides,
};
