import type { ComponentsOverrides } from '@mui/material/styles';

const styleOverrides: ComponentsOverrides['MuiDialogActions'] = {
  root: {
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    padding: '0 32px',
    borderRadius: '0px 0px 10px 10px',
    '& button': {
      padding: '8px 12px',
      height: 36,
    },
  },
};
export default {
  styleOverrides,
};
