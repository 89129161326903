import type { ComponentsOverrides } from '@mui/material/styles';

const styleOverrides: ComponentsOverrides['MuiInputLabel'] = {
  root: {
    marginBottom: 8,
  },
};
export default {
  styleOverrides,
};
