import { Tooltip } from '@mui/material';
import HeaderButton from '@lunit/oncology-components/HeaderButton';
import { AppsIcon } from '@lunit/scope-icons';
import { useProjects } from 'src/hooks/useProjectQueries';
import { useLocation } from 'react-router-dom';
import AppListMenu from './AppListMenu';
import usePopper from '../../../../hooks/usePopper';

function AppListButton() {
  const { anchorEl, onClose, onOpen, open } = usePopper();

  const location = useLocation();

  const enabled = location.pathname !== '/';
  const { data: projects, isLoading } = useProjects(enabled);

  return (
    <Tooltip title={!open ? 'Project title' : ''}>
      <span>
        <HeaderButton onClick={onOpen}>
          <AppsIcon />
        </HeaderButton>
        <AppListMenu
          isOpen={open}
          anchorEl={anchorEl}
          onClose={onClose}
          isLoading={isLoading}
          projects={projects?.projects ?? []}
          customerCode={projects?.customerCode ?? ''}
        />
      </span>
    </Tooltip>
  );
}

export default AppListButton;
