import { createTheme } from '@mui/material/styles';
import MuiCssBaseline from './components/MuiCssBaseline';
import MuiInputBase from './components/MuiInputBase';
import MuiOutlinedInput from './components/MuiOutlinedInput';
import MuiTextField from './components/MuiTextField';
import { palette, spacing, typography } from './foundations';
import { drawer } from './systems';

const theme = createTheme({
  ...drawer,
  palette,
  spacing,
  typography,
  components: {
    MuiCssBaseline,
    MuiInputBase,
    MuiOutlinedInput,
    MuiTextField,
  },
});

export default theme;
