import * as React from 'react';
import type { SVGProps } from 'react';
const SvgIcCloseSmalloutlined20Px = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M10 9.293 5.854 5.146a.5.5 0 1 0-.708.708L9.293 10l-4.147 4.146a.5.5 0 1 0 .708.708L10 10.707l4.146 4.147a.5.5 0 0 0 .708-.708L10.707 10l4.147-4.146a.5.5 0 0 0-.708-.708L10 9.293Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIcCloseSmalloutlined20Px;
