import type { ComponentsOverrides, ComponentsProps } from '@mui/material/styles';

const styleOverrides: ComponentsOverrides['MuiTextField'] = {
  root: {
    width: '100%',
    input: {
      textOverflow: 'ellipsis',
    },
  },
};
const defaultProps: ComponentsProps['MuiTextField'] = {
  variant: 'standard',
};
export default {
  styleOverrides,
  defaultProps,
};
