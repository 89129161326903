import format from 'date-fns/format';

export const DATE_FORMAT = 'MM/dd/yyyy';
export const DATETIME_FORMAT = 'MM/dd/yyyy HH:mm';
export const REPORT_TIME_FORMAT = 'MM_dd_yyyy_HH_mm';
export const SERVER_DATE_FORMAT = 'yyyy-MM-dd';
export const SLIDE_DATE_FORMAT = 'MMM d, yyyy HH:mm';

export function DateFormatter(value: string, dateFormat: string): string {
  const date = new Date(Date.parse(value));
  return format(date, dateFormat);
}

export function DatetoIsoConverter(value: Date): string {
  return value === null ? '' : value.toISOString();
}
