import * as React from 'react';
import type { SVGProps } from 'react';
const SvgIcManufactureroutlined20Px = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M19.48.004v23H0v-8.543l2.977-6.178 2.253 4.682 2.254-4.682 2.256 4.682 2.254-4.682 2.256 4.682V.005h5.23Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgIcManufactureroutlined20Px;
